import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
    name: "data",
    initialState: {
        citys: [],
        isLoadingCity: false,
    },
    reducers: {
        replaceCitys(state, action) {
            state.citys = action.payload;
            state.isLoadingCity = true;
        }
    }
});

export const dataActions = dataSlice.actions;
export default dataSlice;