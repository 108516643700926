import React, { useState, useEffect } from "react";
import "../css/Card.css";
import { MdLocationOn } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import { AiFillInfoCircle } from "react-icons/ai";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Card(props) {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [menu, setMenu] = useState(null);
  const [indexDay, setIndexDay] = useState(-100);
  const [isClose, setIsClose] = useState(false);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    const nameDay = Date(Date.now()).toString().split(" ")[0];
    let index = -100;
    for (let i = 0; i < days.length; i++) {
      if (days[i] === nameDay) {
        index = i;
      }
    }
    var date = new Date();
    var hrs = date.getHours();
    setIndexDay(index);
    if(index === -100) return;
    if ((hrs < props.shop.openTime[index].from) || hrs >= props.shop.openTime[index].to
    ) {
      setIsClose(true);
    }
  }, []);

  return (
    <div className="card-box">
      <div
        onClick={(e) => {
          var date = new Date();
          var hrs = date.getHours();
          if(indexDay === -100) return;
          if (
            hrs < props.shop.openTime[indexDay].from ||
            hrs >= props.shop.openTime[indexDay].to
          ) {
            setIsClose(true);
            return;
          }
          history.push(
            `/shop_detail/${props.shop.city || "^nocity^"}/${
              props.shop.town || "^notown^"
            }/${props.shop.village || "^novillage^"}/${props.shop.nameshop}/${
              props.shop.email || "^notemail^"
            }/${menu || props.shop.menu[0] || "^notfound^"}`
          );
        }}
        className="card-img"
      >
        <LazyLoadImage
          width="100%"
          height="100%"
          src={props.shop.avShop.url || ""}
        ></LazyLoadImage>
        <div className="card-star-box">
          <ReactStars
            key={`stars_${props.shop.star}`}
            isHalf={true}
            count={5}
            size={10}
            value={props.shop.star}
            edit={false}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#00ABB3"
          />
        </div>
        {isClose && <div className="close-text">Đóng Cửa</div>}
        <div className={`card-menu-box ${showMenu ? "showCardMenu" : ""}`}>
          {props.shop.menu
            ? props.shop.menu.map((item, index) => {
                return (
                  <div
                    onMouseOver={(e) => {
                      if (showMenu === false) return;
                      setMenu(item);
                    }}
                    className="card-menu-item"
                    key={index}
                  >
                    {item}
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      {showMenu === false && (
        <div className="card-title">{props.shop.nameshop || ""}</div>
      )}
      {showMenu === true && (
        <div
          onClick={(e) => setShowMenu(false)}
          style={{
            backgroundColor: "#F24A72",
            alignSelf: "center",
            width: "70%",
            color: "white",
            borderRadius: "20px",
            fontWeight: "bold",
          }}
          className="card-title"
        >
          close menu
        </div>
      )}
      <hr />
      <div className="card-content">
        <div className="card-local">
          <span style={{ color: "grey", fontWeight: "bold" }}>giá từ: </span>
          <span style={{ color: "purple", fontWeight: "bold" }}>
            {props.shop.minPrice / 1000 || 0}k
          </span>
        </div>
        {props.shop.sale > 1000 && (
          <div className="card-detail">
            <span style={{ color: "grey" }}>đã bán: </span>
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {(props.shop.sale / 1000).toFixed(1)}K
            </span>
          </div>
        )}
        {props.shop.sale < 1000 && (
          <div className="card-detail">
            <span style={{ color: "grey" }}>đã bán: </span>
            <span style={{ color: "purple", fontWeight: "bold" }}>
              {props.shop.sale}
            </span>
          </div>
        )}
      </div>
      <hr />
      <div className="card-content">
        <div className="card-local">
          <MdLocationOn size="15" />
          <span className="card-text-local">{props.shop.town || ""}</span>
        </div>
        <div className="card-icons-box">
          <span
            onMouseMove={(e) => setShowMenu(true)}
            className="card-icon-menu"
          >
            <MdOutlineRestaurantMenu
              size={15}
              color={showMenu === true ? "green" : "gray"}
            />
          </span>
          <span className="card-icon-detail">
            <AiFillInfoCircle size={15} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Card;
