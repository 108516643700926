import React, { useState, useEffect } from "react";
import "../css/AppBar.css";
import { AiOutlineMenu, AiOutlineShoppingCart } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { MdFavoriteBorder } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { userActions } from "../redux/user";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  AiOutlineInfoCircle,
  AiOutlineLogout,
  AiOutlineLogin,
  AiFillCloseCircle,
  AiFillDelete,
} from "react-icons/ai";
import { FaRegRegistered } from "react-icons/fa";
import Search from "../component/Search";
import { api } from "../util/api";
import axios from "axios";
import { version } from "../util/version";

function AppBar() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const [showPopup, setShowPopup] = useState(-1);
  const [newNumber, setNewNumber] = useState(0);
  const [showMenuAppbar, setShowMenuAppbar] = useState(false);
  const [logoMain, setLogoMain] = useState("");
  const [favorites, setFavorites] = useState([]);

  async function getLogo() {
      const res = await axios.get(`${api}/admin/findImage/logo-main/${version}`);
      let mylogos = res.data.imgs || []
      setLogoMain(mylogos[0].data || "");
  }

  useEffect(() => {

    getLogo();
  }, []);
  
  useEffect(() => {
    let nums = 0;
    if (user.email !== "") {
      for (let i = 0; i < user.notification.length; i++) {
        if (user.notification[i].read === false) {
          nums += 1;
        }
      }
    }
    setNewNumber(nums);
  }, [user]);

  function logout(e) {
    e.preventDefault();
    Cookies.remove("token");
    //Cookies.remove("rfToken");
    dispatch(userActions.logout());
  }

  const checkNotifications = async () => {
    let checkRead = user.notification.findIndex((item) => item.read === false);
    if (checkRead >= 0) {
      let newNotifications = [];
      let i = 0;
      if (user.notification.length > 20) i = 10;
      for (i; i < user.notification.length; i++) {
        newNotifications.push({ ...user.notification[i], read: true });
      }
      await axios.post(`${api}/user/update_notifications`, {
        email: user.email,
        notification: newNotifications,
      });
    }
  };

  const handlerCloseNotifications = async () => {
    let checkRead = user.notification.findIndex((item) => item.read === false);
    if (checkRead >= 0) {
      let newNotifications = [];
      let i = 0;
      if (user.notification.length > 20) i = 10;
      for (i; i < user.notification.length; i++) {
        newNotifications.push({ ...user.notification[i], read: true });
      }
      dispatch(userActions.updateNotifications(newNotifications));
    }
  };

  async function deleteFavorite(e, item) {
    e.preventDefault();
    const newFavorites = favorites.filter(
      (it, index) => it.nameshop !== item.nameshop
    );
    setFavorites(newFavorites);
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
  }

  return (
    <div className="AppBar">
      <Link to={"/"} className="AppBar-Logo">
        <img width="100%" src={logoMain} />
      </Link>
      <div className="AppBar-Help">
        <span>Need Help?</span>
        <span>Call: 19001080</span>
      </div>
      <div className="AppBar-Btn">
        <AiOutlineMenu />
      </div>
      <Search className={"AppBar-Input"} classIcon={"AppBar-Search"} />
      <div className="AppBar-Btn AppBar-Btn-Account">
        {user.email === "" && (
          <div className="AppBar-Btn-Auth">
            <Link to={"/login"} className="AppBar-Person">
              <p>Đăng Nhập</p>
              <AiOutlineLogin size={30} />
            </Link>
            <Link to={"/register"} className="AppBar-Person">
              <p>Đăng Ký</p>
              <FaRegRegistered size={30} />
            </Link>
          </div>
        )}
        {user.email !== "" && (
          <div className="AppBar-Btn-Auth">
            <Link to={"/profile"} className="AppBar-Person">
              <p>Thông Tin</p>
              <AiOutlineInfoCircle size={30} />
            </Link>
            <Link to={"/"} onClick={(e) => logout(e)} className="AppBar-Person">
              <p>Đăng Xuất</p>
              <AiOutlineLogout size={30} />
            </Link>
          </div>
        )}
        <BsPerson size="25" />
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          let favorites = JSON.parse(localStorage.getItem('favorites')) || [];
          setFavorites(favorites);
          setShowPopup(1);
        }}
        className="AppBar-Btn"
      >
        <MdFavoriteBorder size="25" />
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          setShowPopup(2);
          checkNotifications();
        }}
        className="AppBar-Btn"
      >
        <IoMdNotificationsOutline size="25" />
        <div
          style={{
            position: "absolute",
            top: "-15px",
            left: "-10px",
            backgroundColor: "#DDDDDD",
            padding: "5px",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            color: "orangered",
            fontWeight: "bold",
          }}
        >
          {newNumber}
        </div>
      </div>

      <Link to={"/cart"} className="AppBar-Btn">
        <div className="appbar-link" to={"/cart"}>
          <AiOutlineShoppingCart size="25" />
        </div>
        <div
          style={{
            position: "absolute",
            top: "-15px",
            left: "-10px",
            backgroundColor: "#DDDDDD",
            padding: "5px",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            color: "orangered",
            fontWeight: "bold",
          }}
        >
          {cart.items.length}
        </div>
      </Link>

      <div
        style={{
          transform: showPopup === 2 ? "translateX(0)" : "translateX(100%)",
        }}
        className="AppBar-notification"
      >
        <div id="style-5" className="AppBar-notification-content-box">
          {user.notification.map((item, index) => {
            return (
              <div className="AppBar-notification-item" key={index}>
                {item.content}{" "}
                <span style={{ color: "orangered", fontWeight: "bold" }}>
                  {item.read === false ? "New" : ""}
                </span>
              </div>
            );
          })}
        </div>
        <div
          onClick={(e) => {
            setShowPopup(-1);
            handlerCloseNotifications();
          }}
          className="AppBar-notification-close"
        >
          Đóng cửa sổ
        </div>
        {showPopup === 2 && (
          <div
            className="AppBar-notification-close-icon"
            data-popup="1"
            onClick={(e) => {
              setShowPopup(-1);
              handlerCloseNotifications();
            }}
          >
            <AiFillCloseCircle size="45" />
          </div>
        )}
      </div>

      <div
        style={{
          transform: showPopup === 1 ? "translateX(0)" : "translateX(100%)",
        }}
        className="AppBar-notification"
      >
        <div id="style-5" className="AppBar-notification-content-box">
          {favorites.map((item, index) => {
            return (
              <div className="AppBar-favorite-item" key={index}>
                <Link onClick={(e) => setShowPopup(-1)} to={`${item.link}`} className="AppBar-favorite-text">
                  {item.nameshop}
                </Link>
                <div
                  onClick={(e) => {
                    deleteFavorite(e, item);
                  }}
                >
                  <AiFillDelete size={20} />
                </div>
              </div>
            );
          })}
        </div>
        <div
          onClick={(e) => {
            setShowPopup(-1);
            handlerCloseNotifications();
          }}
          className="AppBar-notification-close"
        >
          Đóng Sửa Sổ
        </div>
        {showPopup === 1 && (
          <div
            className="AppBar-notification-close-icon"
            data-popup="1"
            onClick={(e) => {
              setShowPopup(-1);
              handlerCloseNotifications();
            }}
          >
            <AiFillCloseCircle size="45" />
          </div>
        )}
      </div>

      <div className="appbar-mobile-box">
        <Link style={{textDecoration: "none"}} to="/" className="appbar-mobile-text">Welcome to your shop</Link>
        <div onClick={(e) => setShowMenuAppbar(true)} className="appbar-mobile-menu">
          <AiOutlineMenu />
        </div>
        {showMenuAppbar &&  <div  className="appbar-mobile-items">
          <div>
            {user.email === "" && (
              <Link onClick={(e) => setShowMenuAppbar(false)} to={"/login"} className="appBar-mobile-item">
                Đăng Nhập
                <AiOutlineLogin size={30} />
              </Link>
            )}
            {user.email === "" && (
              <Link onClick={(e) => setShowMenuAppbar(false)} to={"/register"} className="appBar-mobile-item">
                Đăng Ký
                <FaRegRegistered size={30} />
              </Link>
            )}
            {user.email !== "" && (
              <Link onClick={(e) => setShowMenuAppbar(false)} to={"/profile"} className="appBar-mobile-item">
                Thông Tin
                <AiOutlineInfoCircle size={30} />
              </Link>
            )}
            {user.email !== "" && (
              <Link
                onClick={(e) => {
                    logout(e)
                    setShowMenuAppbar(false);
                }}
                to={"/login"}
                className="appBar-mobile-item"
              >
                Đăng Xuất
                <AiOutlineLogout size={30} />
              </Link>
            )}
            <div
              onClick={(e) => {
                e.preventDefault();
                setShowPopup(1);
                setShowMenuAppbar(false);
              }}
              className="appBar-mobile-item"
            >
              Yêu Thích
              <MdFavoriteBorder size={30} />
            </div>

            <div
              onClick={(e) => {
                e.preventDefault();
                setShowPopup(2);
                checkNotifications();
                setShowMenuAppbar(false);
              }}
              className="appBar-mobile-item"
            >
              Thông Báo
              <IoMdNotificationsOutline size="30" />
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "20px",
                  backgroundColor: "#DDDDDD",
                  padding: "5px",
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {newNumber}
              </div>
            </div>

            <Link to={"/cart"} onClick={(e) => setShowMenuAppbar(false)}  className="appBar-mobile-item">
              Giỏ Hàng
              <div className="appbar-link" to={"/cart"}>
                <AiOutlineShoppingCart size={30} />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "-5px",
                  right: "20px",
                  backgroundColor: "#DDDDDD",
                  padding: "5px",
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cart.items.length}
              </div>
            </Link>
          </div>

          <button onClick={(e) => setShowMenuAppbar(false)} className="appbar-mobile-close">close menu</button>
        </div>}
      </div>
    </div>
  );
}

export default AppBar;
