import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../css/ShopDetail.css";
import { api } from "../util/api";
import stringFix from "../util/stringFix";
import { useHistory, Link } from "react-router-dom";
import {
  BsFillArrowDownCircleFill,
  BsFacebook,
  BsTelegram,
} from "react-icons/bs";
import {
  MdOutlineRadioButtonChecked,
  MdIndeterminateCheckBox,
  MdAddBox,
  MdArrowDropDownCircle,
} from "react-icons/md";
import {
  AiFillTwitterCircle,
  AiFillYoutube,
  AiFillGoogleCircle,
  AiOutlineMenu,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiFillDelete,
  AiFillStar,
  AiFillCloseCircle,
  AiFillCloseSquare,
  AiFillHeart,
} from "react-icons/ai";
import { FiDelete } from "react-icons/fi";
import { FaInstagramSquare } from "react-icons/fa";
import { cartActions } from "../redux/cart";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import ReviewSend from "./ReviewSend";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TextEffect from "./TextEffect";
import { version } from "../util/version";

function ShopDetail(props) {
  const history = useHistory();
  const mycitys = useSelector(state => state.data.citys);
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const params = useParams();
  const [shop, setShop] = useState(null);
  const [mainItems, setMainItems] = useState([]);
  const [subItems, setSubItems] = useState([]);
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [village, setVillage] = useState("");
  const [address, setAddress] = useState("");
  const [citys, setCitys] = useState([]);
  const [towns, setTowns] = useState([]);
  const [menu, setMenu] = useState([]);
  const [villages, setVillages] = useState([]);
  const [move, setMove] = useState(0);
  const [showPl, setShowPl] = useState(-1);
  const [showMonbosung, setShowMonbosung] = useState(-1);
  const [orderBox, setOrderBox] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showOrderMBS, setShowOrderMBS] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [saveMenus, setSaveMenus] = useState([]);
  const [showLocal, setShowLocal] = useState(-1);
  const [localItems, setLocalItems] = useState([]);
  const [message, setMessage] = useState({ content: "", color: "" });
  const [cartImg, setCartImg] = useState("");
  const [itemsBuy, setItemsBuy] = useState({
    nameshop: "",
    ten: "",
    tien: 0,
    img_url: "",
    so_luong: 0,
    phanloai: { ten: "", tien: 0 },
    monbosung: [],
    tongtien: 0,
    email_nguoi_ban: "",
    sdt_nguoi_ban: "",
    email_nguoi_mua: "",
    sdt_nguoi_mua: "",
    nums: -1,
    diachi_giaohang: [],
    diachi_nhanhang: [],
  });
  const [showMenuPopup, setShowMenuPopup] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [nameshop, setNameshop] = useState("");

  const fetchShop = async (nameshop) => {
      const refresh = Cookies.get("refresh") || Date.now();
      const res = await axios.get(`${api}/shop/shop_by_name/${nameshop}/${refresh}`);
      if (res.data.message === "ok") {
        Cookies.set('refresh', refresh);
        setShop(res.data.shop || null)
      };
  };

  useEffect(() => {
    if(nameshop === "") return;
    fetchShop(nameshop)
  }, [nameshop])

  async function getImg() {
      const res = await axios.get(`${api}/admin/findImage/cart-1/${version}`);
      let cart = res.data.imgs || []
      setCartImg(cart[0].data || "");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    let getFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(getFavorites);
    getImg();
  }, []);

  const fetchItemsMenu = async (search, menu) => {
    const res = await axios.get(`${api}/shop/findItemMenu/${search}/${menu}`);
    if (res.data.message === "ok") {
      setMainItems(res.data.mainItems || []);
      setSubItems(res.data.subItems || []);
      setSaveMenus([
        ...saveMenus,
        {
          name: menu,
          mainItems: res.data.mainItems,
          subItems: res.data.subItems,
        },
      ]);
    }
  };

  useEffect(() => {
    if (user.isLoggedIn === false || user.isLoggedIn === true) {
      setNameshop(params.nameshop);
      let city = user.city;
      let town = user.town;
      let village = user.village;
      let citys = [];
      let towns = ["Tất cả QH"];
      let villages = [];
      for (let i = 0; i < mycitys.length; i++) {
        citys.push(mycitys[i]["city"]);
        if (mycitys[i]["city"] === city) {
          for (var j = 0; j < mycitys[i]["towns"].length; j++) {
            towns.push(mycitys[i]["towns"][j]["town"]);
            if (mycitys[i]["towns"][j]["town"] === town) {
              for (let k = 0; k < mycitys[i]["towns"][j]["village"].length; k++) {
                villages.push(mycitys[i]["towns"][j]["village"][k]);
              }
            }
          }
        }
      }
      setCity(city);
      setTown(town);
      setVillage(village);
      setCitys(citys);
      setTowns(towns);
      setVillages(villages);
      setAddress(user.address || "");
      localStorage.removeItem("products");
      if (params.menu !== "^notfound^" || params.email !== "^notemail^") {
        fetchItemsMenu(params.email + stringFix(params.menu), params.menu);
        setMenu(params.menu);
        return;
      }
    }
  }, [params]);

  useEffect(() => {
    let getItemOrder = [];
    let total = 0;
    for (let i = 0; i < cart.items.length; i++) {
      if (cart.items[i].email_nguoi_ban === params.email) {
        getItemOrder.push({
          ...cart.items[i],
          checkMBS: cart.items[i].monbosung.findIndex(
            (item) => item.ten !== ""
          ),
        });
        total += cart.items[i]["tongtien"] * cart.items[i].so_luong;
      }
    }
    setTotalPrice(total);
    setOrderBox(getItemOrder);
  }, [cart]);

  const AddtoCartHandler = (e) => {
    e.preventDefault();
    if(itemsBuy.email_nguoi_ban === user.email) return alert("Bạn không thể mua hàng của chính mình");
    if(cart.items.length > 50) return alert("giỏ hàng đã đầy vui lòng đặt hàng hoặc xóa bớt");
    if (user.email === "") return history.push("/login");
    if (city === "" || town === "" || village === "" || address === "")
      return alert("chưa đủ thông tin địa chỉ giao hàng");
    dispatch(
      cartActions.addItem({
        ...itemsBuy,
        nameshop: shop.nameshop,
        deviceToken: shop.deviceToken,
        diachi_nhanhang: [city, town, village, address],
        uuid: uuidv4(),
      })
    );
  };

  useEffect(() => {
    if (isLoading) {
      const indexMenu = saveMenus.findIndex((item) => item.name === menu);
      if (indexMenu >= 0) {
        setMainItems(saveMenus[indexMenu].mainItems);
        setSubItems(saveMenus[indexMenu].subItems);
        setIsLoading(false);
      } else {
        fetchItemsMenu(params.email + stringFix(menu), menu);
        setIsLoading(false);
      }
    }
  }, [menu]);

  const handlerOrder = async (e, order) => {
    if(order.length === 0) return;
    if(user.email === "") history.push("/login");
    if(user.email === order[0].email_nguoi_ban) return alert("không thể mua shop của chính mình");
    setIsLoading(true);
    let setOrder = {
      deviceToken: shop.deviceToken,
      nameshop: order[0].nameshop,
      diachi_giaohang: order[0].diachi_giaohang,
      diachi_nhanhang: order[0].diachi_nhanhang,
      sdt_nguoi_ban: order[0].sdt_nguoi_ban,
      sdt_nguoi_mua: order[0].sdt_nguoi_mua,
      email_nguoi_ban: order[0].email_nguoi_ban,
      email_nguoi_mua: order[0].email_nguoi_mua,
      status: "choxacnhan",
      createdAt: Date.now(),
      tongtien: 0,
      items: [],
    };
    let items = [];
    let total = 0;
    for (let i = 0; i < order.length; i++) {
      items.push({
        phanloai: order[i].phanloai,
        tongtien: order[i].tongtien,
        ten: order[i].ten,
        monbosung: order[i].monbosung.filter((item) => item.ten !== ""),
        tien: order[i].tien,
        so_luong: order[i].so_luong,
        img_url: order[i].img_url,
      });
      total += order[i].tongtien;
    }
    setOrder.items = JSON.stringify(items);
    setOrder.tongtien = total;
    const token = Cookies.get("token").replaceAll('"', "") ?? "";
    const res = await axios.post(`${api}/order/order`, setOrder, {
      headers: { authorization: "Bearer " + token },
    });
    if (res.data.message === "ok") {
      let pushdata = {
        email: order[0].email_nguoi_ban,
        token: shop.deviceToken,
      }
      dispatch(cartActions.deleteItemsByEmail(order[0].email_nguoi_ban));
      setMessage({
        content: "đặt hàng thành công, vào thông tin tài khoản để xem chi tiết",
        color: "white",
      });
      setTimeout(
        function () {
          setMessage({ content: "", color: "" });
        },
        [3000]
      );
      setIsLoading(false);
      if(props.socket !== null) props.socket.emit("pushOrderToServer", ({email: shop.email, nums: shop.nums}));
      await axios.post(`${api}/order/push_notification`, pushdata);
    } else {
      setMessage({
        content: "đặt hàng không thành công, vui lòng thử lại sau",
        color: "red",
      });
      setTimeout(
        function () {
          setMessage({ content: "", color: "" });
        },
        [3000]
      );
      setIsLoading(false);
    }
  };

  if(isLoading || !shop) {
    return <TextEffect text={"Loading..."} fontsize={40} />;
  }

    const handlefavorites = async (e) => {
      e.preventDefault();
      let link = `/shop_detail/${params.city}/${params.town}/${params.village}/${params.nameshop}/${params.email}/${params.menu}`;
      let nameshop = shop.nameshop;
      if(favorites.length > 100) return alert("Số lượng theo dõi quá nhiều, vui lòng xóa bớt");
      const index = favorites.findIndex((item) => item.nameshop === params.nameshop);
      if(index >= 0) {
        let newFavorites = favorites.filter((item) => item.nameshop !== params.nameshop);
        setFavorites(newFavorites);
        localStorage.setItem("favorites", JSON.stringify(newFavorites));
      } else {
        let newFavorites = [{nameshop: nameshop, link: link} ,...favorites];
        setFavorites(newFavorites);
        localStorage.setItem("favorites", JSON.stringify(newFavorites));
      }
    }
    
  return (
    <div className="shop-detail-box">
      <div className="shop-detail-link-box">
        <Link to={"/"} className="shop-detail-link-item dt-text-overflow">
          Trang chủ -{" "}
        </Link>
        <Link to={`/searchProduct/${params.city || "Hà Nội"}/${"Tất cả QH"}/${"Tất cả PX"}/^no keyword^/bán chạy/1`} className="shop-detail-link-item dt-text-overflow">
          {params.city} -{" "}
        </Link>
        <Link to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${"Tất cả PX"}/^no keyword^/bán chạy/1`} className="shop-detail-link-item dt-text-overflow">
          {params.town} -{" "}
        </Link>
        <Link to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/^no keyword^/bán chạy/1`} className="shop-detail-link-item dt-text-overflow">
          {params.village}{" "}
        </Link>
      </div>
      <div className="shop-detail-top-box">
        <div onClick={(e) => {}} className="shop-detail-top-img">
          <div
            onClick={(e) => {handlefavorites(e)}}
            className="shop-detail-favorite"
          >
            <AiFillHeart size="40px" color={favorites.findIndex((item) => item.nameshop === params.nameshop) >= 0 ? "purple" : "black"}/>
          </div>
          <LazyLoadImage width="100%" height="100%" src={shop.avShop.url} />
        </div>

        <div className="shop-detail-top-mid">
          <div>{shop.nameshop}</div>
          <hr></hr>
          <div className="shop-detail-content-box">
            <span style={{ color: "gray" }}>
              giá tối thiểu:{" "}
              <span style={{ color: "orangered", fontWeight: "bold" }}>
                {shop.minPrice}.vnđ
              </span>
            </span>
            <span style={{ color: "gray" }}>
              giá tối đa:{" "}
              <span style={{ color: "orangered", fontWeight: "bold" }}>
                {shop.maxPrice}.vnđ
              </span>
            </span>
          </div>
          <hr></hr>
          <div className="shop-detail-content-box">
            <span style={{ color: "gray" }}>
              đã bán:{" "}
              <span style={{ color: "orangered", fontWeight: "bold" }}>
                {shop.sale > 1000 ? (shop.sale / 1000).toFixed(1) : shop.sale}
              </span>
            </span>
            <span style={{ color: "gray", display: "flex" }}>
              <span className="dg">đánh giá: {" "}</span>
              <span
                style={{
                  color: "orangered",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {shop.rp > 0 ? (shop.rp / shop.rt).toFixed(2) : 0}
                <AiFillStar color={"#00ABB3"} />{" "}
                <span style={{ color: "gray", fontWeight: "bold" }}>
                  ({shop.rt} review)
                </span>
              </span>
            </span>
          </div>
          <hr />
          <div style={{ fontWeight: "bold" }}>Địa chỉ giao hàng</div>
          <div className="shop-detail-seller">
            <div className="shop-detail-seller-local-box">
              <span style={{ color: "gray", fontWeight: "normal" }}>
                khu vực -{" "}
              </span>
              <span>{shop.city || ""} - </span>
              <span>{shop.town || ""} - </span>
              <span>{shop.village || ""} </span>
            </div>
            <div className="shop-detail-address">
              <span style={{ color: "gray" }}>số nhà - </span>
              <span style={{ color: "#8758FF", fontWeight: "bold" }}>
                {shop.address}
              </span>
            </div>
          </div>
          <hr />
          <div style={{ fontWeight: "bold" }}>Địa chỉ nhận hàng</div>

          <div className="shop-detail-seller">
            <div className="shop-detail-buyer-local-box">
              <div
                onClick={(e) => {
                  setShowLocal(showLocal === 1 ? -1 : 1);
                  setLocalItems(citys);
                }}
                className="shop-detail-dropdown-local"
              >
                <div className="dt-current-local" style={{ padding: "5px" }}>
                  {city === "" ? "chọn TT" : city}
                </div>
                {showLocal === 1 ? (
                  <AiFillCloseCircle />
                ) : (
                  <BsFillArrowDownCircleFill />
                )}
              </div>

              <div
                onClick={(e) => {
                  setShowLocal(showLocal === 2 ? -1 : 2);
                  let towns = [];
                  for (let i = 0; i < mycitys.length; i++) {
                    if (mycitys[i].city === city) {
                      for (let j = 0; j < mycitys[i]["towns"].length; j++)
                        towns.push(mycitys[i]["towns"][j]["town"]);
                    }
                  }
                  setLocalItems(towns);
                }}
                className="shop-detail-dropdown-local"
              >
                <div className="dt-current-local" style={{ padding: "5px" }}>
                  {town === "" ? "chọn QH" : town}
                </div>
                {showLocal === 2 ? (
                  <AiFillCloseCircle />
                ) : (
                  <BsFillArrowDownCircleFill />
                )}
              </div>

              <div
                onClick={(e) => {
                  let villages = [];
                  for (let i = 0; i < mycitys.length; i++) {
                    if (mycitys[i]["city"] === city) {
                      for (let j = 0; j < mycitys[i]["towns"].length; j++) {
                        if (mycitys[i]["towns"][j]["town"] === town) {
                          for (
                            let k = 0;
                            k < mycitys[i]["towns"][j]["village"].length;
                            k++
                          ) {
                            villages.push(mycitys[i]["towns"][j]["village"][k]);
                          }
                        }
                      }
                    }
                  }
                  setLocalItems(villages);
                  setShowLocal(showLocal === 3 ? -1 : 3);
                }}
                className="shop-detail-dropdown-local"
              >
                <div className="dt-current-local" style={{ padding: "5px" }}>
                  {village === "" ? "chọn PX" : village}
                </div>
                {showLocal === 3 ? (
                  <AiFillCloseCircle />
                ) : (
                  <BsFillArrowDownCircleFill />
                )}
              </div>

              {showLocal !== -1 && (
                <div className="shop-detail-local-items">
                  {localItems.map((item, index) => {
                    return (
                      <p
                        onClick={(e) => {
                          e.preventDefault();
                          if (showLocal === 1) {
                            setCity(item);
                            setTown("");
                            setVillage("");
                            setShowLocal(-1);
                          } else if (showLocal === 2) {
                            setTown(item);
                            setVillage("");
                            setShowLocal(-1);
                          } else if (showLocal === 3) {
                            setVillage(item);
                            setShowLocal(-1);
                          }
                        }}
                        key={index}
                        className="shop-detail-local-item"
                      >
                        {item}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>

            <div style={{ height: "5px" }}></div>
            <div className="shop-detail-address-input">
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="số nhà - đường phố"
              />
            </div>
          </div>
          <div className="shop-detail-ship-box">
            <div style={{ fontWeight: "bold" }}>Phí giao hàng</div>
            <div className="shop-detail-ship-content">
              <div>
                <span style={{ color: "gray" }}>10km đầu:</span>
                <span style={{ fontWeight: "bold", color: "orangered" }}>
                  {" "}
                  {shop.cost10km}.vnđ
                </span>
              </div>
              <div>
                <span style={{ color: "gray" }}>km thứ 4:</span>
                <span style={{ fontWeight: "bold", color: "orangered" }}>
                  {" "}
                  {shop.cost4km}.vnđ
                </span>
              </div>
            </div>
          </div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="dt-share">SHARE</div>
            <div className="shop-detail-share-icons">
              <AiFillTwitterCircle className="shop-detail-icon" />
              <FaInstagramSquare className="shop-detail-icon" />
              <AiFillYoutube className="shop-detail-icon" />
              <AiFillGoogleCircle className="shop-detail-icon" />
              <BsFacebook className="shop-detail-icon" />
              <BsTelegram className="shop-detail-icon" />
            </div>
          </div>
        </div>

        <div
          style={{
            background:
              orderBox.length === 0 ? `url(${cartImg}) center no-repeat` : "",
          }}
          className="shop-detail-top-last"
        >
          {orderBox.length === 0 && (
            <div
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: "40px",
                fontSize: "20px",
                fontWeight: "bold",
                color: "#1746A2",
              }}
            >
              start shopping now
            </div>
          )}
          <div id="style-2" className="order-item-list">
            {orderBox.map((item, index) => {
              return (
                <div
                  style={{
                    border: `1px solid #${Math.floor(
                      Math.random() * 16777215
                    ).toString(16)}`,
                  }}
                  key={index}
                  className="order-item"
                >
                  <div className="order-item-info">
                    <LazyLoadImage src={item.img_url} />
                    <div className="dt-content-container">
                      <div
                        style={{
                          borderBottom: "1px solid gray",
                          paddingBottom: "4px",
                        }}
                        className="order-item-content dt-text-overflow"
                      >
                        <span>Tên Món: </span>
                        <span
                          style={{ width: "100%", textAlign: "right" }}
                          className="dt-text-overflow"
                        >
                          {item.ten}
                        </span>
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid gray",
                          paddingBottom: "4px",
                        }}
                        className="order-item-content"
                      >
                        <span>Phân Loại: </span>
                        <span>
                          {item.phanloai.ten === "" ? "???" : item.phanloai.ten}
                        </span>
                      </div>
                      <div
                        onClick={(e) => {
                          if (item.checkMBS === -1) return;
                          setShowOrderMBS(index);
                        }}
                        style={{
                          borderBottom: "1px solid gray",
                          paddingBottom: "5px",
                        }}
                        className="order-item-content"
                      >
                        <span>Món thêm: </span>
                        <span>
                          {item.checkMBS !== -1 ? (
                            <MdArrowDropDownCircle size="20" />
                          ) : (
                            "???"
                          )}
                        </span>
                      </div>
                      <div className="order-item-content">
                        <span>Số Lượng: </span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <MdIndeterminateCheckBox
                            onClick={(e) => {
                              e.preventDefault();
                              console.log(item);
                              if (item.so_luong === 1)
                                return dispatch(
                                  cartActions.deleteItem(item.uuid)
                                );
                              item.so_luong = item.so_luong - 1;
                              dispatch(cartActions.replaceItem(item));
                            }}
                            size={20}
                          />
                          <span style={{ color: "green" }}>
                            {item.so_luong}
                          </span>
                          <MdAddBox
                            onClick={(e) => {
                              e.preventDefault();
                              console.log(item);
                              item.so_luong = item.so_luong + 1;
                              dispatch(cartActions.replaceItem(item));
                            }}
                            size={20}
                          />
                        </div>
                      </div>

                      <div className="order-mbs-container">
                        <div className="order-mbs-box">
                          <span
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            Tổng Tiền
                          </span>
                          <span
                            style={{ color: "#FF731D", fontWeight: "bold" }}
                          >
                            {item.tongtien * item.so_luong}.vnđ
                          </span>
                        </div>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(cartActions.deleteItem(item.uuid));
                          }}
                          className="delete-order-item"
                        >
                          <AiFillDelete />
                        </span>
                      </div>
                      <div
                        style={{
                          transform:
                            showOrderMBS === index ? "scaleX(1)" : "scaleX(0)",
                        }}
                        id="style-3"
                        className="order-items-monbosung"
                      >
                        <div className="dt-mbs-box">
                          {item.monbosung.map((mbs, index) => {
                            if (mbs.ten === "") return;
                            return (
                              <div className="dt-order-mbs-item" key={index}>
                                <div style={{ color: "black" }}>{mbs.ten}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    fontWeight: "bold",
                                    color: "orangered",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <span>{mbs.tien}.vnđ</span>
                                  <FiDelete
                                    onClick={(e) => {
                                      let newMBS = item.monbosung.filter(
                                        (ft) => ft.ten !== mbs.ten
                                      );
                                      item.tongtien -= mbs.tien;
                                      item.monbosung = newMBS;
                                      dispatch(cartActions.replaceItem(item));
                                    }}
                                    className="dt-order-delete-mbs"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          onClick={(e) => setShowOrderMBS(-1)}
                          className="dt-order-mbs-close"
                        >
                          Đóng Cửa Sổ
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          {
            <div className="order-box">
              <div className="order-money-box">
                {orderBox.length === 0 && (
                  <div>
                    <span style={{ color: "gray" }}>Tiền Hàng</span>
                    <span>???</span>
                  </div>
                )}
                {orderBox.length === 0 && (
                  <div>
                    <span style={{ color: "gray" }}>Phí Ship</span>
                    <span>???</span>
                  </div>
                )}
                <div>
                  <span
                    style={{
                      color: orderBox.length > 0 ? "black" : "gray",
                      fontWeight: orderBox.length > 0 ? "bold" : "",
                    }}
                  >
                    Tổng Tiền{" "}
                    {orderBox.length > 0 && (
                      <span style={{ color: "grey", fontWeight: "bold" }}>
                        - chưa tính phí ship
                      </span>
                    )}
                  </span>
                  <span>
                    {totalPrice > 0 ? (
                      <span style={{ color: "orangered", fontWeight: "bold" }}>
                        {totalPrice}.vnđ
                      </span>
                    ) : (
                      "???"
                    )}
                  </span>
                </div>
              </div>
              {!isLoading && (
                <div
                  onClick={(e) => handlerOrder(e, orderBox)}
                  className="shop-detail-order"
                >
                  Order Now
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <div className="shop-detail-list-menu-box">
        <div onClick={(e) => setShowMenuPopup(!showMenuPopup)} className="shop-detail-menu-dropdown-box">
          <span>{menu === "^notfound^" ? "Chọn menu" : menu}</span>
          <AiOutlineMenu className="detail-menu-icon" />
          {showMenuPopup && <div className="menu-item">
            {shop.menu.map((item, index) => {
              return <div onClick={(e) => {
                e.preventDefault();
                if (item === menu) return;
                setMenu(item);
                setIsLoading(true);
              }} key={index}>
                {item}
              </div>
            })}
          </div>}
        </div>

        <div className="shop-detail-menu-slide">
          <div
            className="dt-arrow-menu"
            onClick={(e) => setMove(move > 1 ? move - 1 : 0)}
          >
            <AiOutlineArrowLeft size="30" />
          </div>
          <div className="dt-menu-box">
            {shop.menu.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    transform: `translate(${index * 100 - move * 5 * 100}%)`,
                    transition: "all 0.5s",
                  }}
                  className="dt-menu-item"
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      if (item === menu) return;
                      setMenu(item);
                      setIsLoading(true);
                    }}
                    style={{
                      background: menu === item ? "#404258" : "",
                      color: menu === item ? "white" : "",
                    }}
                    className="dt-menu-text"
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            onClick={(e) =>
              setMove(move + 1 > shop.menu.length / 5 ? 0 : move + 1)
            }
            className="dt-arrow-menu"
          >
            <AiOutlineArrowRight size="30" />
          </div>
        </div>
      </div>

      <div className="saveMenus-box">
        <div
          className="dt-text-overflow"
          style={{
            textAlign: "left",
            paddingLeft: "10px",
            marginTop: "10px",
            width: "100%",
          }}
        >
          {shop.free}
        </div>
        {saveMenus.map((sm, index) => {
          return (
            <div
              key={index}
              id="style-2"
              style={{
                opacity: menu === sm.name ? "100" : "0",
                zIndex: menu === sm.name ? 1 : -1,
                visibility: menu === sm.name ? "visible" : "hidden",
              }}
              className="dt-main-items-box"
            >
              {sm.mainItems.length > 0 ? (
                sm.mainItems.map((item, index) => {
                  return (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        if (itemsBuy.ten === item.name) return;
                        setShowPl(-1);
                        setShowMonbosung(-1);
                        let newMonbosung = [];
                        for (let i = 0; i < subItems.length; i++) {
                          newMonbosung.push({ ten: "", pirce: "" });
                        }
                        setItemsBuy({
                          ten: item.name,
                          tien: item.price,
                          img_url: item.img.url,
                          nums: shop.nums,
                          so_luong: 1,
                          tongtien: parseInt(item.price),
                          phanloai: { ten: "", tien: 0 },
                          monbosung: newMonbosung,
                          email_nguoi_ban: shop.email || "",
                          sdt_nguoi_ban: shop.phone || "",
                          email_nguoi_mua: user.email || "",
                          sdt_nguoi_mua: user.phone || "",
                          diachi_giaohang: [
                            shop.city || "",
                            shop.town || "",
                            shop.village || "",
                            shop.address || "",
                          ],
                          diachi_nhanhang: [
                            city || "",
                            town || "",
                            village || "",
                            address || "",
                          ],
                        });
                      }}
                      key={index}
                      className={`dt-main-item ${
                        itemsBuy.ten === item.name ? "dt-effectSelectItems" : ""
                      }`}
                    >
                      <div className="dt-item-box">
                        <LazyLoadImage
                          className="dt-img"
                          src={item.img.url}
                        ></LazyLoadImage>
                        {item.name === itemsBuy.ten && (
                          <div className="dt-tongtien">
                            <span>Tổng tiền</span>
                            <span>
                              {itemsBuy.tongtien * itemsBuy.so_luong}.vnđ
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="dt-nameshop">{item.name}</div>
                      <hr className="dt-hr" />
                      <div className="dt-item-text-box">
                        <span style={{ color: "gray" }}>giá tiền:</span>
                        <span
                          style={{ color: "black" }}
                          className="dt-mainItem-text"
                        >
                          {item.price}
                        </span>
                      </div>
                      <hr className="dt-hr" />

                      {/* phan loai */}
                      <div
                        onClick={(e) => {
                          if (item.classifys.length <= 0) return;
                          if (itemsBuy.ten == item.name)
                            return setShowPl(index);
                        }}
                        className="dt-item-text-box"
                      >
                        <span>phân loại:</span>
                        <span className="dt-mainItem-text">
                          {item.classifys.length > 0 ? (
                            <BsFillArrowDownCircleFill />
                          ) : (
                            "???"
                          )}
                        </span>
                      </div>
                      <hr className="dt-hr" />

                      {/* mon bo sung */}
                      <div
                        onClick={(e) => {
                          if (subItems.length <= 0) return;
                          if (itemsBuy.ten == item.name)
                            return setShowMonbosung(index);
                        }}
                        className="dt-item-text-box"
                      >
                        <span>món bổ sung:</span>
                        <span className="dt-item-text-box">
                          {subItems.length > 0 ? (
                            <BsFillArrowDownCircleFill />
                          ) : (
                            "???"
                          )}
                        </span>
                      </div>
                      <hr className="dt-hr" />

                      <div className="dt-addtocart-box">
                        <div>
                          <MdIndeterminateCheckBox
                            onClick={(e) => {
                              if (itemsBuy.so_luong < 2) return;
                              setItemsBuy({
                                ...itemsBuy,
                                so_luong: itemsBuy.so_luong - 1,
                              });
                            }}
                            className="dt-min-plus"
                            style={{
                              pointerEvents:
                                itemsBuy.ten === item.name ? "all" : "none",
                            }}
                          />
                          <span>
                            {itemsBuy.ten === item.name ? itemsBuy.so_luong : 1}
                          </span>
                          <MdAddBox
                            onClick={(e) => {
                              setItemsBuy({
                                ...itemsBuy,
                                so_luong: itemsBuy.so_luong + 1,
                              });
                            }}
                            className="dt-min-plus"
                            style={{
                              pointerEvents:
                                itemsBuy.ten === item.name ? "all" : "none",
                            }}
                          />
                        </div>
                        <span
                          style={{
                            pointerEvents:
                              itemsBuy.ten === item.name ? "all" : "none",
                          }}
                          className="dt-addtocart-text"
                          onClick={(e) => {
                            if (itemsBuy.ten !== item.name) return;
                            if (cart.items.length > 50)
                              return setMessage({
                                content:
                                  "Giỏ hàng đã quá nhiều món, vui lòng xoá bớt",
                                color: "white",
                              });
                            AddtoCartHandler(e);
                          }}
                        >
                          add to cart
                        </span>
                      </div>

                      {itemsBuy.ten === item.name && (
                        <div
                          style={{
                            transform:
                              showPl === index ? "scaleY(1)" : "scaleY(0)",
                          }}
                          className="dt-item-pl"
                        >
                          <div id="style-8" className="dt-item-pl-box">
                            {item.classifys.map((pl, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let tt = parseInt(pl.price);
                                    if (itemsBuy.phanloai.ten === pl.name)
                                      tt = parseInt(item.price);
                                    for (
                                      let i = 0;
                                      i < itemsBuy.monbosung.length;
                                      i++
                                    )
                                      tt +=
                                        parseInt(
                                          itemsBuy.monbosung[i]["tien"]
                                        ) || 0;
                                    setItemsBuy({
                                      ...itemsBuy,
                                      phanloai: {
                                        ten:
                                          pl.name === itemsBuy.phanloai.ten
                                            ? ""
                                            : pl.name,
                                        tien:
                                          pl.name === itemsBuy.phanloai.ten
                                            ? 0
                                            : pl.price,
                                      },
                                      tien: pl.price,
                                      tongtien: tt,
                                    });
                                    setShowPl(-1);
                                  }}
                                  className="dt-item-pl-item"
                                  key={index}
                                >
                                  <div className="dt-item-pl-item-text">
                                    <div
                                      className="dt-name-pl"
                                      style={{
                                        color: "gray",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {pl.name}
                                    </div>
                                    <div
                                      style={{
                                        color: "orangered",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {pl.price}.vnđ
                                    </div>
                                  </div>
                                  <div className="dt-checkbox">
                                    <MdOutlineRadioButtonChecked
                                      color={
                                        itemsBuy.ten === item.name &&
                                        itemsBuy.phanloai.ten === pl.name
                                          ? "#1D1CE5"
                                          : "gray"
                                      }
                                      size="25"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <button
                            onClick={(e) => setShowPl(-1)}
                            className="dt-item-pl-item-close"
                          >
                            đóng cửa sổ
                          </button>
                        </div>
                      )}

                      {itemsBuy.ten === item.name && (
                        <div
                          style={{
                            transform:
                              showMonbosung === index
                                ? "scaleY(1)"
                                : "scaleY(0)",
                          }}
                          className="dt-item-pl"
                        >
                          <div id="style-8" className="dt-item-pl-box">
                            {subItems.map((mbs, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let tt = parseInt(itemsBuy.tien);
                                    let newMonbosung = [...itemsBuy.monbosung];
                                    if (
                                      newMonbosung[index]["ten"] !== mbs.name &&
                                      newMonbosung[index]["tien"] !== mbs.price
                                    )
                                      newMonbosung[index] = {
                                        ten: mbs.name,
                                        tien: parseInt(mbs.price),
                                      };
                                    else
                                      newMonbosung[index] = {
                                        ten: "",
                                        tien: 0,
                                      };
                                    console.log(newMonbosung);
                                    for (
                                      let i = 0;
                                      i < newMonbosung.length;
                                      i++
                                    )
                                      tt +=
                                        parseInt(newMonbosung[i]["tien"]) || 0;
                                    setItemsBuy({
                                      ...itemsBuy,
                                      monbosung: newMonbosung,
                                      tongtien: parseInt(tt) || 0,
                                    });
                                    setShowPl(-1);
                                  }}
                                  className="dt-item-pl-item"
                                  key={index}
                                >
                                  <div className="dt-item-pl-item-text">
                                    <div
                                      className="dt-name-pl"
                                      style={{
                                        color: "gray",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {mbs.name}
                                    </div>
                                    <div
                                      style={{
                                        color: "orangered",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {mbs.price}.vnđ
                                    </div>
                                  </div>
                                  <div className="dt-checkbox">
                                    <MdOutlineRadioButtonChecked
                                      color={
                                        itemsBuy.ten === item.name &&
                                        itemsBuy.monbosung.findIndex(
                                          (obj) => obj.ten === mbs.name
                                        ) >= 0
                                          ? "#1D1CE5"
                                          : "gray"
                                      }
                                      size="25"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <button
                            onClick={(e) => setShowMonbosung(-1)}
                            className="dt-item-pl-item-close"
                          >
                            đóng cửa sổ
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "35px",
                    fontWeight: "bold",
                    justifyContent: "center",
                    width: "100%",
                    color: "red",
                  }}
                >
                  Không Tìm thấy sản phẩm nào
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div style={{ marginTop: "10px" }} className="dt-tb dt-text-overflow">
        <div>
          <span>Thông Báo </span>
        </div>
        <span>{shop.notification}</span>
      </div>
      <div className="dt-tb dt-text-overflow">
        <div>
          <span>Mô Tả Quán </span>
        </div>
        <span className="dt-des">{shop.description}</span>
      </div>
      <ReviewSend
        av={user.avURL}
        review={user.reviews}
        username={user.username}
        user_email={user.email}
        rt={shop.rt}
        rp={shop.rp}
        shop_email={shop.email}
        nameshop={shop.nameshop}
        nums={shop.nums}
      />
      <div
        style={{
          transform: message.content.length === 0 ? "scaleY(0)" : "scaleY(1)",
        }}
        className="cart-order-message"
      >
        <div onClick={(e) => setMessage({ content: "", color: "" })}>
          <AiFillCloseSquare size="30px" />
        </div>
        <div style={{ color: message.color }} className="cart-message-text">
          {message.content}
        </div>
        <div style={{ opacity: 0 }}>1</div>
      </div>
    </div>
  );
}

export default ShopDetail;
