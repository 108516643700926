import { FaCcApplePay, FaCcDiscover, FaCcVisa, FaCcPaypal, FaFacebook, FaTiktok, FaYoutube} from "react-icons/fa";
import { AiFillInstagram, AiFillTwitterCircle, AiFillLinkedin, } from "react-icons/ai";
import { BsTelegram } from "react-icons/bs";
import { Swiper, SwiperSlide,  } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from 'axios';
import { version } from "../util/version";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { api } from '../util/api';

import "../css/Footer.css";
import { useState } from "react";
import { useEffect } from "react";

function Footer() {
  const [brands, setBrands] = useState([]);
  const [apps, setApps] = useState([]);

  async function getImgs(type) {
      const res = await axios.get(`${api}/admin/findImage/${type}/${version}`);
      let myimgs = res.data.imgs || []
      if(type === 'brand') setBrands(myimgs || []);
      else setApps(myimgs || []);    
  }

  useEffect(() => {
    getImgs('brand');
    getImgs('app');
  }, []);


  return (
    <div className="footer-box">
      <div className="footer-box-1">
        <h3 className="foot-wlt">We Love Them</h3>
        <div className="footer-box-1-1">
          <span className="footer-box-1-1-line"></span>
          <span className="footer-box-1-1-text">shop by brands</span>
          <span className="footer-box-1-1-line"></span>
        </div>
      </div>
      
      <div className="footer-box-2">
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          slidesPerGroup={5}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {brands.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <LazyLoadImage src={item.data} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      
      <div className="footer-box-3">
        <div className="footer-box-3-item">
          <div>
            <h4>CATEGORY</h4>
            <span className="footer-text-style">fast food</span>
            <span className="footer-text-style">asian food</span>
            <span className="footer-text-style">vegetable</span>
            <span className="footer-text-style">china food</span>
          </div>
        </div>
        <div className="footer-box-3-item">
          <div>
            <h4>NEED HELP</h4>
            <span className="footer-text-style">Customer Care</span>
            <span className="footer-text-style">Became shipper</span>
            <span className="footer-text-style">Became A Seller</span>
            <span className="footer-text-style">Support Center</span>
          </div>
        </div>
        <div className="footer-box-3-item">
          <div>
            <h4>COMPANY</h4>
            <span className="footer-text-style">your business</span>
            <span className="footer-text-style">Delivery Information</span>
            <span className="footer-text-style">Exchange Policy</span>
            <span className="footer-text-style">Terms & Conditions</span>
          </div>
        </div>
        <div className="footer-box-3-item">
          <div>
            <h4>CORPORATE</h4>
            <span className="footer-text-style">Affiliate Program</span>
            <span className="footer-text-style">Accessibility</span>
            <span className="footer-text-style">Promotions</span>
            <span className="footer-text-style">XStore Business</span>
          </div>
        </div>
        <div className="footer-box-3-item">
          <div>
            <h4>STAY CONNECTED</h4>
            <span className="footer-text-style">+084 0942660894</span>
            <span className="footer-text-style">+254 1888 333 55550</span>
            <span className="footer-text-style">lucky8xbn@gmail.com</span>
          </div>
        </div>
      </div>
      <hr style={{width: "98%"}}/>
      <div className="footer-box-4">
          <div>
            <h4 style={{textAlign: 'center'}}>PAYMENT OPTIONS</h4>
            <div className="footer-box-4-bot">
                <FaCcApplePay  size={70}/> 
                <FaCcDiscover size={70}/> 
                <FaCcVisa size={70}/> 
                <FaCcPaypal size={70}/>
            </div>
          </div>
          {apps.length > 0 ? <div>
            <h4 style={{textAlign: 'center'}}>DOWNLOAD APP</h4>
            <div className="footer-box-4-bot footer-cl">
                <LazyLoadImage className="app-image" src={apps[2].data} />
                <LazyLoadImage className="app-image" src={apps[1].data} />
                <LazyLoadImage className="app-image" src={apps[0].data} />
            </div>
          </div> : ""}
          <div>
            <h4 style={{textAlign: 'center'}}>SOCIAL MEDIA</h4>
            <div className="footer-box-4-bot">
                <FaFacebook size={30}/>
                <FaTiktok size={30}/>
                <FaYoutube size={30}/>
                <AiFillInstagram size={30}/>
                <AiFillTwitterCircle size={30}/>
                <AiFillLinkedin size={30}/>
                <BsTelegram size={30}/>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Footer;
