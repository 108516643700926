import "./css/App.css"
import "./component/AppBar";
import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import AppBar from "./component/AppBar";
import Register from "./component/Register";
import Home from "./component/Home";
import { useEffect, useState, useRef } from "react";
import Login from "./component/Login";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../src/redux/user";
import { cartActions } from "../src/redux/cart";
import Cookies from "js-cookie";
import axios from "axios";
import { api } from "../src/util/api";
import Profile from "./component/Profile";
import Footer from "./component/Footer";
import ShopDetail from "./component/ShopDetail";
import SearchProductScreen from "./component/SearchProductScreen";
import Cart from "./component/Cart";
import io from "socket.io-client";
import sound from "./sound/sound.wav";
import { GoMute, GoUnmute } from "react-icons/go";
import Admin from "./component/Admin";
import { dataActions } from "./redux/data";
import Term from "./component/Term";

function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [connect, setConnect] = useState(false);
  const [socket, setSocket] = useState(null);
  const [muted, setMuted] = useState(true);
  const audioRef = useRef();
  

  useEffect(() => {
    setSocket(io.connect(`${api}`));
  }, []);

  useEffect(() => {
    if (user.email === "" || socket === null || connect === true) return;
    socket?.emit("newUser", user.email, user.nums);
    if (user.roles.includes("seller"))
      socket?.on("pushOrder", (data) => {
        let newNotifications = [data, ...user.notification];
        dispatch(userActions.updateNotifications(newNotifications));
        if (!audioRef.current.muted) audioRef.current.play();
      });
    setConnect(true);
  }, [socket, user]);

  async function fetchCity() {
    const res = await axios.get(`${api}/admin/data/citys`);
    if (res.data.message === "ok") {
      dispatch(dataActions.replaceCitys(res.data.citys));
    }
  }

  useEffect(() => {
    fetchCity();
    async function fetchData() {
      if (!Cookies.get("token")) {
        dispatch(userActions.logout());
        return;
      }
      const token = JSON.parse(Cookies.get("token")).replaceAll('"', "");
      const res = await axios.post(
        `${api}/user/auto_login`,
        {},
        { headers: { authorization: "Bearer " + token } }
      );
      if (res.data.message === "ok") {
        dispatch(
          userActions.login({
            username: res.data.user.username,
            email: res.data.user.email,
            phone: res.data.user.phone,
            address: res.data.user.address,
            avURL: res.data.user.avURL,
            avID: res.data.user.avID,
            cc: res.data.user.cc,
            status: res.data.user.status,
            level: res.data.user.level,
            city: res.data.user.city,
            town: res.data.user.town,
            village: res.data.user.village,
            type: res.data.user.type,
            notification: res.data.user.notification,
            favorite: res.data.user.favorite,
            message: res.data.user.message,
            orderBuy: res.data.user.orderBuy,
            orderSell: res.data.user.orderSell,
            reviews: res.data.user.review,
            code: res.data.user.code,
            roles: res.data.user.role,
            cityShop: res.data.user.cityShop,
            nums: res.data.user.nums,
          })
        );
        if (res.data.user.city !== "" && !Cookies.get("city"))
          Cookies.set("city", res.data.user.city);
        if (res.data.user.town !== "" && !Cookies.get("town"))
          Cookies.set("town", res.data.user.town);
        if (res.data.user.village !== "" && !Cookies.get("village"))
          Cookies.set("village", res.data.user.village);
      } else {
        dispatch(userActions.logout());
      }
    }
    fetchData();
    dispatch(cartActions.getCart());
  }, []);

  return (
    <div className="App">
      <AppBar />
      <audio ref={audioRef} src={sound} muted={muted}>
        {" "}
      </audio>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route
          path="/shop_detail/:city/:town/:village/:nameshop/:email/:menu"
          exact
        >
          <ShopDetail socket={socket} />
        </Route>
        <Route
          path="/searchProduct/:city/:town/:village/:keyword/:sort/:page"
          exact
        >
          <SearchProductScreen />
        </Route>
        <Route path="/register" exact>
          {user.email === "" ? <Register /> : <Profile />};
        </Route>
        <Route path="/login" exact>
          {user.email === "" ? <Login /> : <Profile />};
        </Route>
        <Route path="/profile" exact>
          {user.email !== "" ? <Profile /> : <Login />};
        </Route>
        <Route path="/admin/100488" exact>
          {user.roles.includes("admin") ? <Admin /> : <Home />}
        </Route>
        <Route path="/cart" exact>
          <Cart socket={socket} audioRef={audioRef} />;
        </Route>
        <Route path="/term" exact>
          <Term />
        </Route>
      </Switch>
      <Footer />
      {user.roles.includes("seller") && (
        <div
          style={{ backgroundColor: !muted ? "#FB2576" : "#2B3A55" }}
          onClick={(e) => {
            setMuted(!muted);
          }}
          className="sound-open"
        >
          {muted && <GoMute />}
          {!muted && <GoUnmute />}
        </div>
      )}
    </div>
  );
}

export default App;
