import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { api } from "../util/api";
import axios from "axios";
import "../css/MyShop.css";
import { ImImages, ImSad } from "react-icons/im";
import { BsArrowDownSquareFill } from "react-icons/bs";
import stringFix from "../util/stringFix";
import Loading from "./Loading";
import { userActions } from "../redux/user";
import { LazyLoadImage } from "react-lazy-load-image-component";

function MyShopScreen() {
  const user = useSelector((state) => state.user);
  const mycitys = useSelector((state) => state.data.citys);
  const history = useHistory();
  const dispatch = useDispatch();
  const [imgShop, setImageShop] = useState("");
  const [imgs, setImgs] = useState({
    avShop: "",
    mt: "",
    ms: "",
  });
  const [minPrice, setMinPrice] = useState(-1);
  const [maxPrice, setMaxPrice] = useState(-1);
  const [free, setFree] = useState("");
  const [previews, setPreviews] = useState(["", "", ""]);
  const [nameshop, setNameshop] = useState("");
  const [indexImg, setIndexImg] = useState(-1);
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [cc, setCC] = useState("");
  const [address, setAddress] = useState("");
  const [keyword, setKeyword] = useState("");
  const [citys, setCitys] = useState([]);
  const [towns, setTowns] = useState([]);
  const [villages, setVillages] = useState([]);
  const [city, setCity] = useState("Chọn Tỉnh - Thành");
  const [town, setTown] = useState("Chọn Quận - Huyện");
  const [village, setVillage] = useState("Chọn Phường - Xã");
  const [indexTime, setIndexTime] = useState(-1);
  const [fieldTime, setFieldTime] = useState("");
  const [meShip, setMeShip] = useState("true");
  const [showCost, setShowCost] = useState(-1);
  const [username, setUsername] = useState("");
  const [openTime, setOpenTime] = useState([
    { day: "thứ hai", from: 0, to: 24 },
    { day: "thứ ba", from: 0, to: 24 },
    { day: "thứ tư", from: 0, to: 24 },
    { day: "thứ năm", from: 0, to: 24 },
    { day: "thứ sáu", from: 0, to: 24 },
    { day: "thứ bảy", from: 0, to: 24 },
    { day: "chủ nhật", from: 0, to: 24 },
  ]);
  const [roles, setRoles] = useState([]);
  const [checkData, setCheckData] = useState({ name: "false", cc: "false" });
  const [cost10km, setCost10km] = useState(0);
  const [cost4km, setCost4km] = useState(0);
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(-1);
  const [location, setLocation] = useState({
    type: "Point",
    coordinates: ["", ""],
  });
  const [getShop, setGetShop] = useState("");
  const [notification, setNotification] = useState("");
  const time = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24,
  ];
  const [isLoading, setIsLoading] = useState(false);
  const costs10km = [
    0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000,
    12000, 13000, 14000, 15000,
  ];
  const costs4km = [0, 1000, 2000, 3000, 4000, 5000];
  const [readTerm, setReadTerm] = useState(false);
  useEffect(() => {
    if (user.email === "") history.push("/");
    const citysArr = [];
    const townsArr = [];
    const villageArr = [];
    for (let i = 0; i < mycitys.length; i++) {
      citysArr.push(mycitys[i]["city"]);
    }
    const getShopData = async () => {
      if (user.roles.indexOf("seller") >= 0) {
        const res = await axios.get(`${api}/shop/getShop/${user.email}`);
        if (res.data.message === "ok") setGetShop(res.data.shop);
        else return setGetShop(false);
        for (let i = 0; i < mycitys.length; i++) {
          if (mycitys[i].city === res.data.shop.city) {
            for (let j = 0; j < mycitys[i]["towns"].length; j++) {
              townsArr.push(mycitys[i]["towns"][j]);
              if (mycitys[i]["towns"][j]["town"] === res.data.shop.town) {
                for (
                  let k = 0;
                  k < mycitys[i]["towns"][j]["village"].length;
                  k++
                ) {
                  villageArr.push(mycitys[i]["towns"][j]["village"][k]);
                }
              }
            }
          }
        }
        setTowns(townsArr);
        setVillages(villageArr);
        setCity(res.data.shop.city);
        setTown(res.data.shop.town);
        setVillage(res.data.shop.village);
        setAddress(res.data.shop.address);
        setNameshop(res.data.shop.nameshop);
        setUsername(res.data.shop.username);
        setMinPrice(res.data.shop.minPrice);
        setMaxPrice(res.data.shop.maxPrice);
        setPhone(res.data.shop.phone);
        setCC(res.data.shop.cc);
        setKeyword(res.data.shop.keyword);
        setLocation(res.data.shop.location);
        setOpenTime(res.data.shop.openTime);
        setFree(res.data.shop.free);
        setNotification(res.data.shop.notification);
        setDescription(res.data.shop.description);
        setPreviews([
          res.data.shop.avShop.url,
          res.data.shop.ccMt.url,
          res.data.shop.ccMs.url,
        ]);
        setMeShip(res.data.shop.meShip);
        setCost4km(res.data.shop.cost4km);
        setCost10km(res.data.shop.cost10km);
      }
    };
    getShopData();
    setCitys(citysArr);
  }, []);

  useEffect(() => {
    if (imgShop !== "" || indexImg !== -1) {
      const newPreviews = [...previews];
      newPreviews[indexImg] = window.URL.createObjectURL(imgShop[0]);
      setPreviews(newPreviews);
    }
    return () => {
      window.URL.revokeObjectURL(previews[indexImg]);
    };
  }, [imgShop]);

  async function checkName(e) {
    e.preventDefault();
    if (nameshop.trim().length < 10) {
      return setMessage("Tên Shop phải có 10 ký tự trở lên");
    }
    if (nameshop.trim().length > 60) {
      {
        return setMessage("Tên shop phải phải có độ dài ít hơn 60 ký tự");
      }
    }
    const ns = stringFix(nameshop).trim();
    const validCharacter = "-&,0123456789qwertyuiopasdfghjklzxcvbnm ";
    for (let i = 0; i < ns.length; i++) {
      if (!validCharacter.includes(ns[i])) {
        return setMessage(
          "Tên shop chỉ được chứa số, chữ cái và 4 ký tự - & . ,"
        );
      }
    }
    setIsLoading(true);
    const res = await axios.post(`${api}/shop/checkName`, {
      city: city,
      name: nameshop.trim(),
    });
    if (res.data.message !== "ok") {
      setIsLoading(false);
      return setMessage(res.data.message);
    }
    setCheckData({ ...checkData, name: "true" });
    setIsLoading(false);
  }

  const handleRegiserShop = async (e) => {
    e.preventDefault();
    if (user.nums === undefined) {
      setIsLoading(false);
      return setMessage("Lỗi tài khoản vui lòng đăng nhập lại");
    }
    if (minPrice < 0 || maxPrice < -1) {
      setIsLoading(false);
      return setMessage("Chưa điền thông tin giá tối thiểu hoặc giá tối đa");
    }
    if (checkData.name === "false") {
      setIsLoading(false);
      return setMessage("Chưa kiểm tra tên");
    }
    if (username === "") {
      setIsLoading(false);
      return setMessage("Thiếu thông tin tên người đại diện");
    }
    if (imgs.avShop === "" || imgs.mt === "" || imgs.ms === "") {
      setIsLoading(false);
      return setMessage("thiếu ảnh đại diện hoặc ảnh căn cước");
    }
    if (nameshop === "") {
      setIsLoading(false);
      return setMessage("Thiếu tên shop");
    }
    if (phone === "" || cc === "") {
      setIsLoading(false);
      return setMessage("Thiếu số điện thoại hoặc số thể căn cước");
    }
    if (address === "" || keyword === "") {
      setIsLoading(false);
      return setMessage("Thiếu địa chỉ số nhà hoặc từ khoá tìm kiếm");
    }
    if (
      city === "Chọn Tỉnh - Thành" ||
      town === "Chọn Quận - Huyện" ||
      village === "Chọn Phường - Xã"
    ) {
      setIsLoading(false);
      return setMessage(
        "Chưa chọn Tỉnh - Thành, Quận - Huyện, hoặc Phường - xã"
      );
    }
    if (description === "") {
      setIsLoading(false);
      return setMessage("Thiếu mô tả quán");
    }
    setIsLoading(true);
    const form = new FormData();
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    location.coordinates[0] = parseFloat(location.coordinates[0]) || 0;
    location.coordinates[1] = parseFloat(location.coordinates[1]) || 0;
    location.coordinates[0] =
      Math.abs(location.coordinates[0]) > 180 ? 0 : location.coordinates[0];
    location.coordinates[1] =
      Math.abs(location.coordinates[1]) > 90 ? 0 : location.coordinates[1];
    form.append("year", parseInt(year));
    form.append("month", parseInt(month));
    form.append("nameshop", nameshop.trim());
    form.append("minPrice", parseInt(minPrice));
    form.append("maxPrice", parseInt(maxPrice));
    form.append(
      "nameSearch",
      stringFix(nameshop).trim().toLowerCase() +
        " " +
        stringFix(keyword).toLowerCase()
    );
    form.append("phone", stringFix(phone).trim());
    form.append("cc", stringFix(cc).trim());
    form.append("address", stringFix(address).trim());
    form.append("keyword", stringFix(keyword).trim());
    form.append("email", stringFix(user.email).trim());
    form.append("username", username);
    form.append("notification", notification);
    form.append("city", city);
    form.append("town", town);
    form.append("village", village);
    form.append("free", free);
    form.append("location", JSON.stringify(location));
    form.append("description", description);
    form.append("meShip", meShip);
    form.append("cost10km", parseInt(cost10km));
    form.append("cost4km", parseInt(cost4km));
    form.append("openTime", JSON.stringify(openTime));
    form.append("avShop", JSON.stringify({ id: "", url: "", filename: "" }));
    form.append("ccMt", JSON.stringify({ id: "", url: "", filename: "" }));
    form.append("ccMs", JSON.stringify({ id: "", url: "", filename: "" }));
    form.append("files", imgs.avShop);
    form.append("files", imgs.mt);
    form.append("files", imgs.ms);
    form.append("nums", user.nums);

    const res = await axios.post(`${api}/shop/register_shop`, form);
    if (res.data.message === "ok")
      dispatch(
        userActions.updateRoles({ roles: [...user.roles, "register_shop"] })
      );
    else setMessage(res.data.message);
    setIsLoading(false);
  };

  const handlerUpdateShop = async () => {
    if (minPrice < 0 || maxPrice < -1)
      return setMessage("Chưa điền thông tin giá tối thiểu hoặc giá tối đa");
    if (username === "")
      return setMessage("Thiếu thông tin tên người đại diện");
    if (phone === "" || cc === "")
      return setMessage("Thiếu số điện thoại hoặc số thể căn cước");
    if (address === "" || keyword === "")
      return setMessage("Thiếu địa chỉ số nhà hoặc từ khoá tìm kiếm");
    if (
      city === "Chọn Tỉnh - Thành" ||
      town === "Chọn Quận - Huyện" ||
      village === "Chọn Phường - Xã"
    )
      return setMessage(
        "Chưa chọn Tỉnh - Thành, Quận - Huyện, hoặc Phường - xã"
      );
    if (description === "") return setMessage("Thiếu mô tả quán");

    setIsLoading(true);
    const form = new FormData();
    location.coordinates[0] = parseFloat(location.coordinates[0]) || 0;
    location.coordinates[1] = parseFloat(location.coordinates[1]) || 0;
    location.coordinates[0] =
      Math.abs(location.coordinates[0]) > 180 ? 0 : location.coordinates[0];
    location.coordinates[1] =
      Math.abs(location.coordinates[1]) > 90 ? 0 : location.coordinates[1];
    form.append("minPrice", parseInt(minPrice));
    form.append("maxPrice", parseInt(maxPrice));
    form.append("phone", stringFix(phone).trim());
    form.append("address", stringFix(address).trim());
    form.append("keyword", stringFix(keyword).trim());
    form.append(
      "nameSearch",
      stringFix(nameshop).trim().toLowerCase() +
        " " +
        stringFix(keyword).toLowerCase()
    );
    form.append("email", user.email);
    form.append("username", username);
    form.append("notification", notification);
    form.append("city", city);
    form.append("town", town);
    form.append("village", village);
    form.append("free", free);
    form.append("location", JSON.stringify(location));
    form.append("description", description);
    form.append("cost10km", parseInt(cost10km));
    form.append("cost4km", parseInt(cost4km));
    form.append("openTime", JSON.stringify(openTime));

    const res = await axios.post(`${api}/shop/updateshop`, form);
    if (res.data.message === "ok") setMessage("update shop thành công");
    else setMessage(res.data.message);
    setIsLoading(false);
  };

  if (isLoading) {
    return <div className="myshop-loading-box"> 
      <Loading />
    </div>
  }

  if (
    !readTerm &&
    user.roles.indexOf("seller") < 0 &&
    user.roles.indexOf("register_shop") < 0
  ) {
    return (
      <div className="myshop-terms-container">
        <div style={{ fontSize: "25px", color: "orange", fontWeight: "bold" }}>
          Terms and Conditions
        </div>
        <div className="myshop-terms-box">
          <div className="myshop-terms-content">
            <div style={{ color: "#6C4AB6", fontWeight: "bold" }}>
              Chú ý: hiện tại myshop mới triển khai chưa có đội vận chuyển shop
              chỉ có thể tự giao hàng đồng ý thì ấn vào Accept. Đọc kỹ quy định
              và ví dụ trước khi đăng ký
            </div>
            <p>Last update: 15-11-2022</p>
            <p>1. myshop thu 2000vnd cho mỗi đơn hàng</p>
            <p>
              2. shop có thể tự giao hàng và thu tiền giao hàng từ người mua,
              tối đa 15k cho 3km đầu và thêm 5k cho mỗi km thứ 4 trở lên
            </p>
            <p>
              3. shop mất thêm 5000vnd cho shipper cho mỗi đơn hàng nếu người
              bán nếu đơn hàng do bên thứ 3 giao hàng
            </p>
            <p>
              4. không đăng ảnh khiêu dâm, lời lẽ tục tĩu, phản cảm để mô tả sản
              phẩm{" "}
            </p>
            <p>
              5. chỉ bán những sản phẩm liên quan đến ăn uống, không bán hàng
              cấm, hàng giả, hàng nhái...
            </p>
            <p>
              6. mỗi tài khoản giới hạn 100 sản phẩm, tên shop, ảnh đại diện sau
              khi đăng ký không thể thay đổi
            </p>
            <p>
              7. thông tin đăng ký địa chỉ, số điện thoại, số thẻ căn cước phải
              chính xác và có thật
            </p>
            <p>
              8. khi giao hàng phải thu đúng giá, số lượng, chi phí ship khớp
              với đơn đặt hàng
            </p>
            <p>
              9. shop vi phạm một trong các quy định trên sẽ bị khoá tài khoản
              vĩnh viễn
            </p>
            <span style={{ color: "#5837D0", fontWeight: "bold" }}>
              Ví dụ bán hàng:{" "}
            </span>
            shop đặt phí 10km đầu = 15000.vnd và km thứ 4 = 5000.vnd
            <p>
              -{" "}
              <span style={{ color: "#1746A2", fontWeight: "bold" }}>
                shop có đơn hàng 40000.vnd{" "}
              </span>
              có 2 lựa chọn thanh toán sau:
            </p>
            <p>
              -{" "}
              <span style={{ color: "#1746A2", fontWeight: "bold" }}>
                Gọi ship bên thứ 3:
              </span>{" "}
              myshop thu 2000.vnd, shipper thu 5000.vnd, shop thu về: 4000 -
              2000 - 5000 ={" "}
              <span style={{ color: "#B73E3E", fontWeight: "bold" }}>
                37000.vnd{" "}
              </span>
            </p>
            <p>
              -{" "}
              <span style={{ color: "#1746A2", fontWeight: "bold" }}>
                Tự ship:
              </span>{" "}
              myshop thu 2000.vnd, shop thu về: 4000 - 2000 + 15000 + 5000 ={" "}
              <span style={{ color: "#B73E3E", fontWeight: "bold" }}>
                58000.vnd
              </span>
            </p>
          </div>
        </div>
        <div className="readTerm-btn">
          <p>Decline</p>
          <p
            onClick={(e) => {
              setReadTerm(1);
            }}
          >
            Accept
          </p>
        </div>
      </div>
    );
  }

  if (user.roles.indexOf("register_shop") >= 0) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          color: "green",
        }}
      >
        Đăng ký shop thành công, xin vui lòng chờ phê duyệt
      </div>
    );
  }

  if (
    (getShop === "" || getShop === false) &&
    user.roles.indexOf("seller") >= 0
  ) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          color: "green",
        }}
      >
        Không tìm thấy thông tin shop, vui lòng kiểm tra lại internet hoặc shop
        của bạn đã bị khoá
      </div>
    );
  }

  return (
    <div className="myshop-box">
      <div className="myshop-local-container">
        <div onClick={(e) => setPopup(1)} className="myshop-local-box">
          <label>Tỉnh - Thành</label>
          <div className="local-box">
            <span>{city}</span>
            <BsArrowDownSquareFill />
          </div>
        </div>
        <div
          onClick={(e) => {
            const townsArray = [];
            for (let i = 0; i < mycitys.length; i++) {
              if (mycitys[i]["city"] === city) {
                for (let j = 0; j < mycitys[i]["towns"].length; j++) {
                  townsArray.push(mycitys[i]["towns"][j]["town"]);
                }
              }
            }
            setTowns(townsArray);
            setPopup(2);
          }}
          className="myshop-local-box"
        >
          <label>Quận - Huyện</label>
          <div>
            <span>{town}</span>
            <BsArrowDownSquareFill />
          </div>
        </div>
        <div
          onClick={(e) => {
            const villagesArray = [];
            for (let i = 0; i < mycitys.length; i++) {
              if (mycitys[i]["city"] === city) {
                for (let j = 0; j < mycitys[i]["towns"].length; j++) {
                  if (mycitys[i]["towns"][j]["town"] === town) {
                    for (
                      let k = 0;
                      k < mycitys[i]["towns"][j]["village"].length;
                      k++
                    ) {
                      villagesArray.push(mycitys[i]["towns"][j]["village"][k]);
                    }
                  }
                }
              }
            }
            setVillages(villagesArray);
            setPopup(3);
          }}
          className="myshop-local-box"
        >
          <label>Phường - Xã</label>
          <div>
            <span>{village}</span>
            <BsArrowDownSquareFill />
          </div>
        </div>
      </div>

      <div className="myshop-address-keyword">
        <div>
          <label>số nhà - đường phố</label>
          <input
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            maxLength="50"
            placeholder="48 đường âu cơ, 35 đường trần hưng đạo..."
            className=""
          />
        </div>
        <div>
          <label>Từ khóa tìm kiếm</label>
          <input
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            maxLength="30"
            placeholder="bánh mì, phở, cơm rang..."
            className=""
          />
        </div>
      </div>

      <div className="myshop-guide-map-box">
        <div>
          <span className="myshop-guide-map">
            Hướng dẫn lấy tọa độ Google Map trên:
          </span>
          <span className="myshop-guide-map-platform">máy tính</span>-
          <span className="myshop-guide-map-platform">điện thoại</span>
        </div>
      </div>

      <div style={{ marginTop: "10px" }} className="myshop-address-keyword">
        <div>
          <label>Kinh độ</label>

          <input
            type="text"
            name=""
            value={location.coordinates[0]}
            onChange={(e) => {
              const nums = "-.0123456789";
              let value = "";
              for (let i = 0; i < e.target.value.length; i++) {
                if (nums.includes(e.target.value[i]))
                  value = value + e.target.value[i];
              }
              setLocation({
                ...location,
                coordinates: [value, location.coordinates[1]],
              });
            }}
            maxLength="50"
            placeholder="-25.5577"
            className=""
          />
        </div>
        <div>
          <label>Vĩ Độ</label>
          <input
            type="text"
            value={location.coordinates[1]}
            onChange={(e) => {
              const nums = "-.0123456789";
              let value = "";
              for (let i = 0; i < e.target.value.length; i++) {
                if (nums.includes(e.target.value[i]))
                  value = value + e.target.value[i];
              }
              setLocation({
                ...location,
                coordinates: [location.coordinates[0], value],
              });
            }}
            maxLength="50"
            placeholder="15.3556"
            className=""
          />
        </div>
      </div>

      <div style={{ marginTop: "10px" }} className="myshop-address-keyword">
        <div>
          <label>Email</label>
          <input
            value={user.email}
            readOnly={true}
            maxLength="100"
            placeholder="khabanh@gmail.com"
            className=""
          />
        </div>
        <div>
          <label>Tên người đại diện</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            maxLength="50"
            placeholder="ngô bá khá"
            className=""
          />
        </div>
      </div>

      <div style={{ marginTop: "10px" }} className="myshop-address-keyword">
        <div>
          <label>Giá tối thiểu</label>
          <input
            type="number"
            value={minPrice}
            onChange={(e) => {
              setMinPrice(e.target.value);
            }}
            maxLength="12"
            placeholder="khabanh@gmail.com"
            className=""
          />
        </div>
        <div>
          <label>Giá tối đa</label>
          <input
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            maxLength="12"
            placeholder="ngô bá khá"
            className=""
          />
        </div>
      </div>

      <p style={{ height: "5px" }}></p>
      <div className="myshop-info-top-box">
        <div className="myshop-anhdaidien">
          <input
            style={{
              display: user.roles.indexOf("seller") >= 0 ? "none" : "block",
            }}
            onChange={(e) => {
              let target = e.target || e.srcElement;
              if (target.value.length === 0) return;
              setImageShop(e.target.files);
              setIndexImg(0);
              setImgs({ ...imgs, avShop: e.target.files[0] });
            }}
            className="myshop-input-anhdaidien"
            type="file"
          ></input>
          {previews[0] === "" && <button>Ảnh đại diện quán</button>}
          {previews[0] !== "" && (
            <LazyLoadImage
              src={previews[0]}
              width={400}
              height={400}
            ></LazyLoadImage>
          )}
        </div>

        <div className="myshop-input-info">
          <div className="myshop-input-box">
            <label>Tên cửa hàng</label>
            <input
              maxLength="60"
              value={nameshop}
              placeholder="quán phở hà nội, bánh mì sài gòn..."
              onChange={(e) => {
                if (user.roles.indexOf("seller") >= 0) return;
                setNameshop(e.target.value);
                setCheckData({ ...checkData, name: "false" });
              }}
              type="text"
            ></input>
            {user.roles.indexOf("seller") < 0 && (
              <p onClick={(e) => checkName(e)} className="ktt">
                {checkData.name === "true" ? "Tên hợp lệ" : "Kiểm tra tên"}
              </p>
            )}
          </div>

          <div className="myshop-input-box-2">
            <div className="myshop-input-box-2-1">
              <label>Số điện thoại</label>
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="09123456"
                maxLength="12"
              ></input>
            </div>
            <div className="myshop-input-box-2-1">
              <label>Số thẻ căn cước</label>
              <input
                value={cc}
                onChange={(e) => {
                  if (user.roles.indexOf("seller") >= 0) return;
                  setCC(e.target.value);
                  setCheckData({ ...checkData, cc: false });
                }}
                placeholder="123456789"
                maxLength="15"
              ></input>
            </div>
          </div>

          <div className="myshop-input-box-2">
            <div className="myshop-input-img-cc">
              <label>Ảnh căn cước mặt trước</label>
              <div>
                <input
                  style={{
                    display:
                      user.roles.indexOf("seller") >= 0 ? "none" : "block",
                  }}
                  onChange={(e) => {
                    let target = e.target || e.srcElement;
                    if (target.value.length === 0) return;
                    setImageShop(e.target.files);
                    setIndexImg(1);
                    setImgs({ ...imgs, mt: e.target.files[0] });
                  }}
                  type="file"
                  placeholder=""
                ></input>
                {previews[1] !== "" && (
                  <LazyLoadImage
                    className="img-cc"
                    src={previews[1]}
                  ></LazyLoadImage>
                )}
                {previews[1] === "" && (
                  <ImImages className="myshop-upload-cc-icon" />
                )}
              </div>
            </div>
            <div className="myshop-input-img-cc">
              <label>Ảnh căn cước mặt sau</label>
              <div>
                <input
                  style={{
                    display:
                      user.roles.indexOf("seller") >= 0 ? "none" : "block",
                  }}
                  onChange={(e) => {
                    let target = e.target || e.srcElement;
                    if (target.value.length === 0) return;
                    setImageShop(e.target.files);
                    setIndexImg(2);
                    setImgs({ ...imgs, ms: e.target.files[0] });
                  }}
                  type="file"
                  placeholder=""
                ></input>
                {previews[2] !== "" && (
                  <LazyLoadImage className="img-cc" src={previews[2]} />
                )}
                {previews[2] === "" && (
                  <ImImages className="myshop-upload-cc-icon" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {message !== "" && (
        <div className="myshop-message">
          <p></p>
          <p className="myshop-message-content">{message}</p>
          <p
            onClick={(e) => {
              setMessage("");
            }}
            className="myshop-close-message"
          >
            Đóng cửa sổ
          </p>
        </div>
      )}

      <label className="time-open">Thời gian mở cửa</label>
      <div className="myshop-open-time-box">
        {openTime.map((item, indexDay) => {
          return (
            <div key={indexDay}>
              <label>{item.day}</label>

              <div className="myshop-time">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setIndexTime(indexDay);
                    setFieldTime("from");
                  }}
                >
                  {item.from} giờ
                </span>
                <BsArrowDownSquareFill />
                {indexDay === indexTime && fieldTime === "from" && (
                  <div className="myshop-dropdown-time">
                    {time.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            const newTime = openTime;
                            newTime[indexDay].from = item;
                            setIndexTime(-1);
                            setFieldTime("");
                            setOpenTime(newTime);
                          }}
                          className="myshop-time-item"
                        >
                          {item} giờ
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <div
                style={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                đến
              </div>
              <div className="myshop-time">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setIndexTime(indexDay);
                    setFieldTime("to");
                  }}
                >
                  {item.to} giờ
                </span>
                <BsArrowDownSquareFill />
                {indexDay === indexTime && fieldTime === "to" && (
                  <div className="myshop-dropdown-time">
                    {time.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            const newTime = openTime;
                            newTime[indexDay].to = item;
                            setIndexTime(-1);
                            setFieldTime("");
                            setOpenTime(newTime);
                          }}
                          className="myshop-time-item"
                        >
                          {item} giờ
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          width: "100%",
          textAlign: "left",
          marginTop: "5px",
          fontWeight: "bold",
        }}
      >
        Ưu đãi
      </div>
      <div className="myshop-free">
        <p className="myshop-ship-item">
          <input
            maxLength="200"
            placeholder="miễn phí ship trong vòng 3km, miễn phí ship cùng phường - xã, miễn phí ship đơn hàng trên 50k, giảm giá ngày chủ nhật..."
            value={free}
            onChange={(e) => {
              setFree(e.target.value);
            }}
            type="text"
          ></input>
        </p>
      </div>
      <div className="myshop-ship-box">
        <p>
          <span>Bật chế độ tự giao hàng</span>
          {user.roles.indexOf("seller") ? (
            <input
              checked={meShip === "true" ? true : false}
              readOnly={true}
              type="checkbox"
            />
          ) : (
            <input
              checked={meShip === "true" ? true : false}
              // onClick={(e) => {
              //   setMeShip(e.target.checked ? true : false);
              // }}
              type="checkbox"
              readOnly={true}
            />
          )}
        </p>
        <p>
          <span
            onClick={(e) => {
              setShowCost(showCost === 11 ? -1 : 1);
            }}
          >
            Phí giao hàng cho 3km đầu:{" "}
            <span style={{ color: "#829460", fontWeight: "bold" }}>
              {cost10km}.vnd
            </span>
          </span>
          <BsArrowDownSquareFill />
          {showCost === 1 && (
            <span className="myshop-cost-dropdown">
              {costs10km.map((item, index) => {
                return (
                  <span
                    onClick={(e) => {
                      setCost10km(item);
                      setShowCost(-1);
                    }}
                    key={index}
                  >
                    {item}.vnđ
                  </span>
                );
              })}
            </span>
          )}
        </p>
        <p>
          <span
            onClick={(e) => {
              setShowCost(showCost === 2 ? -1 : 2);
            }}
          >
            Phí giao hàng cho km thứ 4:{" "}
            <span style={{ color: "#829460", fontWeight: "bold" }}>
              {cost4km}.vnd
            </span>
          </span>
          <BsArrowDownSquareFill />
          {showCost === 2 && (
            <span className="myshop-cost-dropdown">
              {costs4km.map((item, index) => {
                return (
                  <span
                    onClick={(e) => {
                      setCost4km(item);
                      setShowCost(-1);
                    }}
                    key={index}
                  >
                    {item}.vnđ
                  </span>
                );
              })}
            </span>
          )}
        </p>
      </div>
      <div className="myshop-notification">
        <span>Thông báo của shop</span>
        <input
          maxLength="200"
          value={notification}
          onChange={(e) => setNotification(e.target.value)}
        ></input>
      </div>
      <div
        style={{
          textAlign: "left",
          width: "100%",
          marginBottom: "5px",
          fontWeight: "bold",
        }}
      >
        Mô tả quán
      </div>
      <textarea
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        maxLength="1000"
        placeholder="Quán ăn sạch sẽ, giá rẻ, có wifi, view đẹp..."
        className="myshop-description"
        rows="10"
      ></textarea>
      {user.roles.indexOf("seller") < 0 ? (
        <button
          onClick={(e) => handleRegiserShop(e)}
          className="myshop-btn-dkbh"
        >
          Đăng ký bán hàng
        </button>
      ) : (
        <button
          onClick={(e) => handlerUpdateShop(e)}
          className="myshop-btn-dkbh"
        >
          cập nhật thông tin cửa hàng
        </button>
      )}
      {popup !== -1 && (
        <div className="myshop-local-dropdown-box">
          {popup === 1 &&
            citys.map((item, index) => {
              return (
                <p
                  onClick={(e) => {
                    e.preventDefault();

                    setTown("Chọn Quận - Huyện");
                    setVillage("Chọn Phường - Xã");
                    setCity(item);
                    setTowns([]);
                    setVillages([]);
                    setPopup(-1);
                  }}
                  key={index}
                >
                  {item}
                </p>
              );
            })}

          {popup === 2 &&
            towns.map((item, index) => {
              return (
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    setVillage("Chọn Phường - Xã");
                    setTown(item);
                    setVillages([]);
                    setPopup(-1);
                  }}
                  key={index}
                >
                  {item}
                </p>
              );
            })}

          {popup === 3 &&
            villages.map((item, index) => {
              return (
                <p
                  onClick={(e) => {
                    e.preventDefault();
                    setVillage(item);
                    setPopup(-1);
                  }}
                  key={index}
                >
                  {item}
                </p>
              );
            })}
        </div>
      )}
    </div>
  );
}
export default MyShopScreen;
