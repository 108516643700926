import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./user";
import cartSlice from "../redux/cart";
import dataSlice from "../redux/data";

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        cart: cartSlice.reducer,
        data: dataSlice.reducer,
    }
});

export default store;
