import React, { useState, useEffect } from "react";
import "../css/Products.css";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { api } from "../util/api";
import axios from "axios";
import Card from "../component/Card";
import { FiLoader } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import Search from "./Search";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import MyShopScreen from "./MyShopScreen";

function Products() {
  const user = useSelector((state) => state.user);
  const mycitys = useSelector((state) => state.data.citys);
  const cityLoading = useSelector((state) => state.data.isLoadingCity);
  const dispatch = useDispatch();
  const [citys, setCitys] = useState([]);
  const [towns, setTowns] = useState([]);
  const [villages, setVillages] = useState([]);
  const [city, setCity] = useState("");
  const [town, setTown] = useState("Tất cả QH");
  const [village, setVillage] = useState("Tất cả PX");
  const [shops, setShops] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("bán chạy");
  const [isLoading, setIsLoading] = useState(true);
  const [showLocal, setShowLocal] = useState(-1);

  const setFilter = () => {
    const filter = { city: city ? city : "Hà Nội" };
    if (town !== "Tất cả QH") filter.town = town;
    if (village !== "Tất cả PX") filter.village = village;
    return filter;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function fetchProduct(filter, page, sort) {
    const res = await axios.get(
      `${api}/shop/getShopByFilter/${JSON.stringify(
        filter
      )}/${page}/${sort}`
    );
    if (res.data.message === "ok") {
      setShops(res.data.shops || []);
      setIsLoading(false);
      return;
    }
  }

  const firstLoad = async () => {
    let city = Cookies.get("city") || "Hà Nội";
    let town = Cookies.get("town") || "Tất cả QH";
    let village = Cookies.get("village") || "Tất cả PX";
    let citys = [];
    let towns = ["Tất cả QH"];
    let villages = [];
    for (let i = 0; i < mycitys.length; i++) {
      citys.push(mycitys[i]["city"]);
      if (mycitys[i]["city"] === city) {
        for (var j = 0; j < mycitys[i]["towns"].length; j++) {
          towns.push(mycitys[i]["towns"][j]["town"]);
          if (mycitys[i]["towns"][j]["town"] === town) {
            for (let k = 0; k < mycitys[i]["towns"][j]["village"].length; k++) {
              villages.push(mycitys[i]["towns"][j]["village"][k]);
            }
          }
        }
      }
    }
    setCity(city);
    setTown(town);
    setVillage(village);
    setCitys(citys);
    setTowns(towns);
    setVillages(villages);
    const filter = { city: city ? city : "Hà Nội" };
    if (town !== "Tất cả QH") filter.town = town;
    if (village !== "Tất cả PX") filter.village = village;
    fetchProduct(filter, page, sort);
  };

  useEffect(() => {
    if ((user.isLoggedIn === false || user.isLoggedIn === true) && cityLoading === true) firstLoad();
  }, [user, cityLoading]);

  if (isLoading) {
    return (
      <div className="product-loading">
        <div className="product-loading-icon">
          <FiLoader size={40} />
        </div>
      </div>
    );
  }

  return (
    <div className="Products-Box">
      <div className="Product-Btn">
        <Search className={"Product-Search"} classIcon={"Product-Search-Icon"} />
        <div
          style={{
            backgroundColor: sort === "gần nhất" ? "#4B56D2" : "gray",
            color: "white",
            border: "none",
          }}
          onClick={(e) => {
            if (sort === "gần nhất") return;
            const filter = setFilter();
            setIsLoading(true);
            fetchProduct(filter, page, "gần nhất");
            setSort("gần nhất");
          }}
          className="product-btn-item"
        >
          gần nhất
        </div>
        <div
          style={{
            backgroundColor: sort === "bán chạy" ? "#4B56D2" : "gray",
            color: "white",
            border: "none",
          }}
          onClick={(e) => {
            if (sort === "bán chạy") return;
            const filter = setFilter();
            setIsLoading(true);
            fetchProduct(filter, page, "bán chạy");
            setSort("bán chạy");
          }}
          className="product-btn-item"
        >
          bán chạy
        </div>
        <div
          style={{
            backgroundColor: sort === "giá tăng dần" ? "#4B56D2" : "gray",
            color: "white",
            border: "none",
          }}
          onClick={(e) => {
            if (sort === "giá tăng dần") return;
            const filter = setFilter();
            setIsLoading(true);
            fetchProduct(filter, page, "giá tăng dần");
            setSort("giá tăng dần");
          }}
          className="product-btn-item"
        >
          giá tăng dần
        </div>

        <div
          onClick={(e) => setShowLocal(showLocal === 1 ? -1 : 1)}
          className="product-btn-local"
        >
          <span className="product-local-text">{city}</span>
          {showLocal !== 1 && (
            <span style={{ transform: "translateY(1px)" }}>
              <BsFillArrowDownCircleFill size={15} />
            </span>
          )}
          {showLocal === 1 && (
            <span style={{ transform: "translateY(1px)" }}>
              <AiFillCloseCircle size={15} />
            </span>
          )}
          {showLocal === 1 && (
            <div className="product-dropdown-local">
              {citys.map((item, index) => {
                if (item === city) return;
                return (
                  <span
                    className="product-dropdown-local-item"
                    onClick={(e) => {
                      const filter = { city: item };
                      setCity(item);
                      setTown("Tất cả QH");
                      setVillage("Tất cả PX");
                      Cookies.set("city", item);
                      Cookies.set("town", "Tất cả QH");
                      Cookies.set("village", "Tất cả PX");
                      let newtowns = ["Tất cả QH"];
                      for (let i = 0; i < mycitys.length; i++) {
                        if (mycitys[i]["city"] === item) {
                          for (let j = 0; j < mycitys[i]["towns"].length; j++) {
                            newtowns.push(mycitys[i]["towns"][j]["town"]);
                          }
                        }
                      }
                      setTowns(newtowns);
                      setVillages([]);
                      setIsLoading(true);
                      fetchProduct(filter, page, sort);
                    }}
                    key={index}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div
          onClick={(e) => setShowLocal(showLocal === 2 ? -1 : 2)}
          className="product-btn-local"
        >
          <span className="product-local-text">{town}</span>
          {showLocal !== 2 && (
            <span style={{ transform: "translateY(1px)" }}>
              <BsFillArrowDownCircleFill size={15} />
            </span>
          )}
          {showLocal === 2 && (
            <span style={{ transform: "translateY(1px)" }}>
              <AiFillCloseCircle size={15} />
            </span>
          )}
          {showLocal === 2 && (
            <div className="product-dropdown-local">
              {towns.map((item, index) => {
                if (item === town) return;
                if (item === city) return;
                return (
                  <span
                    className="product-dropdown-local-item"
                    onClick={(e) => {
                      const filter = { city: city };
                      const newvillages = ["Tất cả PX"];
                      Cookies.set("town", item);
                      Cookies.set("village", "Tất cả PX");
                      if (item !== "Tất cả QH") filter.town = item;
                      if (item === "Tất cả QH") setVillages([]);
                      else {
                        for (let i = 0; i < mycitys.length; i++) {
                          if (mycitys[i]["city"] === city) {
                            for (
                              let j = 0;
                              j < mycitys[i]["towns"].length;
                              j++
                            ) {
                              if (mycitys[i]["towns"][j]["town"] === item) {
                                for (
                                  let k = 0;
                                  k < mycitys[i]["towns"][j]["village"].length;
                                  k++
                                ) {
                                  newvillages.push(
                                    mycitys[i]["towns"][j]["village"][k]
                                  );
                                }
                              }
                            }
                          }
                        }
                        setVillages(newvillages);
                      }
                      setVillage("Tất cả PX");
                      setTown(item);
                      setIsLoading(true);
                      fetchProduct(filter, page, sort);
                    }}
                    key={index}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          )}
        </div>
        <div
          onClick={(e) => setShowLocal(showLocal === 3 ? -1 : 3)}
          className="product-btn-local"
        >
          <span className="product-local-text">{village}</span>
          {showLocal !== 3 && (
            <span style={{ transform: "translateY(1px)" }}>
              <BsFillArrowDownCircleFill size={15} />
            </span>
          )}
          {showLocal === 3 && (
            <span style={{ transform: "translateY(1px)" }}>
              <AiFillCloseCircle size={15} />
            </span>
          )}
          {showLocal === 3 && (
            <div className="product-dropdown-local">
              {villages.map((item, index) => {
                if (item === village) return;
                if (item === city) return;
                return (
                  <span
                    className="product-dropdown-local-item"
                    onClick={(e) => {
                      Cookies.set("village", item);
                      const filter = { city: city, town: town };
                      if (item !== "Tất cả PX") filter.village = item;
                      setVillage(item);
                      setIsLoading(true);
                      fetchProduct(filter, page, sort);
                    }}
                    key={index}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="Product-Items">
        {shops.map((item, index) => {
          return (
            <div key={index} className="product-item">
              <Card shop={item} />
            </div>
          );
        })}
      </div>
      {shops.length === 0 && isLoading == false && (
        <h2 style={{ marginTop: "20px", color: "red", textAlign: "center" }}>
          không tìm thấy quán ăn nào
        </h2>
      )}
      <div className="product-pages-btn">
        <div
          onClick={(e) => {
            e.preventDefault();
            if (page < 2) return;
            setPage((prev) => prev - 1);
            const filter = setFilter();
            setIsLoading(true);
            fetchProduct(filter, page - 1, sort);
          }}
          className="product-arrow-page"
        >
          <AiOutlineArrowLeft size={40} />
        </div>
        {page < 4 &&
          [1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (page === item) return;
                  if (shops.length === 0 && item >= page) return;
                  setPage(item);
                  const filter = setFilter();
                  setIsLoading(true);
                  fetchProduct(filter, item, sort);
                }}
                style={{
                  backgroundColor: page === item ? "orangered" : "gray",
                  color: "white",
                }}
                key={index}
                className="product-pages-item"
              >
                {item}
              </div>
            );
          })}
        {page >= 4 &&
          [page - 2, page - 1, page, page + 1, page + 2].map((item, index) => {
            return (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  if (page === item) return;
                  if (shops.length === 0 && item >= page) return;
                  setPage(item);
                  const filter = setFilter();
                  setIsLoading(true);
                  fetchProduct(filter, item, sort);
                }}
                style={{
                  backgroundColor: page === item ? "orangered" : "gray",
                  color: "white",
                }}
                key={index}
                className="product-pages-item"
              >
                {item}
              </div>
            );
          })}
        <div
          onClick={(e) => {
            e.preventDefault();
            if (shops.length === 0) return;
            setPage((prev) => prev + 1);
            const filter = setFilter();
            setIsLoading(true);
            fetchProduct(filter, page + 1, sort);
          }}
          className="product-arrow-page"
        >
          <AiOutlineArrowRight size={40} />
        </div>
      </div>
    </div>
  );
}

export default Products;
