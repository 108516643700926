import React, { useState, useEffect } from "react";
import "../css/Products.css";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { api } from "../util/api";
import axios from "axios";
import Card from "../component/Card";
import { FiLoader } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";
import { useParams, Link } from "react-router-dom";
import { userActions } from "../redux/user";
import stringFix from "../util/stringFix";
import Search from "./Search";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

function Products() {
  const user = useSelector((state) => state.user);
  const mycitys = useSelector(state => state.data.citys);
  const params = useParams();
  const dispatch = useDispatch();
  const [citys, setCitys] = useState([]);
  const [towns, setTowns] = useState([]);
  const [villages, setVillages] = useState([]);
  const [city, setCity] = useState("");
  const [town, setTown] = useState("Tất cả QH");
  const [village, setVillage] = useState("Tất cả PX");
  const [shops, setShops] = useState([]);
  const [page, setPage] = useState(parseInt(params.page));
  const [sort, setSort] = useState(params.sort);
  const [isLoading, setIsLoading] = useState(false);
  const [showLocal, setShowLocal] = useState(-1);

  const setFilter = () => {
    const filter = { city: city ? city : "Hà Nội" };
    if (town !== "Tất cả QH") filter.town = town;
    if (village !== "Tất cả PX") filter.village = village;
    return filter;
  };
  
  async function fetchProduct(filter, page, sort) {
    setIsLoading(true);
    const res = await axios.get(
      `${api}/shop/getShopBySearch/${JSON.stringify(filter)}/${page}/${sort}/${params.keyword}`
    );
    if (res.data.message === "ok") {
      setShops(res.data.shops || []);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  }

  const firstLoad = async () => {
    let city = params.city || "Hà Nội";
    let town = params.town || "Tất cả QH";
    let village = params.village || "Tất cả PX";
    let citys = [];
    let towns = ["Tất cả QH"];
    let villages = [];

    for (let i = 0; i < mycitys.length; i++) {
      citys.push(mycitys[i]["city"]);
      if (mycitys[i]["city"] === city) {
        for (var j = 0; j < mycitys[i]["towns"].length; j++) {
          towns.push(mycitys[i]["towns"][j]["town"]);
          if (mycitys[i]["towns"][j]["town"] === town) {
            for (let k = 0; k < mycitys[i]["towns"][j]["village"].length; k++) {
              villages.push(mycitys[i]["towns"][j]["village"][k]);
            }
          }
        }
      }
    }
    setCity(city);
    setTown(town);
    setVillage(village);
    setCitys(citys);
    setTowns(towns);
    setVillages(villages);
    const filter = { city: city ? city : "Hà Nội" };
    if (town !== "Tất cả QH") filter.town = town;
    if (village !== "Tất cả PX") filter.village = village; 
    if(params.keyword !== "^no keyword^") {
      let arr = params.keyword.split(" ");
      if(arr.length <= 2) filter.nameSearch = {$regex: params.keyword};
      if(arr.length === 3) filter.$and = [{nameSearch: {$regex: `${arr[0]} ${arr[1]}`}}, {nameSearch: {$regex: arr[2]}}]
      if(arr.length > 3) filter.$and = [{nameSearch: {$regex: `${arr[0]} ${arr[1]}`}}, {nameSearch: {$regex: `${arr[2]} ${arr[3]}`}}];
    }
    fetchProduct(filter, parseInt(params.page), params.sort);
  };

  useEffect(() => {
    firstLoad();
    if(document.getElementById("shops")) {
      document.getElementById("shops").scrollIntoView();
    }
  }, [params]);


  if (isLoading) {
    return (
      <div className="product-loading">
        <div className="product-loading-icon">
          <FiLoader size={40} />
        </div>
      </div>
    );
  }
  return (
    <div className="Products-Box">
      <div className="Product-Btn">
        <Search className="Product-Search" classIcon={"Product-Search-Icon"} />
        <Link
          to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/gần nhất/1`}
          style={{
            backgroundColor: params.sort === "gần nhất" ? "#247881" : "gray",
            color: "white",
            border: "none",
            textDecoration: "none"
          }}
          className="product-btn-item"
        >
          gần nhất
        </Link>
        <Link
          to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/bán chạy/1`}
          style={{
            backgroundColor: params.sort === "bán chạy" ? "#247881" : "gray",
            color: "white",
            border: "none",
            textDecoration: "none"
          }}
          id="shops" 
          className="product-btn-item"
        >
          bán chạy
        </Link>
        <Link
          style={{
            backgroundColor: params.sort === "giá tăng dần" ? "#247881" : "gray",
            color: "white",
            border: "none",
            textDecoration: "none"
          }}
          to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/giá tăng dần/1`}

          className="product-btn-item"
        >
          giá tăng dần
        </Link>

        <div
          onClick={(e) => setShowLocal(showLocal === 1 ? -1 : 1)}
          className="product-btn-local"
        >
          <span className="product-local-text">{city}</span>
          {showLocal !== 1 && (
            <span style={{ transform: "translateY(1px)" }}>
              <BsFillArrowDownCircleFill size={15} />
            </span>
          )}
          {showLocal === 1 && (
            <span style={{ transform: "translateY(1px)" }}>
              <AiFillCloseCircle size={15} />
            </span>
          )}
          {showLocal === 1 && (
            <div className="product-dropdown-local">
              {citys.map((item, index) => {
                if (item === params.city) return;
                return (
                  <Link
                    to={`/searchProduct/${item}/Tất cả QH/Tất cả PX/${stringFix(params.keyword)}/${params.sort}/1`}
                    className="product-dropdown-local-item"
                    onClick={(e) => {
                      Cookies.set("city", item);
                      Cookies.set("town", "Tất cả QH");
                      Cookies.set("village", "Tất cả PX"); 
                    }}
                    key={index}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
        <div
          onClick={(e) => setShowLocal(showLocal === 2 ? -1 : 2)}
          className="product-btn-local"
        >
          <span className="product-local-text">{town}</span>
          {showLocal !== 2 && (
            <span style={{ transform: "translateY(1px)" }}>
              <BsFillArrowDownCircleFill size={15} />
            </span>
          )}
          {showLocal === 2 && (
            <span style={{ transform: "translateY(1px)" }}>
              <AiFillCloseCircle size={15} />
            </span>
          )}
          {showLocal === 2 && (
            <div className="product-dropdown-local">
              {towns.map((item, index) => {
                if (item === params.town) return;
                return (
                  <Link
                    to={`/searchProduct/${params.city || "Hà Nội"}/${item}/Tất cả PX/${stringFix(params.keyword)}/${params.sort}/1`}
                    className="product-dropdown-local-item"
                    onClick={(e) => {
                      Cookies.set("town", item);
                      Cookies.set("village", "Tất cả PX");              
                    }}
                    key={index}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
        <div
          onClick={(e) => setShowLocal(showLocal === 3 ? -1 : 3)}
          className="product-btn-local"
        >
          <span className="product-local-text">{village}</span>
          {showLocal !== 3 && (
            <span style={{ transform: "translateY(1px)" }}>
              <BsFillArrowDownCircleFill size={15} />
            </span>
          )}
          {showLocal === 3 && (
            <span style={{ transform: "translateY(1px)" }}>
              <AiFillCloseCircle size={15} />
            </span>
          )}
          {showLocal === 3 && (
            <div className="product-dropdown-local">
              {villages.map((item, index) => {
                if (item === params.village) return;
                return (
                  <Link
                    to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${item}/${stringFix(params.keyword)}/${params.sort}/1`}
                    className="product-dropdown-local-item"
                    onClick={(e) => {
                      Cookies.set("village", item);
                    }}
                    key={index}
                  >
                    {item}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="Product-Items">
        {shops.map((item, index) => {
          return (
            <div key={index} className="product-item">
              <Card shop={item} />
            </div>
          );
        })}
        <div style={{ flex: 1 }}></div>
      </div>
      {shops.length === 0 && (
        <h2 style={{ marginTop: "20px", color: "red" }}>
          không tìm thấy quán ăn nào
        </h2>
      )}
      <div className="product-pages-btn">
        <Link
          style={{pointerEvents: parseInt(params.page) > 1 ? "all" : "none"}}
          to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/${params.sort}/${parseInt(params.page) - 1}`}
          className="product-arrow-page"
        >
          <AiOutlineArrowLeft size={40} />
        </Link>
        {parseInt(params.page) < 4 &&
          [1, 2, 3, 4, 5].map((item, index) => {
            return (
              <Link
              to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/${params.sort}/${item}`}
              style={{
                  pointerEvents: item > parseInt(params.page) && shops.length === 0 ? "none" : "all",
                  backgroundColor: parseInt(params.page) === item ? "orangered" : "gray",
                  color: "white",
                }}
                key={index}
                className="product-pages-item"
              >
                {item}
              </Link>
            );
          })}
        {parseInt(params.page) >= 4 &&
          [parseInt(params.page) - 2, parseInt(params.page) - 1, parseInt(params.page), parseInt(params.page) + 1, parseInt(params.page) + 2].map((item, index) => {
            return (
              <Link
                to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/${params.sort}/${item}`}
                style={{
                  backgroundColor: parseInt(params.page) === item ? "orangered" : "gray",
                  color: "white",
                }}
                key={index}
                className="product-pages-item"
              >
                {item}
              </Link>
            );
          })}
        <Link
          to={`/searchProduct/${params.city || "Hà Nội"}/${params.town || "Tất cả QH"}/${params.village || "Tất cả PX"}/${stringFix(params.keyword)}/${params.sort}/${parseInt(params.page) + 1}`}
          style={{pointerEvents: shops.length <= 0 ? "none" : "all"}}
          className="product-arrow-page"
        >
          <AiOutlineArrowRight size={40} />
        </Link>
      </div>
    </div>
  );
}

export default Products;
