import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "../css/Admin.css";
import Cookies from "js-cookie";
import axios from "axios";
import { api } from "../util/api";
import { BiLoader } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { version } from "../util/version"

function Admin() {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const [checkPass, setCheckPass] = useState(false);
  const [password, setPassword] = useState("");
  const [shopsRegister, setShopsRegister] = useState([]);
  const [category, setCategory] = useState(-1);
  const [detailShop, setDetailShop] = useState("");
  const [type, setType] = useState("");
  const [messageAction, setMessageAction] = useState({
    content: "",
    color: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState("");
  const [imgs, setImgs] = useState([]);
  const [pass2, setPass2] = useState("");

  useEffect(() => {
    if (user.roles.indexOf("admin") < 0) return history.push("/");
  }, []);

  async function clearOrder() {
    const res = await axios.get(`${api}/admin/clear_order/${pass2}`);
    if(res.data.message === "ok") {
      return setMessageAction({
        content: res.data.message,
        color: "green",
      });
    }
  }

  async function passcheck(e) {
    e.preventDefault();
    if (!Cookies.get("token")) return useHistory.push("/");
    const token = await Cookies.get("token").replaceAll('"', "");
    const res = await axios.post(
      `${api}/admin/admincheck`,
      { email: user.email, password: password },
      { headers: { authorization: "Bearer " + token } }
    );
    if (res.data.message === "ok") setCheckPass(true);
  }

  if (checkPass === false) {
    return (
      <div className="admin-passcheck">
        <div className="admin-input-box">
          <label>password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          ></input>
          <button onClick={(e) => passcheck(e)}>Enter</button>
        </div>
      </div>
    );
  }

  async function clearRole(e, email) {
    e.preventDefault();
    const res = await axios.post(`${api}/admin/clearRole`, {
      email: detailShop.email,
      pass2: pass2
    });
    if (res.data.message === "ok") {
      return setMessageAction({
        content: "clear role thành công",
        color: "#F0FF42",
      });
    } else {
      return setMessageAction({
        content: res.data.message,
        color: "red",
      });
    }
  }

  async function findShopRegister(e) {
    e.preventDefault();
    const res = await axios.get(`${api}/admin/findShopRegister/${pass2}`);
    if (res.data.message === "ok") {
      setShopsRegister(res.data.shopsRegister);
      setCategory(1);
    }
  }

  const setrole = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.post(`${api}/admin/setrole/`, {
      email: detailShop.email,
      city: detailShop.city,
      pass2: pass2
    });
    if (res.data.message === "ok") {
      setIsLoading(false);
      return setMessageAction({
        content: "Push role thành công",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const checkrole = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.get(
      `${api}/admin/checkrole/${detailShop.email}/${detailShop.city}/${pass2}`
    );
    if (res.data.message === "ok") {
      setIsLoading(false);
      return setMessageAction({
        content: "người dùng đã được cấp quyền bán hàng",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const pushshop = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.post(`${api}/admin/pushshop`, {
      email: detailShop.email,
      shop: detailShop,
      pass2: pass2
    });
    if (res.data.message === "ok") {
      setIsLoading(false);
      return setMessageAction({
        content: "Push shop thành công",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const checkshop = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.get(
      `${api}/admin/checkshop/${detailShop.city}/${detailShop.email}/${detailShop.nameSearch}/${pass2}`
    );
    if (res.data.message === "ok") {
      setIsLoading(false);
      return setMessageAction({
        content: "đã tìm thấy shop này",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const removeshop = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.get(`${api}/admin/removeshop/${detailShop.city}/${detailShop.email}/${detailShop.nameSearch}/${pass2}`);
    if (res.data.message === "ok") {
      setIsLoading(false);
      const newshop = shopsRegister.filter(
        (shop) => shop.email !== detailShop.email
      );
      setShopsRegister(newshop);
      return setMessageAction({
        content: "Loại bỏ shop thành công",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const reportFailed = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.post(`${api}/user/reportfailed/:email`, {
      email: detailShop.email,
    });
    if (res.data.message === "ok") {
      setIsLoading(false);
      return setMessageAction({
        content: "Đăng ký không thành công, vui lòng thử lại",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const reportSuccess = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await axios.post(`${api}/user/reportsuccess/:email`, {
      email: detailShop.email,
    });
    if (res.data.message === "ok") {
      setIsLoading(false);
      return setMessageAction({
        content: "Đăng ký shop thành công",
        color: "#F0FF42",
      });
    } else {
      setIsLoading(false);
      return setMessageAction({ content: res.data.message, color: "red" });
    }
  };

  const deleteShopImage = async (e, data, target) => {
    e.preventDefault();
    try {
      data.pass = data.pass2;
      const res = await axios.post(`${api}/admin/delete_shop_image`, data);
      console.log(res.data.message);
      if (res.data.message === "ok") {
        setMessageAction({
          content: `xóa thành công ${target}`,
          color: "#F0FF42",
        });
      }
    } catch (error) {
      setMessageAction({ content: "xóa thành công", color: "red" });
      return;
    }
  };

  function encodeImageFileAsURL(element) {
    setImgs([]);
    var file = element[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      console.log("RESULT", reader.result.length);
      setImageUpload(reader.result);
    };
    reader.readAsDataURL(file);
  }

  async function uploadImage(e) {
    if(imageUpload === "") return;
    const data = {
      type: type,
      data: imageUpload,
      createdAt: Date.now().toString(),
      pass2: pass2
    }
    const res = await axios.post(`${api}/admin/imageUpload`, data);
    if(res.data.message === "ok") {
      alert("upload image success");
    }
  }

  async function findImage(e) {
    try {
      e.preventDefault();
      const res = await axios.get(`${api}/admin/findImage/${type}/${version}`);
      if(res.data.message === 'ok') {
        setImageUpload("");
        setImgs(res.data.imgs || []);
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  }

  async function deleteImage(e, type, createdAt) {
    try {
      e.preventDefault();
      const data = {
        type: type,
        createdAt: createdAt.toString(),
        pass2: pass2
      };
      const res = await axios.post(`${api}/admin/deleteImage`, data);
      if(res.data.message === 'ok') {
        setImageUpload("");
        let newimgs = imgs.filter((item) => item.createdAt != createdAt);
        setImgs(newimgs);
        alert("delete sucsess");
      }
    } catch (error) {
      console.log(error.message);
      return;
    }
  }

  return (
    <div className="admin-box">
      <div style={{ display: "flex" }}>
        <div className="admin-btn-box">
          <input value={pass2} type="text" placeholder="pass 2" onChange={(e) => setPass2(e.target.value)}></input>
        </div>
        <div className="admin-btn-box">
          <button onClick={(e) => findShopRegister(e)}>Shop Đăng Ký</button>
        </div>
        <div className="admin-btn-box">
          <button onClick={(e) => clearOrder(e)}>Xóa Order</button>
        </div>
        <div className="admin-btn-box">
          <button onClick={(e) => setCategory(2)}>Upload Banner</button>
        </div>
      </div>
      <hr />
      <span style={{ textAlign: "left" }}>Danh sách trả về</span>
      <div className="admin-list-box">
        {category === 1 &&
          shopsRegister.map((item, index) => {
            return (
              <div
                onClick={(e) => {
                  setDetailShop(item);
                }}
                className="admin-list-item"
                key={index}
              >
                <div>
                  <img width="200px" height="200px" src={item.avShop.url}></img>
                </div>
                <p className="admin-shop-title">{item.nameshop}</p>
                <hr style={{ margin: 0 }} />
                <div className="admin-shop-ps">
                  <span>
                    <span style={{ color: "gray" }}>price: </span>
                    {item.minPrice}
                  </span>
                  <span>
                    <span style={{ color: "gray" }}>sale: </span>
                    {item.sale}
                  </span>
                </div>
                <hr style={{ margin: 0 }} />
                <div className="admin-shop-ps">
                  <span style={{ color: "gray" }}>{item.city}</span>
                  <span style={{ color: "gray" }}>{item.town}</span>
                </div>
              </div>
            );
          })}
        {category === 2 && (
          <div className="btn-banner-box">
            <div className="admin-btn-box-2">
              <div className="admin-btn-upload">
                <input
                  onChange={(e) =>{ 
                    let target = e.target || e.srcElement;
                    if (target.value.length === 0) return;
                    encodeImageFileAsURL(e.target.files)
                  }}
                  type="file"
                ></input>
                <div>Select Banner</div>
              </div>
              
              <div className="admin-btn-box">
                <button >{imageUpload.length} Byte</button>
              </div>
              <div className="admin-btn-box">
                <button onClick={(e) => findImage(e)}>Find Banner</button>
              </div>
              <div className="admin-btn-box">
                <input value={type} onChange={(e) => setType(e.target.value)} type="text" />
              </div>
              <div className="admin-btn-box">
                <button onClick={(e) => uploadImage(e)}>Upload Image</button>
              </div>
            </div>
            <img src={imageUpload} />
            {imgs.length > 0 && imgs.map((item, i) => {
              return <div key={i}>
                <img src={item.data} />
                <div>
                  <button onClick={(e) => deleteImage(e, "banner", imgs[i].createdAt)}>DELETE IMAGE</button>
                </div>
              </div>
            })}
          </div>
        )}
      </div>
      {detailShop !== "" && (
        <div className="admin-detail-shop">
          <div className="admin-detail-input">
            <label>Tên shop</label>
            <input value={detailShop.nameshop} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>phone</label>
            <input value={detailShop.phone} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>username</label>
            <input value={detailShop.username} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>email</label>
            <input value={detailShop.email} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>tỉnh - thành</label>
            <input value={detailShop.city} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>quận - huyện</label>
            <input value={detailShop.town} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>phường - xã</label>
            <input value={detailShop.village} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>địa chỉ - đường phố</label>
            <input value={detailShop.address} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>từ khóa tìm kiếm</label>
            <input value={detailShop.keyword} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>ưu đãi</label>
            <input value={detailShop.free} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>thông báo</label>
            <input value={detailShop.notification} readOnly={true}></input>
          </div>
          <div className="admin-detail-input">
            <label>mô tả quán</label>
            <textarea
              rows={5}
              value={detailShop.description}
              readOnly={true}
            ></textarea>
          </div>
          <div className="admin-detail-input">
            <label>thẻ căn cước</label>
            <input rows={5} value={detailShop.cc} readOnly={true}></input>
          </div>
          <div className="admin-detail-ims-box">
            <div className="admin-detail-item-imgs">
              <label>ảnh đại diện shop</label>
              <img
                width="200px"
                height="200px"
                src={detailShop.avShop.url}
              ></img>
              <div
                onClick={(e) =>
                  deleteShopImage(e, detailShop.avShop, "ảnh đại diện")
                }
                className="admin-delete-icon"
              >
                <BsTrash size={25} />
              </div>
            </div>
            <div className="admin-detail-item-imgs">
              <label>ảnh căn cước mặt trước</label>
              <img width="200px" height="200px" src={detailShop.ccMt.url}></img>
              <div
                onClick={(e) =>
                  deleteShopImage(e, detailShop.ccMt, "ảnh cc mặt trước")
                }
                className="admin-delete-icon"
              >
                <BsTrash size={25} />
              </div>
            </div>
            <div className="admin-detail-item-imgs">
              <label>ảnh căn cước mặt sau</label>
              <img width="200px" height="200px" src={detailShop.ccMs.url}></img>
              <div
                onClick={(e) =>
                  deleteShopImage(e, detailShop.ccMs, "ảnh cc mặt sau")
                }
                className="admin-delete-icon"
              >
                <BsTrash size={25} />
              </div>
            </div>
          </div>
          <div style={{ height: "20px" }}></div>
          <div style={{ color: messageAction.color, marginBottom: "10px" }}>
            {messageAction.content}
          </div>
          <div className="admin-button-shop-action">
            <button onClick={(e) => clearRole(e, detailShop.email)}>
              Clear Role
            </button>
            <button onClick={(e) => setrole(e)}>Push Role</button>
            <button onClick={(e) => checkrole(e)}>Check Role</button>
            <button onClick={(e) => pushshop(e)}>Push Shop</button>
            <button onClick={(e) => checkshop(e)}>Check Shop</button>
            <button onClick={(e) => removeshop(e)}>Remove Register</button>
            <button onClick={(e) => reportFailed(e)}>báo lỗi</button>
            <button onClick={(e) => reportSuccess(e)}>báo thành công</button>
            <button onClick={(e) => setDetailShop("")}>đóng cửa sổ</button>
            {isLoading && (
              <div className="admin-button-loading">
                <div className="admin-loading">
                  <BiLoader size="35" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;
