import React, { useState, useEffect } from 'react'
import { BsSearch } from "react-icons/bs";
import stringFix from '../util/stringFix';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { api } from "../util/api";
import axios from "axios";
import { RiCloseCircleLine } from "react-icons/ri";

import '../css/Search.css';
function Search(props) {
    const [keyword, setKeyword] = useState('');
    const [finalKeyWord, setFinalKeyword] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [keywords, setKeyWords] = useState([]);

    useEffect(() => {
        const timerID = setTimeout(() => {
            setFinalKeyword(keyword);
        }, 500);
        return () => {
            clearTimeout(timerID);
        }
    }, [keyword]);

    const fetchKeyWords = async () => {
        if(finalKeyWord.length === 0) return;
        const res = await axios.get(`${api}/shop/getKeyWord/${stringFix(finalKeyWord)}`);
        if(res.data.message === "ok") setKeyWords(res.data.keywords || []);
    }

    useEffect(() => {
        fetchKeyWords();
    }, [finalKeyWord]);
    


  return (
    <div className={`${props.className}`}>
        <input onFocus={(e) => setShowSearch(true)} maxLength="50" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="phở, bánh mì, cơm rang, pizza..." />
        {keyword.length <= 0 ? <BsSearch className={`${props.classIcon}`} /> : <RiCloseCircleLine onClick={(e) => setKeyword("")} className={`${props.classIcon}`} color="blue"/> }
        <div style={{transform: keyword.length > 0 && showSearch ? 'scaleY(1)' : 'scaleY(0)'}} className="search-list-box"> 
            <Link to={`/searchProduct/${Cookies.get("city") || "Hà Nội"}/${Cookies.get("town") || "Tất cả QH"}/${Cookies.get("village") || "Tất cả PX"}/${stringFix(keyword || "")}/bán chạy/1`} onClick={(e) => setShowSearch(false)}className="search-list-item">{keyword}</Link>
            <hr/>
            {keywords.map((item, index) => {
                return <div onClick={(e) => {
                    e.preventDefault(); 
                    setKeyword(item.text);
                }} key={index}>
                    <Link to={`/searchProduct/${Cookies.get("city") || "Hà Nội"}/${Cookies.get("town") || "Tất cả QH"}/${Cookies.get("village") || "Tất cả PX"}/${stringFix(item.text ? item.text : "")}/bán chạy/1`} onClick={(e) => setShowSearch(false)} className="search-list-item">{item.text ? item.text : ""}
                    </Link>
                    <hr/>
                </div>
            })}
        </div>
    </div>
  )
}

export default Search;