import React from 'react';
import { FiLoader } from "react-icons/fi";
import "../css/Loading.css";

function Loading() {
  return (
    <div className="loading">
        <FiLoader size={100} />
    </div>
  )
}

export default Loading;