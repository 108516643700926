function stringFix(s) {
    let arr = [
      [
        "a",
        "à",
        "á",
        "ạ",
        "ả",
        "ã",
        "â",
        "ầ",
        "ấ",
        "ậ",
        "ẩ",
        "ẫ",
        "ă",
        "ằ",
        "ắ",
        "ặ",
        "ẳ",
        "ẵ",
      ],
      ["e", "è", "é", "ẹ", "ẻ", "ẽ", "ê", "ề", "ế", "ệ", "ể", "ễ"],
      ["i", "ì", "í", "ị", "ỉ", "ĩ"],
      [
        "o",
        "ò",
        "ó",
        "ọ",
        "ỏ",
        "õ",
        "ô",
        "ồ",
        "ố",
        "ộ",
        "ổ",
        "ỗ",
        "ơ",
        "ờ",
        "ớ",
        "ợ",
        "ở",
        "ỡ",
      ],
      ["u", "ù", "ú", "ụ", "ủ", "ũ", "ư", "ừ", "ứ", "ự", "ử", "ữ"],
      ["y", "ỳ", "ý", "ỵ", "ỷ", "ỹ"],
      ["d", "đ"],
    ];
  
    let t = '';
    s = s.toLowerCase();
    for (var i = 0; i < s.length; i++) {
      let currentChar = s[i];
      for(var j = 0; j < arr.length; j++) {
          if(arr[j].includes(s[i])) {
              currentChar = arr[j][0];
          }
      }
      t+= currentChar
    }
    return t;
  }

export default stringFix;