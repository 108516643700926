import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        quantity: 0,
        items: [],
        total: 0,
    },
    reducers: {
        getCart(state) {
            if(!localStorage.getItem("cart")) return;
            const cart = JSON.parse(localStorage.getItem("cart"));
            state.items = cart.items;
            state.quantity = cart.quantity;
            state.total = cart.total;
        },
        addItem(state, action) {
            state.items = [...state.items, action.payload];
            state.quantity += 1;
            localStorage.setItem('cart', JSON.stringify({items: state.items, quantity: state.quantity, total: state.total}));
        },
        replaceItem(state, action) {
            const indexUpdate = state.items.findIndex((item) => item.uuid === action.payload.uuid);
            state.items[indexUpdate] = action.payload;
            localStorage.setItem('cart', JSON.stringify({items: state.items, quantity: state.quantity, total: state.total}));
        },
        deleteItem(state, action) {
            let newItems = state.items.filter((item) => item.uuid !== action.payload);
            state.items = newItems;
            localStorage.setItem('cart', JSON.stringify({items: state.items, quantity: state.quantity, total: state.total}));
        },
        deleteItemsByEmail(state, action) {
            let newItems = state.items.filter((item) => item.email_nguoi_ban !== action.payload);
            state.items = newItems;
            localStorage.setItem('cart', JSON.stringify({items: state.items, quantity: state.quantity, total: state.total}));
        }
    }
})

export const cartActions = cartSlice.actions;
export default cartSlice;