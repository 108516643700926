import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';


const userSlice = createSlice({
    name: "user",
    initialState: {
        city: '',
        town: '',
        village: '',
        uuid: '',
        username: '',
        status: '',
        email: '',
        address: '',
        cc: '',
        ordersBuy: [],
        ordersSell: [],
        notification: [],
        favorites: [],
        reviews: [],
        roles: [],
        level: 1,
        phone: 123,
        cityShop: "",
        avID: "",
        avURL: "",
        isLoggedIn: "",
        type: "",
        nums: -1,
    },
    reducers: {
        login(state, action) {
            state.city = action.payload.city;
            state.town = action.payload.town;
            state.village = action.payload.village;
            state.username = action.payload.username;
            state.status = action.payload.status;
            state.email = action.payload.email;
            state.address = action.payload.address;
            state.cc = action.payload.cc;
            state.ordersBuy = action.payload.orderBuy;
            state.ordersSell = action.payload.orderSell;
            state.reviews = action.payload.reviews;
            state.notification = action.payload.notification.reverse();
            state.favorites = action.payload.favorite.reverse();
            state.level = action.payload.level;
            state.phone = action.payload.phone;
            state.avID = action.payload.avID;
            state.type= action.payload.type;
            state.avURL = action.payload.avURL;
            state.roles = action.payload.roles;
            state.cityShop = action.payload.cityShop;
            state.nums = action.payload.nums;
            state.isLoggedIn = true;
            Cookies.set("type", action.payload.type);
        }, 
        logout(state) {
            state.city = '';
            state.town = '';
            state.village = '';
            state.username = '';
            state.status = '';
            state.email = '';
            state.address = '';
            state.cc = '';
            state.ordersBuy = [];
            state.ordersSell = [];
            state.reviews = [];
            state.notification = [];
            state.favorites = [];
            state.level = '';
            state.roles = [];
            state.phone = 123;
            state.avID = '';
            state.avURL = '';
            state.cityShop = "";
            state.isLoggedIn = false;
            state.nums = "";
            state.type = "";
            FacebookLoginClient.logout(() => {
                console.log('logout completed!');
            });
            Cookies.remove('token');
            Cookies.remove('type');
        },
        updateRoles(state, action) {
            state.roles = action.payload.roles;
        },
        updateNotifications(state, action) {
            state.notification = action.payload;
        },
        updateFavorites(state, action) {
            state.favorites = action.payload;
        },
        updateReview(state, action) {
            state.reviews = action.payload;
        },
    }
});

export const userActions = userSlice.actions;
export default userSlice;
