import React, { useEffect } from "react";

import "../css/TextEffect.css";

function TextEffect(props) {
  return (
    <div className="km-text-box" style={{fontSize: `${props.fontsize}px`}}>
      <h2 className="km-text" data-text={props.text}>
       {props.text}
      </h2>
    </div>
  );
}

export default TextEffect;

