import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { AiFillStar } from "react-icons/ai";
import "../css/ShopDetail.css";
import { api } from "../util/api";
import axios from "axios";
import TextEffect from "../component/TextEffect";
import TimeAgo from "javascript-time-ago";
import vi from "javascript-time-ago/locale/vi";
import ReactStars from "react-rating-stars-component";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { userActions } from "../redux/user";
import { useDispatch } from "react-redux";
import avatar from "../imgs/avatar.jpg";
import Cookies from "js-cookie";

function ReviewSend(props) {
  const disPatch = useDispatch();
  const history = useHistory();
  const [rvPage, setRvPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(-1);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [rt, setRt] = useState(0);
  TimeAgo.addLocale(vi);
  const timeAgo = new TimeAgo("vi-VN");

  const [review, setReview] = useState({
    av: "",
    email: "",
    createdAt: Date.now(),
    star: -1,
    content: "",
    number: -1,
  });

  useEffect(() => {
    setRt(props.rt);
    if (props.rt > 0) setButtonLoading(true);
  }, []);

  async function sendRevew(e) {
    e.preventDefault();
    if (props.review.indexOf(props.shop_email) < 0) return alert("Mua hàng để đánh giá sản phẩm");
    if (review.star <= 0) return alert("Chưa cho điểm đánh giá");
    if (review.content.trim() === "") return alert("Chưa có nội dung đánh giá");
    if (props.user_email === "") return history.push("/login");
    const data = {
      av: props.av || "",
      username: props.username,
      email: props.user_email,
      shop_email: props.shop_email,
      star: review.star,
      content: review.content,
      shop_star: (props.rp + review.star) / (props.rp + 1),
      createdAt: review.createdAt,
      number: props.rt + 1,
      nameshop: props.nameshop,
      nums: props.nums,
    };
    setIsLoading(3);
    const newReviews = props.review.filter((item) => item !== props.shop_email);
    const res = await axios.post(`${api}/user/remove_review`, {email: props.user_email, reviews: newReviews,});
    if (res.data.message === "ok") {
      disPatch(userActions.updateReview(newReviews));
      await axios.post(`${api}/shop/push_review`, data);
      Cookies.set('refresh', Date.now() || Math.random());
      setRt((prev) => prev + 1);
      if (reviews.length <= 0) setButtonLoading(true);
      setReviews([
        {
          username: props.username,
          content: review.content,
          createdAt: review.createdAt,
          star: review.star,
          av: review.av,
        },
        ...reviews,
      ]);
      setReview({
        av: "",
        email: "",
        createdAt: Date.now(),
        star: -1,
        content: "",
        number: -1,
      });
      setIsLoading(-1);
    } else {
      alert(res.data.message);
      setIsLoading(-1);
    }
  }

  async function loadReviews(e, nums) {
    e.preventDefault();
    setIsLoading(2);
    const res = await axios.get(
      `${api}/shop/getReview/${nums}/${props.shop_email}/${props.nums}`
    );
    if (res.data.message === "ok") {
      setReviews(res.data.reviews || []);
      setButtonLoading(false);
      setIsLoading(-1);
    } else {
      alert(res.data.message);
      setButtonLoading(false);
      setIsLoading(-1);
    }
  }

  return (
    <div className="dt-review-box">
      {isLoading === 1 ? (
        <TextEffect fontsize={35} text={"Loading..."} />
      ) : (
        <div className="dt-review">
          <span className="dt-text-overflow dt-review-text">
            để lại đánh giá của bạn
          </span>
          <textarea
            type="text"
            maxLength="200"
            value={review.content}
            onChange={(e) => setReview({ ...review, content: e.target.value })}
            placeholder="Nội dung"
            rows="7"
            className=""
          />
          <span style={{ color: "#FF5858" }}>Nội dung không được để trống</span>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Xếp hạng:</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AiFillStar
                onMouseOver={(e) => setReview({ ...review, star: 1 })}
                style={{ cursor: "pointer" }}
                size="20"
                color={review.star >= 1 ? "#00ABB3" : "gray"}
              />
              <AiFillStar
                onMouseOver={(e) => setReview({ ...review, star: 2 })}
                style={{ cursor: "pointer" }}
                size="20"
                color={review.star >= 2 ? "#00ABB3" : "gray"}
              />
              <AiFillStar
                onMouseOver={(e) => setReview({ ...review, star: 3 })}
                style={{ cursor: "pointer" }}
                size="20"
                color={review.star >= 3 ? "#00ABB3" : "gray"}
              />
              <AiFillStar
                onMouseOver={(e) => setReview({ ...review, star: 4 })}
                style={{ cursor: "pointer" }}
                size="20"
                color={review.star >= 4 ? "#00ABB3" : "gray"}
              />
              <AiFillStar
                onMouseOver={(e) => setReview({ ...review, star: 5 })}
                style={{ cursor: "pointer" }}
                size="20"
                color={review.star >= 5 ? "#00ABB3" : "gray"}
              />
            </div>
          </div>
          {isLoading === 3 ? <TextEffect fontsize={35} text={"Loading..."} /> : <button onClick={(e) => sendRevew(e)} className="dt-gdg">
            Gửi đánh giá
          </button>}
        </div>
      )}
      {isLoading === 2 ? (
        <TextEffect fontsize={35} text={"Loading..."} />
      ) : (
        <div className="review-list">
          {buttonLoading && (
            <button onClick={(e) => loadReviews(e, 1)}>Load review</button>
          )}
          {rt === 0 && reviews.length === 0 && (
            <h1 style={{ color: "orangered" }}>Chưa có đánh giá nào</h1>
          )}
          {!buttonLoading &&
            reviews.length >= 1 &&
            reviews.map((item, index) => {
              if (index > 4) return;
              return (
                <div key={index} className="review-item">
                  <div className="review-av">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={item.av}
                      width="100px"
                      height="100px"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avatar;
                        return;
                      }}
                    />
                  </div>
                  <div className="review-text-box">
                    <div>
                      <ReactStars
                        key={`stars_${item.star}`}
                        isHalf={true}
                        count={5}
                        size={18}
                        value={item.star}
                        edit={false}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#B9005B"
                      />
                    </div>
                    <div className="review-content">{item.content}</div>
                    <div className="review-bottom dt-text-overflow">
                      <div className="dt-text-overflow">
                        <span style={{ color: "gray" }}>username: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {item.username}
                        </span>
                      </div>
                      <div className="dt-text-overflow">
                        <span style={{ color: "gray" }}>createdAt: </span>
                        <span style={{ fontWeight: "bold" }}>
                          {timeAgo.format(
                            parseInt(item.createdAt) || Date.now()
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div className="dt-rvpage-box">
        <div
          onClick={(e) => {
            if (rvPage <= 1) return;
            setRvPage(rvPage - 1);
            loadReviews(e, rvPage - 1);
          }}
          className="dt-arrow-page"
        >
          <AiOutlineArrowLeft size="45" />
        </div>
        {rvPage < 4 &&
          [1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div
                onClick={(e) => {
                  if (rvPage < item && reviews.length < 5) return;
                  setRvPage(item);
                  loadReviews(e, item);
                }}
                style={{
                  backgroundColor: rvPage === item ? "#7743DB" : "gray",
                }}
                key={index}
                className="dt-page-item"
              >
                {item}
              </div>
            );
          })}
        {rvPage >= 4 &&
          [rvPage - 2, rvPage - 1, rvPage, rvPage + 1, rvPage + 1].map(
            (item, index) => {
              return (
                <div
                  onClick={(e) => {
                    if (rvPage < item && reviews.length < 5) return;
                    setRvPage(item);
                    loadReviews(e, item);
                  }}
                  style={{
                    backgroundColor: rvPage === item ? "#7743DB" : "gray",
                  }}
                  key={index}
                  className="dt-page-item"
                >
                  {item}
                </div>
              );
            }
          )}
        <div
          onClick={(e) => {
            if (reviews.length < 5) return;
            setRvPage(rvPage + 1);
            loadReviews(e, rvPage + 1);
          }}
          className="dt-arrow-page"
        >
          <AiOutlineArrowRight size="45" />
        </div>
      </div>
    </div>
  );
}

export default ReviewSend;
