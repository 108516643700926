import React, { useState, useEffect } from "react";
import "../css/Login.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { api } from "../util/api";
import { useDispatch } from "react-redux";
import { userActions } from "../redux/user";
import Cookies from "js-cookie";
import Loading from "./Loading";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";

import { gapi } from "gapi-script";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsloading] = useState(false);
  const [checkbox, setCheckbox] = useState(-1);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState({
    content: "",
    color: "red",
  });

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "187010286153-86d1hfpvl2ri6ra013mg5g31kir7pkj3.apps.googleusercontent.com",
        scope: "email",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        setIsloading(true);
        const resg = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        const res = await axios.get(`${api}/user/login_google/${resg.data.email}`);
        if (res.data.message === "ok") {
          dispatch(
            userActions.login({
              username: res.data.user.username,
              email: res.data.user.email,
              phone: res.data.user.phone,
              address: res.data.user.address,
              avID: res.data.user.avID,
              avURL: res.data.user.avURL,
              cc: res.data.user.cc,
              status: res.data.user.status,
              level: res.data.user.level,
              city: res.data.user.city,
              town: res.data.user.town,
              village: res.data.user.village,
              type: res.data.user.type,
              notification: res.data.user.notification,
              favorite: res.data.user.favorite,
              message: res.data.user.message,
              orderBuy: res.data.user.orderBuy,
              orderSell: res.data.user.orderSell,
              reviews: res.data.user.review,
              code: res.data.user.code,
              roles: res.data.user.role,
              cityShop: res.data.user.cityShop,
              nums: res.data.user.nums,
            })
          );
          Cookies.set("token", JSON.stringify(res.data.token));
          history.push("/");
          //Cookies.set("rfToken", JSON.stringify(res.data.rfToken));
          
          setIsloading(false);
        } else {
          setMessage({ content: res.data.message, color: "red" });
          setIsloading(false);
        }
        setIsloading(false);
      } catch (err) {
        setIsloading(false);
        console.log(err);
      }
    },
  });
  
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsloading(true);
    if (
      user.email === "" ||
      !user.email.includes("@") ||
      !user.email.includes(".com")
    ) {
      setMessage({ cotent: "Email không hợp lệ", color: "red" });
      setIsloading(false);
      return;
    }
    if (user.password === "") {
      setMessage({ content: "Password không hợp lệ", color: "red" });
      setIsloading(false);
      return;
    }

    const res = await axios.get(
      `${api}/user/login_email/${user.email}/${user.password}`
    );

    if (res.data.message === "ok") {
      dispatch(
        userActions.login({
          username: res.data.user.username,
          email: res.data.user.email,
          phone: res.data.user.phone,
          address: res.data.user.address,
          avID: res.data.user.avID,
          avURL: res.data.user.avURL,
          cc: res.data.user.cc,
          status: res.data.user.status,
          level: res.data.user.level,
          city: res.data.user.city,
          town: res.data.user.town,
          village: res.data.user.village,
          type: res.data.user.type,
          notification: res.data.user.notification,
          favorite: res.data.user.favorite,
          message: res.data.user.message,
          orderBuy: res.data.user.orderBuy,
          orderSell: res.data.user.orderSell,
          reviews: res.data.user.review,
          code: res.data.user.code,
          roles: res.data.user.role,
          cityShop: res.data.user.cityShop,
          nums: res.data.user.nums,
        })
      );
      Cookies.set("token", JSON.stringify(res.data.token));
      history.push("/");
      setIsloading(false);
    } else {
      setMessage({ content: res.data.message, color: "red" });
      setIsloading(false);
    }
    setIsloading(false);
  };

  if (isLoading) {
    return (
      <div style={{ backgroundColor: "white", width: "100%", height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  const responseGoogle = async (response) => {
    let data = await response.profileObj;
    if (!data) return setIsloading(false);

  };

  const responseFacebook = async (response) => {
    let data = await response;
    if (!data) return setIsloading(false);
    const res = await axios.get(`${api}/user/login_google/${data.email}`);
    if (res.data.message === "ok") {
      dispatch(
        userActions.login({
          username: res.data.user.username,
          email: res.data.user.email,
          phone: res.data.user.phone,
          address: res.data.user.address,
          avID: res.data.user.avID,
          avURL: res.data.user.avURL,
          cc: res.data.user.cc,
          status: res.data.user.status,
          level: res.data.user.level,
          city: res.data.user.city,
          town: res.data.user.town,
          village: res.data.user.village,
          type: res.data.user.type,
          notification: res.data.user.notification,
          favorite: res.data.user.favorite,
          message: res.data.user.message,
          orderBuy: res.data.user.orderBuy,
          orderSell: res.data.user.orderSell,
          reviews: res.data.user.review,
          code: res.data.user.code,
          roles: res.data.user.role,
          cityShop: res.data.user.cityShop,
          nums: res.data.user.nums,
        })
      );
      Cookies.set("token", JSON.stringify(res.data.token));
      history.push("/");
      //Cookies.set("rfToken", JSON.stringify(res.data.rfToken));
      setIsloading(false);
    } else {
      setMessage({ content: res.data.message, color: "red" });
      setIsloading(false);
    }
    setIsloading(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "40px",
        overflow: "hidden",
      }}
    >
      <div className="Login-Box">
        <h3>Login</h3>
        <h4 style={{ height: 30, color: message.color }}>{message.content}</h4>
        <div className="Login-input-box">
          <label>Email</label>
          <input
            value={user.email}
            onChange={(e) => {
              e.preventDefault();
              setUser({ ...user, email: e.target.value });
            }}
            type="text"
          ></input>
        </div>
        <div className="Login-input-box">
          <label>Password</label>
          <input
            value={user.password}
            onChange={(e) => {
              e.preventDefault();
              setUser({ ...user, password: e.target.value });
            }}
            type="password"
          ></input>
        </div>
        <div className="Login-remember">
          <input
            onClick={(e) => {
              setCheckbox(e.target.checked ? 1 : -1);
            }}
            type="checkbox"
          ></input>
          <h4>Remember me?</h4>
        </div>

        <div onClick={(e) => handleLogin(e)} className="Login-btn">
          LOGIN
        </div>
        <div className="Login-forgot">Forgot Password?</div>
        <div className="Login-Or-Box">
          <span className="Login-Or-Line"></span>
          <span className="Login-Or-Text">OR</span>
          <span className="Login-Or-Line"></span>
        </div>
        <div className="Login-faang">
          <FacebookLogin
            appId="1226617864829309"
            children="Facebook"
            style={{
              width: "50%",
              padding: "10px",
              backgroundColor: "#8D9EFF",
              border: "1px solid gray",
              borderRadius: "4px",
              color: "white",
              cursor: "pointer",
            }}
            onSuccess={(response) => {
              //console.log("Login Success!", response);
            }}
            onFail={(error) => {
              console.log("Login Failed!", error);
            }}
            onProfileSuccess={(response) => {
              responseFacebook(response);
            }}
          />

          <button onClick={login} style={{width: "50%", backgroundColor: "#8D9EFF", border: "1px solid gray", color: "white", borderRadius: "3px"}}>
            <i className="fa-brands fa-google"></i>
            Google
          </button>
        </div>

        <div>
          <h4 style={{ color: "gray" }}>
            Chưa có tài khoản?{" "}
            <Link
              to={"/register"}
              style={{
                color: "green",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Đăng Ký
            </Link>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Login;
