import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../css/Swiper.css"
import axios from "axios";
import { api } from '../util/api';
import { version } from '../util/version';

function SwiperApp() {

  const [imgs, setImgs] = useState([]);

  async function getImgs() {
      const res = await axios.get(`${api}/admin/findImage/banner-top/${version}`);
      let mybanners = res.data.imgs || []
      setImgs(mybanners || []);
  }

  

  useEffect(() => {
    getImgs();
  }, []);


  return (
    <div >
        <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            }}
            pagination={{
            clickable: true,
            }}
            loop={true}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
        >
          {imgs.map((img, i) => {
            return <SwiperSlide key={i}><LazyLoadImage src={img.data}/></SwiperSlide>
          })}
      </Swiper>
    </div>
  );
}

export default SwiperApp;
