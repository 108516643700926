import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../redux/user";
import "../css/ProfileInfoUser.css";
import { GrUploadOption } from "react-icons/gr";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { api } from "../util/api";
import Loading from "../component/Loading";

function ProfileInfoUser(props) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const mycitys = useSelector(state => state.data.citys);
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [village, setVillage] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [citys, setCitys] = useState([]);
  const [towns, setTowns] = useState([]);
  const [villages, setVillages] = useState([]);
  const [popUp, setPopup] = useState(-1);
  const [changLocal, setChangLocal] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({content: "", color: ""});

  useEffect(() => {
    setImage(props.user.avURL);
    setCity(props.user.city);
    setTown(props.user.town);
    setVillage(props.user.village);
    setAddress(props.user.address);
    setPhone(props.user.phone);
    setUsername(props.user.username);
    setEmail(props.user.email);

    if(props.user.city !== "") {
      let citys = [];
      let towns = [];
      let villages = [];
      for(let i = 0; i < mycitys.length; i++) {
        citys.push(mycitys[i]["city"]);
        if(mycitys[i]["city"] === props.user.city) {
          for(let j = 0; j < mycitys[i]["towns"].length; j++) {
            towns.push(mycitys[i]["towns"][j]["town"]);
            if(mycitys[i]["towns"][j]["town"] === props.user.town) {
              for(let k = 0; k < mycitys[i]["towns"][j]["village"].length; k++) {
                villages.push(mycitys[i]["towns"][j]["village"][k]);
              }
            }
          }
        }
      }
      setCitys(citys);
      setTowns(towns);
      setVillages(villages);
    } else {
      let citys = [];
      for (var i = 0; i < mycitys.length; i++) {
        citys.push(mycitys[i]["city"]);
      }
      setCitys(citys);
    }
  }, [props]);

  useEffect(() => {
    if(typeof(image) !== "string") setPreview(window.URL.createObjectURL(image[0]));
    return () => {
      window.URL.revokeObjectURL(preview);
    }
  }, [image]);

  useEffect(() => {
    if(changLocal === -1) return;
    const towns = []
    for(let i = 0; i < mycitys.length; i++) {
      if(mycitys[i]["city"] === city) {
        for(let j = 0; j < mycitys[i]["towns"].length; j++) {
          towns.push(mycitys[i]["towns"][j]["town"]);
        }
      }
    }
    setTowns(towns);
    setTown(towns[0]);
  }, [city]);

  useEffect(() => {
    if(changLocal === -1) return;
    const villages = []
    for(let i = 0; i < mycitys.length; i++) {
      if(mycitys[i]["city"] === city) {
        for(let j = 0; j < mycitys[i]["towns"].length; j++) {
          if(mycitys[i]["towns"][j]["town"] === town) {
            for(let k = 0; k < mycitys[i]["towns"][j]["village"].length; k++) {
              villages.push(mycitys[i]["towns"][j]["village"][k]);
            }
          }
        }
      }
    }
    setVillages(villages);
    setVillage(villages[0]);
  }, [town]);

  async function update(e) {
    e.preventDefault();
    setIsLoading(true);
    setChangLocal(-1);
    if(props.user.city === city && props.user.town === town && props.user.village === village
        && props.user.address === address && props.user.phone === phone && props.user.avatar === image
        && props.user.username === username
    ) return;
    let form = new FormData();
    if(image !== props.user.avURL) form.append("file", image[0]);
    form.append("city", city);
    form.append("town", town);
    form.append("email", props.user.email);
    form.append("village", village);
    form.append("username", username);
    form.append("address", address);
    form.append("phone", phone);
    form.append("avID", props.user.avID);
    const res = await axios.post(`${api}/user/update`, form);
    if(res.data.message === "ok") {
      console.log(res.data.message)
      dispatch(userActions.login({
        username: res.data.user.username,
        email: res.data.user.email,
        phone: res.data.user.phone,
        address: res.data.user.address,
        avURL: res.data.user.avURL,
        avID: res.data.user.avID,
        cc: res.data.user.cc,
        status: res.data.user.status,
        level: res.data.user.level,
        city: res.data.user.city,
        town: res.data.user.town,
        village: res.data.user.village,
        type: res.data.user.type,
        notification: res.data.user.notification,
        favorite: res.data.user.favorite,
        message: res.data.user.message,
        orderBuy: res.data.user.orderBuy,
        orderSell: res.data.user.orderSell,
        reviews: res.data.user.review,
        code: res.data.user.code,
        roles: res.data.user.role,
        cityShop: res.data.user.cityShop,
        nums: res.data.user.nums,
      }));
      setImage(res.data.user.avURL);
      setCity(res.data.user.city);
      setTown(res.data.user.town);
      setVillage(res.data.user.village);
      setAddress(res.data.user.address);
      setPhone(res.data.user.phone);
      setUsername(res.data.user.username);
      setEmail(res.data.user.email);
      setMessage({content: "cập nhật thành công", color: "green"});
    } else {
      setMessage({content: "cập nhật thất bại vui lòng thử lại sau", color: "red"});
    }
    setIsLoading(false);
  }

  if(isLoading) {
    return <div style={{marginTop: "300px", marginBottom: "300px"}}> <Loading /></div>
  }

  return (
    <div className="profile-info-box">
        <div className="profile-info-avatar">
            {preview !== "" && <img className="profile-info-img" src={preview}/>}
            {preview === "" && props.user.avURL !== ""  && <img className="profile-info-img" src={props.user.avURL}/>}
            {image === "" && <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column",}}>
              <GrUploadOption size={30} />
              <h4>ảnh đại diện</h4>
            </div>}
            <input 
              onChange={(e) => {
                e.preventDefault();
                let target = e.target || e.srcElement;
                if (target.value.length === 0) return;
                setImage(e.target.files);
              }}
              type="file" className="profile-input-avatar"></input>
        </div>
        <div style={{marginTop: "10px", fontWeight: "bold", color: `${message.color}`}}>{message.content}</div>
        <div className="profile-info">
          <div className="profile-info-data">
            <label className="profile-info-label">
              username
            </label>
            <input value={username} onChange={(e) => setUsername(e.target.value)} className="profile-info-input"/>
          </div>
          <div className="profile-info-data">
            <label className="profile-info-label">
              email
            </label>
            <input value={email} readOnly className="profile-info-input"/>
          </div>
          <div className="profile-info-data">
            <label className="profile-info-label">
              phone
            </label>
            <input value={phone} onChange={(e) => {
              let number = "0123456789";
              let fix = "";
              for(let i = 0; i < e.target.value.length; i++) {
                if(number.includes(e.target.value[i])) fix += e.target.value[i];
              }
              setPhone(fix);
            }} className="profile-info-input"/>
          </div>

          <div className="profile-info-data-local">
            <div onClick={(e) => {
              setPopup(popUp === 1 ? -1 : 1);
            }} className="profile-info-dropdown">
              <label className="profile-info-label">
                Tỉnh - Thành
              </label>
              <div className="profile-info-locals-box">
                {city === "" ? <span className='profile-overflow-text'>Chọn Thành Phố</span> : <span className='profile-overflow-text'>{city}</span>}
                {popUp === 1 ? <AiFillCloseCircle /> : <BsFillArrowDownCircleFill />}
              </div>
              {popUp === 1 &&<div className="profile-dropdown-items">
                {citys.map((item, index) => {
                  return <p onClick={(e) => {
                    e.preventDefault();
                    setCity(item);
                    setChangLocal(item);
                    setPopup(-1);
                  }} key={index}>{item}</p>
                })}
              </div>}
            </div>

            <div className="profile-info-dropdown">
              <label className="profile-info-label">
                Quận - Huyện
              </label>
              <div onClick={(e) => {
                setPopup(popUp === 2 ? -1 : 2);
              }} className="profile-info-locals-box">
                {city === "" ? <span className='profile-overflow-text'>Chọn Quận Huyện</span > : <span className='profile-overflow-text'>{town}</span>}
                {popUp === 2 ? <AiFillCloseCircle /> : <BsFillArrowDownCircleFill />}
              </div>
              {popUp === 2 &&<div className="profile-dropdown-items">
                {towns.map((item, index) => {
                  return <p onClick={(e) => {
                    e.preventDefault();
                    setTown(item);
                    setChangLocal(item);
                    setPopup(-1);
                  }} key={index}>{item}</p>
                })}
              </div>}
            </div>

            <div className="profile-info-dropdown">
              <label className="profile-info-label">
                Phường - Xã
              </label>
              <div onClick={(e) => {
                setPopup(popUp === 3 ? -1 : 3);
              }} className="profile-info-locals-box">
                {city === "" ? <span className='profile-overflow-text'>Chọn Phường Xã</span> : <span className='profile-overflow-text'>{village}</span>}
                {popUp === 3 ? <AiFillCloseCircle /> : <BsFillArrowDownCircleFill />}
              </div>
              {popUp === 3 &&<div className="profile-dropdown-items">
                {villages.map((item, index) => {
                  return <p onClick={(e) => {
                    e.preventDefault();
                    setVillage(item);
                    setChangLocal(item);
                    setPopup(-1);
                  }} key={index}>{item}</p>
                })}
              </div>}
            </div>
          </div>

          <div className="profile-info-data">
            <label className="profile-info-label">
              address
            </label>
            <input value={address} onChange={(e) => setAddress(e.target.value)} className="profile-info-input"/>
          </div>
        <div onClick={(e) => update(e)} className="profile-btn-update">
          update
        </div>
        </div>
    </div>
  )
}

export default ProfileInfoUser