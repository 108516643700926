import React, { useState, useEffect } from "react";
import "../css/Register.css";
import axios from "axios";
import { api } from "../util/api";
import Loading from "./Loading";
import stringFix from "../util/stringFix";
import { Link } from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { gapi } from "gapi-script";
import { v4 as uuidv4 } from "uuid";
import { useGoogleLogin } from "@react-oauth/google";

function Register() {
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    type: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "187010286153-86d1hfpvl2ri6ra013mg5g31kir7pkj3.apps.googleusercontent.com",
        scope: "email",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  async function handleRegister(e) {
    e.preventDefault();
    setIsloading(true);
    if (
      user.username === "" ||
      user.email === "" ||
      user.password === "" ||
      !user.email.includes("@") ||
      !user.email.includes(".com")
    ) {
      setMessage("thông tin không hợp lệ");
      return;
    }
    user.email = stringFix(user.email);
    const response = await axios.post(`${api}/user/register`, {
      ...user,
      type: "email",
    });
    if (response.data.message === "ok") setMessage("Đăng ký thành công");
    else setMessage(response.data.message);
    setIsloading(false);
  }

  const register = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const resg = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          { headers: { Authorization: `Bearer ${respose.access_token}` } }
        );
        setIsloading(true);
        const res = await axios.post(`${api}/user/register`, {
          email: resg.data.email,
          username: resg.data.name,
          password: uuidv4(),
          type: "google",
        });
        if (res.data.message === "ok") setMessage("Đăng ký thành công");
        else setMessage(res.data.message);
        setIsloading(false);
      } catch (err) {
        setIsloading(false);
      }
    },
  });

  const responseFacebook = async (response) => {
    setIsloading(true);
    let data = response;
    if (!data) return setIsloading(false);
    data.type = "facebook";
    data.password = "";
    const res = await axios.post(`${api}/user/register`, {
      email: data.email,
      password: uuidv4(),
      username: data.name,
      type: "facebook",
    });
    if (res.data.message === "ok") setMessage("Đăng ký thành công");
    else setMessage(res.data.message);
    setIsloading(false);
  };


  if (isLoading) {
    return (
      <div style={{ backgroundColor: "white", width: "100%", height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "40px",
        overflow: "hidden",
      }}
    >
      <div className="register">
        <h3>Đăng Ký Với Email Của Bạn</h3>
        <h5>
          Đã có tài khoản?{" "}
          <Link
            to={"/login"}
            style={{ color: "green", textDecoration: "none" }}
          >
            Đăng Nhập
          </Link>
        </h5>
        <div className="register-input-box">
          <label>username</label>
          <input
            value={user.username}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
            type="String"
          ></input>
        </div>
        <div className="register-input-box">
          <label>email</label>
          <input
            value={user.email}
            onChange={(e) =>
              setUser({ ...user, email: stringFix(e.target.value) })
            }
            type="email"
          ></input>
        </div>
        <div className="register-input-box">
          <label>password</label>
          <input
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            type="password"
          ></input>
        </div>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            color: message === "Đăng ký thành công" ? "green" : "red",
          }}
        >
          {message}
        </div>
        <div className="register-checkbox">
          <input type="checkbox" />
          <h4>i agree to the terms and conditions</h4>
        </div>
        <p onClick={handleRegister} className="register-btn">
          Đăng Ký
        </p>
        <h4 style={{ width: "100%", textAlign: "center" }}>Hoặc Đăng Ký Với</h4>

        <div className="register-gf">
          <FacebookLogin
            appId="671319784733718"
            children="Facebook"
            style={{
              width: "50%",
              padding: "10px",
              backgroundColor: "#8D9EFF",
              border: "1px solid gray",
              borderRadius: "4px",
              color: "white",
              cursor: "pointer",
            }}
            onSuccess={(response) => {
              console.log("ok");
            }}
            onFail={(error) => {
              console.log("Login Failed!", error);
            }}
            onProfileSuccess={(response) => {
              responseFacebook(response);
            }}
          />

          <button
            onClick={register}
            style={{
              width: "50%",
              padding: "10px",
              backgroundColor: "#8D9EFF",
              border: "1px solid gray",
              borderRadius: "4px",
              color: "white",
              cursor: "pointer",
            }}
          >
            <i className="fa-brands fa-google"></i>
            Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;
