import React, { useEffect, useState } from "react";
import { api } from "../util/api";
import Cookies from "js-cookie";
import axios from "axios";
import TimeAgo from "javascript-time-ago";
import vi from "javascript-time-ago/locale/vi";
import "../css/OrderBuyScreen.css";
import { AiFillCloseSquare } from "react-icons/ai";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { TbMinusVertical } from "react-icons/tb";
import { useSelector } from "react-redux";
import TextEffect from "./TextEffect";
import { LazyLoadImage } from "react-lazy-load-image-component";

function OrderBuyScreen(props) {
  const [orders, setOrder] = useState([]);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [saveOrders, setSaveOrders] = useState({
    choxacnhan: [],
    danggiao: [],
    dagiao: [],
  });
  const [status, setStatus] = useState("");
  const [orderDetail, setOrderDetail] = useState("");
  const [showMBS, setShowMBS] = useState(-1);
  TimeAgo.addLocale(vi);
  const [message, setMessage] = useState({ content: "", color: "" });
  const timeAgo = new TimeAgo("vi-VN");
  const statusmap = { choxacnhan: "chờ xác nhận" };

  async function getOrder(s) {
    setIsLoading(true);
    if (!Cookies.get("token")) return;
    const token = Cookies.get("token").replaceAll('"', "");
    const res = await axios.get(
      `${api}/order/getOrderSell/${s}/${props.user.email}`,
      { headers: { authorization: "Bearer " + token } }
    );
    if (res.data.message === "ok") {
      setOrder(res.data.orders.reverse());
      setSaveOrders({ ...saveOrders, [s]: res.data.orders });
    }
    setIsLoading(false);
  }

  async function handlerdagiao(e, order) {
    e.preventDefault();
    if (order.status === "dagiao")
      return alert("đơn hàng đã được đã được giao");
    setIsLoading(true);
    const token = Cookies.get("token");
    const data = {
      email: user.email,
      time: order.createdAt,
      emailBuyer: order.email_nguoi_mua,
      nameshop: order.nameshop,
    };
    const res = await axios.post(
      `${api}/order/set_dagiao`,
      data,
      { headers: { authorization: "Bearer " + token } }
    );
    if (res.data.message === "ok") {
      let newOrders = [];
      setMessage({ content: "xác nhận đã giao thành công", color: "white" });
      newOrders = orders.filter((item) => item._id !== order._id);
      setOrder(newOrders);
    } else {
      setMessage({ content: "xác nhận đã giao thành công", color: "white" });
    }
    setIsLoading(false);
    setTimeout(
      function () {
        setMessage({
          content: "",
          color: "",
        });
      },
      [3000]
    );
  }

  async function handlerCancelOrder(e, order) {
    e.preventDefault();
    if (!Cookies.get("token")) return;
    setIsLoading(true);
    const token = Cookies.get("token");
    const data = {
      email: user.email,
      time: order.createdAt,
      emailBuyer: order.email_nguoi_mua,
      nameshop: order.nameshop,
    };
    const res = await axios.post(`${api}/order/cancelOrderSell`, data, {
      headers: { authorization: "Bearer " + token },
    });
    if (res.status === 200) {
      setIsLoading(false);
      let newOrders = orders.filter(
        (item) => item.createdAt !== order.createdAt
      );
      setOrder(newOrders);
      if (status === "choxacnhan") {
        setSaveOrders({ ...saveOrders, choxacnhan: newOrders });
      } else {
        setSaveOrders({ ...saveOrders, danggiao: newOrders });
      }
      setMessage({ content: res.data.message, color: "white" });
      await axios.post(`${api}/order/send_message`, {
        email: order.email_nguoi_mua,
        message: `Đơn hàng (${order.nameshop}) đã bị người bán hủy bỏ`,
      });
    } else {
      setIsLoading(false);
      setMessage({ content: res.data.message, color: "white" });
    }
    setTimeout(
      function () {
        setMessage({
          content: "",
          color: "",
        });
      },
      [3000]
    );
  }

  async function handlerAcceptOrder(e, order) {
    e.preventDefault();
    if (!Cookies.get("token")) return;
    if (order.status === "danggiao") return alert("đơn hàng đã được xác nhận");
    setIsLoading(true);
    const token = Cookies.get("token");
    const data = {
      email: user.email,
      time: order.createdAt,
      emailBuyer: order.email_nguoi_mua,
      nameshop: order.nameshop,
    };
    const res = await axios.post(`${api}/order/acceptOrderSell`, data, {
      headers: { authorization: "Bearer " + token },
    });
    if (res.status === 200) {
      let newOrders = [];
      setIsLoading(false);
      if (res.data.message === "đơn hàng đã bị người mua hủy bỏ") {
        setMessage({ content: res.data.message, color: "white" });
        newOrders = orders.filter(
          (item) =>
            item.createdAt !== order.createdAt &&
            item.email_nguoi_mua !== order.email_nguoi_mua
        );
        setTimeout(
          function () {
            setMessage({
              content: "",
              color: "",
            });
          },
          [3000]
        );
      } else {
        let mydata = {
          email: order.email_nguoi_mua,
          message: `Đơn hàng (${order.nameshop}) đã được xác nhận`,
        };
        for (let i = 0; i < orders.length; i++) {
          if (
            orders[i].createdAt === order.createdAt &&
            orders[i].email_nguoi_mua === order.email_nguoi_mua
          ) {
            let neworder = orders[i];
            neworder.status = "danggiao";
            newOrders.push(neworder);
          } else newOrders.push(orders[i]);
        }
        setMessage({ content: res.data.message, color: "white" });
        setTimeout(
          function () {
            setMessage({
              content: "",
              color: "",
            });
          },
          [3000]
        );
        await axios.post(`${api}/order/send_message`, mydata);
      }
      setOrder(newOrders);
      setSaveOrders({ ...saveOrders, choxacnhan: newOrders });
      setMessage({
        content: res.data.message,
        color: "white",
      });
    } else {
      setIsLoading(false);
      setMessage({ content: res.data.message, color: "white" });
    }
  }

  if (isLoading) {
    return (
      <div className="order-loading">
        <TextEffect text={"Loading..."} fontsize={40} />;
      </div>
    );
  }

  return (
    <div className="myorder-screen">
      <div className="myorder-top-box">
        <div
          onClick={(e) => {
            if (saveOrders.choxacnhan.length > 0) {
              setOrder(saveOrders.choxacnhan);
              setStatus("choxacnhan");
            } else {
              getOrder("choxacnhan");
              setStatus("choxacnhan");
            }
          }}
          style={{
            backgroundColor: status === "choxacnhan" ? "#FB2576" : "gray",
          }}
          className="myorder-top-btn"
        >
          Chờ xác nhận
        </div>
        <div
          onClick={(e) => {
            if (saveOrders.danggiao.length > 0) {
              setOrder(saveOrders.danggiao);
              setStatus("danggiao");
            } else {
              getOrder("danggiao");
              setStatus("danggiao");
            }
          }}
          style={{
            backgroundColor: status === "danggiao" ? "#FB2576" : "gray",
          }}
          className="myorder-top-btn"
        >
          Đang giao
        </div>

        <div
          onClick={(e) => {
            if (saveOrders.dagiao.length > 0) {
              setOrder(saveOrders.dagiao);
              setStatus("dagiao");
            } else {
              getOrder("dagiao");
              setStatus("dagiao");
            }
          }}
          style={{
            backgroundColor: status === "dagiao" ? "#FB2576" : "gray",
          }}
          className="myorder-top-btn"
        >
          Đã Giao
        </div>

        <div
          style={{
            flex: 1,
            color: message.color,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {message.content}
        </div>
      </div>
      <hr style={{ width: "95%" }} />


      <div className="myorder-orders-list">
        {orders.map((order, index) => {
          return (
            <div key={index} className="myorder-items">
              <div className="myorder-item-time-box">
                <div className="myorder-time-item">
                  {timeAgo.format(parseInt(order.createdAt))}
                </div>
                <div className="myorder-time-item myorder-item-length">
                  {order.items.length}
                </div>
              </div>
              <LazyLoadImage
                onClick={(e) => setOrderDetail(order)}
                src={order.items[0].img_url}
                alt=""
                className="myorder-img"
              />
              <div className="myorder-data-box">
                <div className="myorder-data-item-left">tên món:</div>
                <div className="myorder-data-item-right">
                  {order.items[0].ten}
                </div>
              </div>
              <div className="myorder-data-box">
                <div className="myorder-data-item-left">phân loại:</div>
                <div className="myorder-data-item-right">
                  {order.items[0].phanloai.ten}
                </div>
              </div>
              <div className="myorder-data-box">
                <div className="myorder-data-item-left">món thêm:</div>
                <div className="myorder-data-item-right">
                  {order.items[0].monbosung.length > 0
                    ? `${order.items[0].monbosung.length} món`
                    : "???"}
                </div>
              </div>
              <div
                style={{ border: "none", padding: "0", marginTop: "5px" }}
                className="myorder-data-box"
              >
                {order.status !== "dagiao" && <div
                  onClick={(e) => handlerCancelOrder(e, order)}
                  className="myorder-data-item-end-right"
                >
                  huỷ đơn
                </div>}
                {order.status === "choxacnhan" && (
                  <div
                    style={{
                      background: "#083AA9",
                    }}
                    onClick={(e) => {
                      if (order.status !== "choxacnhan")
                        return alert("Đơn hàng đã được xác nhận");
                      handlerAcceptOrder(e, order);
                    }}
                    className="myorder-data-item-end-right"
                  >
                    xác nhận
                  </div>
                )}

                {order.status === "danggiao" && (
                  <div
                    style={{
                      background: "#083AA9",
                    }}
                    onClick={(e) => {
                      if (
                        order.status === "choxacnhan" ||
                        order.status === "dagiao"
                      )
                        return alert("Đơn hàng đã được giao");
                      handlerdagiao(e, order);
                    }}
                    className="myorder-data-item-end-right"
                  >
                  xác nhận đã giao
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {orderDetail !== "" && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            position: "fixed",
            top: "0",
            left: "0",
            opacity: "0.5",
            zIndex: "50",
          }}
        ></div>
      )}
      {orderDetail !== "" && (
        <div id="style-2" className="myorder-detail">
          <div>
            <div className="myoder-detail-close">
              <div className="myorder-detail-shop-title">
                {orderDetail.nameshop}
              </div>
              <AiFillCloseSquare
                onClick={(e) => setOrderDetail("")}
                size={40}
                color={"black"}
              />
            </div>
            <div className="myorder-detail-items">
              {orderDetail.items.map((item, index) => {
                return (
                  <div key={index} className="myorder-detail-item">
                    {item.monbosung.length > 0 && (
                      <div
                        style={{
                          transform:
                            showMBS === index ? "scaleY(1)" : "scaleY(0)",
                        }}
                        id="style-8"
                        className="myorder-detail-mbs"
                      >
                        <div className="myorder-detail-map-mbs">
                          {item.monbosung.map((mbs, index) => {
                            return (
                              <div
                                key={index}
                                className="myorder-detail-mbs-box-1"
                              >
                                <span
                                  style={{ color: "white" }}
                                  className="myorder-detail-mbs-item"
                                >
                                  {mbs.ten}
                                </span>
                                <span
                                  style={{
                                    color: "orangered",
                                    fontWeight: "bold",
                                  }}
                                  className="myorder-detail-mbs-item"
                                >
                                  {mbs.tien}.vnđ
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          onClick={(e) => setShowMBS(-1)}
                          className="myorder-detail-mbs-box-2"
                        >
                          Đóng cửa sổ
                        </div>
                      </div>
                    )}
                    <LazyLoadImage
                      width="100%"
                      height="100%"
                      src={item.img_url}
                      alt=""
                      className="myorder-detail-item-img"
                    />
                    <div className="myoder-detail-data-box">
                      <div className="myorder-detail-data-item">
                        <div className="myorder-detail-data-item-left">
                          tên món:{" "}
                        </div>
                        <div className="myorder-detail-data-item-right">
                          {item.ten}
                        </div>
                      </div>
                      <div className="myorder-detail-data-item">
                        <div className="myorder-detail-data-item-left">
                          phan loai:{" "}
                        </div>
                        <div className="myorder-detail-data-item-right">
                          {item.phanloai.ten !== "" ? item.phanloai.ten : "???"}
                        </div>
                      </div>
                      <div className="myorder-detail-data-item">
                        <div className="myorder-detail-data-item-left">
                          mon them:{" "}
                        </div>
                        {item.monbosung.length > 0 ? (
                          <div
                            style={{ display: "flex", gap: "10px" }}
                            className="myorder-detail-data-item-right"
                          >
                            <span>{item.monbosung.length} món </span>
                            <TbMinusVertical size={20} />
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                color: "green",
                                cursor: "pointer",
                              }}
                              onClick={(e) => setShowMBS(index)}
                            >
                              bấm để xem <IoMdArrowDropdownCircle />
                            </span>
                          </div>
                        ) : (
                          "???"
                        )}
                      </div>
                      <div className="myorder-detail-data-item">
                        <div className="myorder-detail-data-item-left">
                          so luong:{" "}
                        </div>
                        <div className="myorder-detail-data-item-right">
                          {item.so_luong}
                        </div>
                      </div>

                      <div
                        style={{ border: "none" }}
                        className="myorder-detail-data-item"
                      >
                        <div className="myorder-detail-data-item-left">
                          tong tien:{" "}
                        </div>
                        <div
                          style={{ color: "orangered", fontWeight: "bold" }}
                          className="myorder-detail-data-item-right"
                        >
                          {item.tongtien * item.so_luong}.vnđ
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="myorder-detail-bottom-box">
            <div className="myorder-detail-data-bottom-item">
              <div className="myorder-detail-data-item-left">
                địa chỉ giao hàng:{" "}
              </div>
              <div className="myorder-detail-databottom-item-right">
                {orderDetail.diachi_giaohang[3]} -{" "}
                {orderDetail.diachi_giaohang[2]} -{" "}
                {orderDetail.diachi_giaohang[1]} -{" "}
                {orderDetail.diachi_giaohang[0]}
              </div>
            </div>

            <div className="myorder-detail-data-bottom-item">
              <div className="myorder-detail-data-item-left">
                địa chỉ nhận hàng:{" "}
              </div>
              <div className="myorder-detail-databottom-item-right">
                {orderDetail.diachi_nhanhang[3]} -{" "}
                {orderDetail.diachi_nhanhang[2]} -{" "}
                {orderDetail.diachi_nhanhang[1]} -{" "}
                {orderDetail.diachi_nhanhang[0]}
              </div>
            </div>

            <div className="myorder-detail-data-bottom-item">
              <div className="myorder-detail-data-item-left">
                số điện thoại người bán:{" "}
              </div>
              <div className="myorder-detail-databottom-item-right">
                {orderDetail.sdt_nguoi_ban}
              </div>
            </div>

            <div className="myorder-detail-data-bottom-item">
              <div className="myorder-detail-data-item-left">
                số điện thoại người mua:{" "}
              </div>
              <div className="myorder-detail-databottom-item-right">
                {orderDetail.sdt_nguoi_mua}
              </div>
            </div>

            <div
              style={{ border: "none" }}
              className="myorder-detail-data-bottom-item"
            >
              <div className="myorder-detail-data-item-left">
                tổng tiền (chưa tính phí ship):{" "}
              </div>
              <div
                style={{ color: "orangered" }}
                className="myorder-detail-databottom-item-right"
              >
                {orderDetail.tongtien}.vnđ
              </div>
            </div>
            <button
              onClick={(e) => setOrderDetail("")}
              className="myorder-detail-close-bottom"
            >
              Đóng cửa sổ
            </button>
          </div>
        </div>
      )}
      <div
        style={{
          transform: message.content.length === 0 ? "scaleY(0)" : "scaleY(1)",
        }}
        className="cart-order-message"
      >
        <div onClick={(e) => setMessage({ content: "", color: "" })}>
          <AiFillCloseSquare size="30px" />
        </div>
        <div style={{ color: message.color }} className="cart-message-text">
          {message.content}
        </div>
        <div style={{ opacity: 0 }}>1</div>
      </div>
    </div>
  );
}

export default OrderBuyScreen;
