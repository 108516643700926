import React, { useState, useEffect } from "react";
import "../css/AddProduct.css";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { MdOutlineFoodBank } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { api } from "../util/api";
import { BiLoader } from "react-icons/bi";
import stringFix from "../util/stringFix";
import { MdFileUpload } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { IoMdRemoveCircle } from "react-icons/io";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Cookies from "js-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../css/ScrollBar.css"

function AddProduct() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [menus, setMenus] = useState([]);
  const [menu, setMenu] = useState("");
  const [updateMenu, setUpdateMenu] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mainItems, setMainItems] = useState([]);
  const [foodImage, setFoodImage] = useState("");
  const [nameFood, setNameFood] = useState("");
  const [priceFood, setPriceFood] = useState("");
  const [preview, setPreview] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [deleteFood, setDeleteFood] = useState("");
  const [updateItem, setUpdateItem] = useState("");
  const [indexUpdate, setIndexUpdate] = useState(-1);
  const [size, setSize] = useState(1000000);
  const [subItem, setSubItem] = useState({ name: "", price: "" });
  const [subItems, setSubItems] = useState([]);
  const [subItemsUpdate, setSubItemsUpdate] = useState("");
  const [updateSubItemsIndex, setSubItemsUpdateIndex] = useState(-1);
  const [classifys, setClassifys] = useState([]);
  const [classify, setClassify] = useState({ name: "", price: "" });
  const [showClassify, setShowClassify] = useState(-1);
  const [showMenu, setShowMenu] = useState(false);
  const [menuDelete, setMenuDelete] = useState("");

  useEffect(() => {
    if (foodImage.length) setPreview(window.URL.createObjectURL(foodImage[0]));
    return () => {
      window.URL.revokeObjectURL(preview);
    };
  }, [foodImage]);

  useEffect(() => {
    if (user.roles.indexOf("seller") < 0) return;
    setIsLoading(true);
    async function fecthData() {
      const res = await axios.get(`${api}/shop/profile/findMenu/${user.cityShop}/${user.email}`);
      if (res.data.message === "ok") {
        setMenus(res.data.menu || []);
        setMenu(menu);
        setYear(res.data.year || "");
        setMonth(res.data.month || "");
        setSize(res.data.size >= 0 ? res.data.size : 1000000);
        if (res.data.menu.length > 0) {
          setMenu(res.data.menu[0] || "");
          // find item
          const resItems = await axios.get(
            `${api}/shop/profile/findItemMenu/${user.email + stringFix(res.data.menu[0]) || ""}/${res.data.menu[0] || ""}`
          );
          if (resItems.data.message === "ok") {
            setMainItems(resItems.data.mainItems || []);
            setSubItems(resItems.data.subItems || []);
          }
        }
      }
    }
    fecthData();
    setIsLoading(false);
  }, []);

  const createMenu = async (e) => {
    e.preventDefault();
    const s = "-&0123456789qwertyuiopasdfghjklzxcvbnm ";
    const updateMenuFix = stringFix(updateMenu);
    for (let i = 0; i < updateMenuFix.length; i++) {
      if (!s.includes(updateMenuFix[i]))
        return alert("Tên menu không được chứa ký tự đặc biệt");
    }
    if (updateMenu.trim().length === 0 || updateMenu.length > 25)
      return alert("Tên menu không được bỏ trống hoặc dài hơn 25 ký tự");
    if(menus.indexOf(updateMenu) >= 0) return alert("Tên menu không trùng nhau");
    setIsLoading(true);
    const data = {
      namemenu: updateMenu,
      search: user.email + stringFix(updateMenu),
      email: user.email,
    };
    const res = await axios.post(`${api}/shop/createMenu`, data);
    if (res.data.message === "ok") {
      setMenus([...menus, updateMenu]);
      setMenu(updateMenu);
      setMainItems([]);
      setSubItems([]);
      setUpdateMenu("");
      setIsLoading(false);
      setSize(0);
    } else alert(res.data.message);
    setIsLoading(false);
  };

  const deleteMenuHandler = async (e) => {
    e.preventDefault();
    if(menuDelete !== menu) return alert("chưa nhập đúng tên thực đơn đang chọn, vui lòng thử lại sau");
    if(subItems.length > 0 || mainItems.length > 0) return alert("chưa xóa hết món chính, món phụ, vui lòng thử lại sau");
    if(menus.length === 1) return alert("không thể xóa thực đơn cuối cùng");
    const menuIndex = menus.findIndex((item) => item !== menuDelete);
    const swapMenu = menus[menuIndex];
    const res = await axios.post(`${api}/shop/deleteMenu`, {email: user.email, menuDelete: menuDelete, menu: menu, searchMenuDelete: user.email + stringFix(menuDelete), searchSwapMenu: user.email + stringFix(swapMenu)});
    if(res.data.message === "ok") {
      setMenu(res.data.swapMenu.namemenu);
      setMainItems(res.data.swapMenu.mainItems);
      setSubItems(res.data.swapMenu.subItems);
      const newMenus = menus.filter((item) => item !== menuDelete);
      setMenus(newMenus);
    } else return alert(res.data.message);
  }

  const deleteFoodHandler = async (e) => {
    e.preventDefault();
    const token = await Cookies.get("token").replaceAll('"', "");
    if (deleteFood.target === "main") {
      setIsLoading(true);
      const res = await axios.post(
        `${api}/shop/deleteFood`,
        {
          deleteFood: deleteFood,
          search: user.email + stringFix(menu),
          email: user.email,
        },
        { headers: { authorization: "Bearer " + token } }
      );
      if (res.data.message === "ok") {
        const newMainItems = mainItems.filter(
          (item) => item.name !== deleteFood.name
        );
        setMainItems(newMainItems);
        setDeleteFood("");
        setIsLoading(false);
        setSize((prev) => prev - 1);
      }
      setIsLoading(false);
    } else if (deleteFood.target === "sub") {
      setIsLoading(true);
      const res = await axios.post(
        `${api}/shop/deleteSubFood`,
        {
          deleteFood: deleteFood,
          search: user.email + stringFix(menu),
          email: user.email,
        },
        { headers: { authorization: "Bearer " + token } }
      );
      if (res.data.message === "ok") {
        const newSubItems = subItems.filter(
          (item) => item.name !== deleteFood.name
        );
        setSubItems(newSubItems || []);
        setDeleteFood("");
        setIsLoading(false);
      }
      setIsLoading(false);
    } else return;
  };

  async function createFood(e) {
    e.preventDefault();
    if(menus.length === 0) return alert("Chưa có thực đơn nào được tạo");
    if (size >= 500) return alert("Đã quá giới hạn số lượng sản phẩm được tạo");
    if (
      priceFood === "" ||
      foodImage === "" ||
      nameFood === "" ||
      year === "" ||
      month === "" ||
      user.email === "" ||
      !parseInt(priceFood)
    ) {
      setIsLoading(false);
      return alert("Thiếu thông tin sản phẩm");
    }
    for (let i = 0; i < mainItems.length; i++) {
      if (mainItems[i].name === nameFood) {
        alert(`trùng tên món ăn trong thực đơn ${menu}`);
        return;
      }
    }
    for(let i = 0; i < classifys.length; i++) {
      if(classifys[i].name === "" || classifys[i].price === "" || !parseInt(classifys[i].price)) {
        alert("dữ liệu phân loại không hợp lệ vui lòng kiểm tra lại");
        return;
      }
    }
    setIsLoading(true);
    let uuid = uuidv4();
    const form = new FormData();
    form.append("file", foodImage[0]);
    form.append("year", year);
    form.append("uuid", uuid);
    form.append("price", priceFood);
    form.append("email", user.email);
    form.append("namemenu", menu);
    form.append("classifys", JSON.stringify(classifys));
    form.append("nameFood", nameFood);
    form.append("search", user.email + stringFix(menu));
    const res = await axios.post(`${api}/shop/createItemFood`, form);
    if (res.data.message === "ok") {
      setMainItems(res.data.menu.mainItems || mainItems);
      window.URL.revokeObjectURL(preview);
      setSize((prev) => prev + 1);
      setNameFood("");
      setPriceFood("");
      setFoodImage("");
      setPreview("");
      setClassify({name: "", price: ""});
      setClassifys([]);
      setIsLoading(false);
    } else alert(res.data.message)
    setIsLoading(false);
  }

  async function updateItemHandler(e) {
    e.preventDefault();
    if (indexUpdate === -1) return;
    setIsLoading(true);
    const res = await axios.post(`${api}/shop/updateItemFood`, {
      updateItem: updateItem,
      search: user.email + stringFix(menu),
      id: indexUpdate,
    });
    if (res.data.message === "ok") {
      setMainItems(res.data.mainItems);
      setIsLoading(false);
    }
    setIndexUpdate(-1);
    setIsLoading(false);
  }

  const changeMenusHandler = async (item) => {
    setSubItemsUpdate("");
    setSubItemsUpdateIndex(-1);
    setUpdateItem("");
    setIndexUpdate(-1);
    setIsLoading(true);
    const res = await axios.get(`${api}/shop/profile/findItemMenu/${user.email + stringFix(item)}/${stringFix(item)}`
    );
    if (res.data.message === "ok") {
      setMainItems(res.data.mainItems);
      setSubItems(res.data.subItems);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  async function createSubFood(e) {
    e.preventDefault(e);
    if (
      subItem.name.length > 20 ||
      subItem.name.trim().length === 0 ||
      subItem.price.trim().length === 0 ||
      subItem.price.length > 12 ||
      !parseInt(subItem.price)
    ) {
      alert("Thông tin không hợp lệ vui lòng kiểm tra lại");
      return;
    }
    setIsLoading(true);
    const res = await axios.post(`${api}/shop/createSubItem`, {
      name: subItem.name,
      price: subItem.price,
      search: user.email + stringFix(menu),
    });
    if (res.data.message === "ok") {
      setSubItems(res.data.subItems);
      setSubItem({ name: "", price: "" });
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  async function updateSubFoodhandler(e) {
    e.preventDefault();
    if(subItems.findIndex((item) => item.name === subItemsUpdate.name.trim()) >= 0) return alert("tên món không được trùng lặp, vui lòng đổi tên khác");
    setIsLoading(true);
    const res = await axios.post(`${api}/shop/updateSubFood`, {
      search: user.email + stringFix(menu),
      name: subItemsUpdate.name,
      price: subItemsUpdate.price,
      id: updateSubItemsIndex,
    });
    if (res.data.message === "ok") {
      setSubItems(res.data.subItems);
      setSubItemsUpdateIndex(-1);
      setSubItemsUpdate("");
    }
    setIsLoading(false);
  }
  if (isLoading) {
    return (
      <div className="addproduct-loading">
        <BiLoader className="addproduct-icon-loading" />
      </div>
    );
  }

  if (user.roles.indexOf("seller") < 0) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "500px",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        <p>Chưa đăng ký bán hàng?</p>
        <p>
          ấn vào <span style={{ color: "blue" }}>Thông tin Shop</span> để đăng
          ký
        </p>
      </div>
    );
  }

  return (
    <div className="AddProduct">
      <div className="AddProduct-btn">

        <div onClick={(e) => setShowMenu(!showMenu)} className="AddProduct-btn-menu">
          <span style={{ color: "#C539B4", fontWeight: "bold" }}>
            {menu === "" ? "Thực Đơn" : menu}
          </span>
          <BsFillArrowDownCircleFill size={20} />
          {showMenu && <div className="addproduct-menu-box">
            {menus.map((item, index) => {
              if(item === menu) return;
              return (
                <div
                  onClick={(e) => {
                    setMenu(item);
                    changeMenusHandler(item);
                  }}
                  className="addproduct-menu-item"
                  key={index}
                >
                  <span>{item}</span>
                  <hr />
                </div>
              );
            })}
          </div>}
        </div>
        
        <div className="AddProduct-add-menu">
          <input
            value={updateMenu}
            onChange={(e) => setUpdateMenu(e.target.value)}
            placeholder="thêm thực đơn"
            type="text"
            maxLength="25"
          />
          <MdOutlineFoodBank onClick={(e) => createMenu(e)} size={40} />
        </div>
        
        <div className="AddProduct-add-menu">
          <input
            value={menuDelete}
            onChange={(e) => setMenuDelete(e.target.value)}
            placeholder="xóa thực đơn - điền tên"
            type="text"
            maxLength="25"
          />
          <AiFillDelete onClick={(e) => deleteMenuHandler(e)} size={35} />
        </div>
      </div>

      <hr className="AddProduct-hr" />

      <div className="AddProduct-sp">
        {menu !== "" && (
          <span
            style={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              Danh sách món ăn của thực đơn -{" "}
              <span style={{ color: "#C539B4", fontWeight: "bold" }}>
                {menu}
              </span>
            </div>
          </span>
        )}
        {menu === "" && (
          <span style={{ textAlign: "center", width: "100%" }}>
            Chưa có thực đơn vui lòng tạo thực đơn trước khi tạo thêm món ăn
          </span>
        )}
      </div>

      <div className="addproduct-items-box">
        <div className="addproduct-add-btn">
          <div className="addproduct-image">
            {preview === "" && (
              <div style={{ display: "flex", alignItems: "center" }}>
                Thêm ảnh <span style={{ width: "5px" }}></span>{" "}
                <MdFileUpload size={20} />
              </div>
            )}
            {preview !== "" && (
              <LazyLoadImage width="90%" height="80%" src={preview}></LazyLoadImage>
            )}
            <input
              onChange={(e) => {
                let target = e.target || e.srcElement;
                if (target.value.length === 0) return;
                if (e.target.files[0].size > 1000000) {
                  alert("Dung lượng ảnh không được lớn hơn 1mb");
                  return;
                }
                setFoodImage(e.target.files);
              }}
              className="addproduct-image-input"
              type="file"
            />
          </div>

          <div className="addproduct-input">
            <input
              value={nameFood}
              onChange={(e) => setNameFood(e.target.value)}
              placeholder="Tên món"
              type="text"
              className=""
              maxLength="50"
            ></input>
          </div>

          <div className="addproduct-input">
            <input
              value={priceFood}
              onChange={(e) => setPriceFood(e.target.value)}
              placeholder="Giá tiền"
              type="text"
              className=""
              maxLength="10"
            ></input>
          </div>

          <div className="addproduct-pl-box">
            <span
              style={{ flex: 1, textAlign: "left" }}
              onClick={(e) => {
                setShowClassify(showClassify === 1000000 ? -1 : 1000000);
                setClassify({ name: "", price: ""});
              }}
            >
              Phân Loại
            </span>
            <BsFillArrowDownCircleFill />
            {showClassify === 1000000 && (
              <div id="style-8" className="addproduct-pl-list">
                <input
                  value={classify.name}
                  onChange={(e) =>
                    setClassify({ name: e.target.value, price: classify.price })
                  }
                  type="text"
                  maxLength="20"
                  placeholder="tên"
                />
                <input
                  value={classify.price}
                  onChange={(e) =>
                    setClassify({ name: classify.name, price: e.target.value })
                  }
                  type="text"
                  maxLength="10"
                  placeholder="giá tiền"
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (classify.name === "" || classify.price === "" ||!parseInt(classify.price))return alert("dữ liệu không hợp lệ, vui lòng thử lại sau");
                    for (let i = 0; i < classifys.length; i++) {
                      if(classifys[i].name === classify.name) return alert("Tên phân loại không được trùng lặp")
                    }
                    const newClassifys = [...classifys, classify];
                    setClassifys(newClassifys);
                    setClassify({ name: "", price: "" });
                  }}
                >
                  Thêm phân loại
                </button>
                {classifys.map((item, index) => {
                  return (
                    <div key={index} className="addproduct-pl-item">
                      <div className="pl-item">
                        <span>tên: </span>
                        <span>{item.name}</span>
                      </div>
                      <div className="pl-item">
                        <span>giá: </span>
                        <span>{item.price}.vnđ</span>
                      </div>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          const newClassifys = classifys.filter(
                            (it) => it.name !== item.name
                          );
                          setClassifys(newClassifys);
                        }}
                        className="pl-delete"
                      >
                        xóa phân loại
                      </div>
          
                    </div>
                  );
                })}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowClassify(-1);
                  }}
                >
                  Đóng cửa sổ
                </button>
              </div>
            )}
          </div>

          <button onClick={(e) => createFood(e)} className="addproduct-submit">
            Tạo món
          </button>
        </div>

        {/* list items */}
        {mainItems.map((item, index) => {
          if (indexUpdate === index) {
            return (
              <div key={index} className="addproduct-add-btn">
                <div className="addproduct-image">
                  {/* {preview === "" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Thêm ảnh <span style={{ width: "5px" }}></span>{" "}
                      <MdFileUpload size={20} />
                    </div>
                  )}
                  {preview !== "" && (
                    <img width="90%" height="80%" src={preview}></img>
                  )} */}
                  {/* <input
                    onChange={(e) => {
                      let target = e.target || e.srcElement;
                      if (target.value.length === 0) return;
                      if (e.target.files[0].size > 1000000) {
                        alert("Dung lượng ảnh không được lớn hơn 1mb");
                        return;
                      }
                      setFoodImage(e.target.files);
                    }}
                    className="addproduct-image-input"
                    type="file"
                  /> */}
                  <LazyLoadImage width="90%" height="80%" src={item.img.url}></LazyLoadImage>
                </div>
                <div className="addproduct-input">
                  <input
                    value={updateItem.name}
                    placeholder="Tên món"
                    type="text"
                    className=""
                    onChange={(e) => {
                      setUpdateItem({ ...updateItem, name: e.target.value });
                    }}
                    maxLength="20"
                  ></input>
                </div>
                <div className="addproduct-input">
                  <input
                    value={updateItem.price}
                    placeholder="Giá tiền"
                    type="text"
                    className=""
                    onChange={(e) => {
                      setUpdateItem({ ...updateItem, price: e.target.value });
                    }}
                    maxLength="12"
                  ></input>
                </div>

                <div className="addproduct-pl-box">
                  <span
                    style={{ flex: 1, textAlign: "left" }}
                    onClick={(e) => {                   
                      setClassify({ name: "", price: ""});
                      setShowClassify(showClassify === index ? -1 : index);
                    }}
                  >
                    Phân Loại
                  </span>
                  <BsFillArrowDownCircleFill />
                  {showClassify === index && (
                    <div id="style-8" className="addproduct-pl-list">
                      <input
                        value={classify.name}
                        onChange={(e) =>
                          setClassify({
                            name: e.target.value,
                            price: classify.price,
                          })
                        }
                        type="text"
                        maxLength="20"
                        placeholder="tên"
                      />
                      <input
                        value={classify.price}
                        onChange={(e) =>
                          setClassify({
                            name: classify.name,
                            price: e.target.value,
                          })
                        }
                        type="text"
                        maxLength="10"
                        placeholder="giá tiền"
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (classify.name === "" ||classify.price === "" ||!parseInt(classify.price))return alert("dữ liệu không hợp lệ, vui lòng thử lại sau");
                          for (let i = 0; i < updateItem.classifys.length; i++) {
                            if(updateItem.classifys[i].name === classify.name) return alert("Tên phân loại không được trùng lặp")
                          }
                          const newClassifys = [...updateItem.classifys, classify];
                          setUpdateItem({...updateItem, classifys: newClassifys});
                          setClassify({ name: "", price: "" });
                        }}
                      >
                        Thêm phân loại
                      </button>
                      {updateItem.classifys.map((item, index) => {
                        return (
                          <div key={index} className="addproduct-pl-item">
                            <div className="pl-item">
                              <span>tên: </span>
                              <span>{item.name}</span>
                            </div>
                            <div className="pl-item">
                              <span>giá: </span>
                              <span>{item.price}.vnđ</span>
                            </div>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                const newClassifys = updateItem.classifys.filter(
                                  (it) => it.name !== item.name
                                );
                                setUpdateItem({...updateItem, classifys: newClassifys});
                              }}
                              className="pl-delete"
                            >
                              xóa phân loại
                            </div>
                          </div>
                        );
                      })}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowClassify(-1);
                        }}
                      >
                        Đóng cửa sổ
                      </button>
                    </div>
                  )}
                </div>

                <div className="addproduct-box-btn">
                  <button
                    onClick={(e) => updateItemHandler(e)}
                    className="addproduct-update-btn"
                  >
                    update
                  </button>
                  <button
                    onClick={(e) => {
                      setIndexUpdate(-1);
                      setUpdateItem("");
                    }}
                    className="addproduct-icon-btn"
                    style={{
                      backgroundColor: "#404258",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    <IoMdRemoveCircle size={15} />
                  </button>
                  <button
                    onClick={(e) => setDeleteFood(item)}
                    className="addproduct-icon-btn"
                  >
                    <AiFillDelete size={15} />
                  </button>
                </div>
              </div>
            );
          }
          return (
            <div key={index} className="addproduct-add-btn">
              <div className="addproduct-image">
                {/* {preview === "" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Thêm ảnh <span style={{ width: "5px" }}></span>{" "}
                    <MdFileUpload size={20} />
                  </div>
                )}
                {preview !== "" && (
                  <img width="90%" height="80%" src={preview}></img>
                )} */}
                {/* <input
                  onChange={(e) => {
                    let target = e.target || e.srcElement;
                    if (target.value.length === 0) return;
                    if (e.target.files[0].size > 1000000) {
                      alert("Dung lượng ảnh không được lớn hơn 1mb");
                      return;
                    }
                    setFoodImage(e.target.files);
                  }}
                  className="addproduct-image-input"
                  type="file"
                /> */}
                <LazyLoadImage width="90%" height="80%" src={item.img.url}></LazyLoadImage>
              </div>
              <div className="addproduct-input">
                <input
                  value={item.name}
                  placeholder="Tên món"
                  type="text"
                  className=""
                  readOnly={true}
                  maxLength="20"
                ></input>
              </div>
              <div className="addproduct-input">
                <input
                  value={item.price}
                  placeholder="Giá tiền"
                  type="text"
                  className=""
                  readOnly={true}
                  maxLength="10"
                ></input>
              </div>

              <div className="addproduct-pl-box">
                <span
                  style={{ flex: 1, textAlign: "left" }}
                  onClick={(e) => {
                  }}
                >
                  Phân Loại
                </span>
                <BsFillArrowDownCircleFill />
     
              </div>

              <div className="addproduct-box-btn">
                <button className="addproduct-update-btn">update</button>
                <button
                  onClick={(e) => {
                    setIndexUpdate(index);
                    setUpdateItem(item);
                  }}
                  className="addproduct-icon-btn"
                >
                  <AiFillEdit size={15} />
                </button>

                <button
                  onClick={(e) => setDeleteFood({ ...item, target: "main" })}
                  className="addproduct-icon-btn"
                >
                  <AiFillDelete size={15} />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {deleteFood !== "" && (
        <div className="delete-popup">
          <h4>Bạn có chắc muốn xóa sản phẩm này</h4>
          <div className="addproduct-delete-btn">
            <p
              onClick={(e) => {
                setDeleteFood("");
              }}
            >
              không xóa
            </p>
            <p onClick={(e) => deleteFoodHandler(e)}>xóa</p>
          </div>
        </div>
      )}

      <hr />

      <div style={{ marginTop: "10px" }} className="AddProduct-sp">
        {menu !== "" && (
          <span
            style={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              Danh sách <span style={{ fontWeight: "bold" }}>gia vị</span> -{" "}
              <span style={{ fontWeight: "bold" }}>hương vị</span> -{" "}
              <span style={{ fontWeight: "bold" }}>món bổ sung</span> -{" "}
              <span style={{ fontWeight: "bold" }}>món phụ...</span> cho -{" "}
              <span style={{ color: "#C539B4", fontWeight: "bold" }}>
                {menu}
              </span>
            </div>
          </span>
        )}
        {menu === "" && (
          <span style={{ textAlign: "center", width: "100%" }}>
            Chưa có thực đơn vui lòng tạo thực đơn trước khi tạo món phụ
          </span>
        )}
      </div>

      <div style={{ marginTop: "10px" }} className="addproduct-items-box">
        <div className="addproduct-add-btn">
          <div className="addproduct-input">
            <input
              maxLength={20}
              value={subItem.name}
              onChange={(e) => setSubItem({ ...subItem, name: e.target.value })}
              placeholder="Tên món bổ sung"
              type="text"
              className=""
            ></input>
          </div>
          <div className="addproduct-input">
            <input
              value={subItem.price}
              maxLength="10"
              onChange={(e) => {
                const name = subItem.name;
                let price = "";
                const number = "0123456789";
                for (let i = 0; i < e.target.value.length; i++) {
                  if (number.includes(e.target.value[i]))
                    price += e.target.value[i];
                }
                setSubItem({ ...subItem, price: price });
              }}
              placeholder="Giá tiền"
              type="text"
              className=""
            ></input>
          </div>
          <button
            onClick={(e) => createSubFood(e)}
            className="addproduct-submit"
          >
            Tạo món phụ
          </button>
        </div>

        {/* subitems list */}
        {subItems.map((item, index) => {
          if (updateSubItemsIndex === index) {
            return (
              <div key={index} className="addproduct-add-btn">
                <div className="addproduct-input">
                  <input
                    maxLength="20"
                    value={subItemsUpdate.name}
                    placeholder="Tên món"
                    type="text"
                    className=""
                    onChange={(e) => {
                      setSubItemsUpdate({
                        ...subItemsUpdate,
                        name: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className="addproduct-input">
                  <input
                    value={subItemsUpdate.price}
                    placeholder="Giá tiền"
                    maxLength="10"
                    type="text"
                    className=""
                    onChange={(e) => {
                      setSubItemsUpdate({
                        ...subItemsUpdate,
                        price: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className="addproduct-box-btn">
                  <button
                    onClick={(e) => updateSubFoodhandler(e)}
                    className="addproduct-update-btn"
                  >
                    update
                  </button>
                  <button
                    onClick={(e) => {
                      setSubItemsUpdateIndex(-1);
                      setSubItemsUpdate("");
                    }}
                    className="addproduct-icon-btn"
                    style={{
                      backgroundColor: "#404258",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    <IoMdRemoveCircle size={15} />
                  </button>
                  <button
                    onClick={(e) => setDeleteFood(item)}
                    className="addproduct-icon-btn"
                  >
                    <AiFillDelete size={15} />
                  </button>
                </div>
              </div>
            );
          }
          return (
            <div key={index} className="addproduct-add-btn">
              <div className="addproduct-input">
                <input
                  value={item.name}
                  placeholder="Tên món"
                  type="text"
                  className=""
                  readOnly={true}
                  maxLength="20"
                ></input>
              </div>
              <div className="addproduct-input">
                <input
                  value={item.price}
                  placeholder="Giá tiền"
                  type="text"
                  className=""
                  readOnly={true}
                  maxLength="10"
                ></input>
              </div>
              <div className="addproduct-box-btn">
                <button className="addproduct-update-btn">update</button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setSubItemsUpdateIndex(index);
                    setSubItemsUpdate(item);
                  }}
                  className="addproduct-icon-btn"
                >
                  <AiFillEdit size={15} />
                </button>
                <button
                  onClick={(e) => setDeleteFood({ ...item, target: "sub" })}
                  className="addproduct-icon-btn"
                >
                  <AiFillDelete size={15} />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AddProduct;
