import React, { useState, useEffect } from 'react';
import Swiper from './Swiper';
import Products from './Products';
import axios from 'axios';
import { api } from '../util/api';
import { version } from '../util/version';

function Home() {

  const [banner, setBanner] = useState("");

  async function getbannerbot() {
      const res = await axios.get(`${api}/admin/findImage/banner-bot/${version}`);
      let bannerbot = res.data.imgs || []
      setBanner(bannerbot[0].data || "");
  }

  useEffect(() => {
    getbannerbot();
  }, []);

  return (
    <div style={{overflow: 'hidden'}}>
        <Swiper />
        <img width="100%" height="100%" src={banner}/>
        <Products />
    </div>
  )
}

export default Home