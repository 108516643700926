import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineHome, AiOutlineInfoCircle, AiOutlineShopping, AiOutlineHistory, AiOutlineShop, AiOutlineLogout } from "react-icons/ai";
import { MdOutlineSell, MdFavoriteBorder } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import { TbMeat } from "react-icons/tb";
import "../css/Profile.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProfileInfoUser from './ProfileInfoUser';
import MyShopScreen from "./MyShopScreen";
import { IoIosStats } from "react-icons/io";
import AddProduct from "./AddProduct";
import OrderBuyScreen from './OrderBuyScreen';
import OrderSellScreen from './OrderSellScreen';

function Profile() {
    const history = useHistory();
    const user = useSelector(state => state.user);
    const [options, setOptions] = useState(["Trang chủ", "Thông tin user", "Lịch sử", "Đơn mua", "Đơn bán", "Yêu thích", "Message", "Thông tin Shop", "Thêm sản phẩm","Thống kê"])
    const [icons, setIcons] = useState([<AiOutlineHome size={30} />, <AiOutlineInfoCircle />, <AiOutlineHistory />, <AiOutlineShopping />, <MdOutlineSell />, <MdFavoriteBorder />, <FiMessageSquare />, <AiOutlineShop />, <TbMeat/>, <IoIosStats />]);
    const [currentOption, setCurrentOption] = useState(1);
    const [iconsDrop, setIconDrop] = useState(false);

    useEffect(() => {
        if(user.email === "") history.push("/");
    }, [user])

    useEffect(() => {
        if(currentOption === 0) history.push("/");
        if(setCurrentOption === 9) {

        }
    }, [currentOption]);

    return (
    <div className="profile-box">
        <div className="profile-box-left">
            {options.map((item, index) => {
                return <div onClick={(e) => {
                    e.preventDefault();
                    setCurrentOption(index);
                }} key={index} className="profile-btn-box">
                    <p>{icons[index]}</p>
                    <h4>{options[index]}</h4>
                </div>
            })}
            <div style={{top: `${currentOption * 75}px`}} className="profile-current-btn">
                <p>{icons[currentOption]}</p>
                <h4>{options[currentOption]}</h4>
            </div>
        </div>
        
        <div className="profile-box-right">
            {currentOption === 1 && <ProfileInfoUser user={user} />}
            {currentOption === 3 && <OrderBuyScreen user={user} />}
            {currentOption === 4 && <OrderSellScreen user={user} />}
            {currentOption === 7 && <MyShopScreen />}
            {currentOption === 8 && <AddProduct />}
            <div onClick={(e) => setIconDrop(!iconsDrop)} className="profile-menu-icon">
                <AiOutlineMenu />
            </div>
            <div style={{transform: iconsDrop ? 'scaleY(1)' : 'scaleY(0)'}} className="profile-menu-icon-items-down">
                {icons.map((item, index) => {
                    return <div onClick={(e) => setCurrentOption(index)} key={index}>
                        {item}
                    </div>
                })}
            </div>
        </div>
    </div>
  )
}

export default Profile;