import React, { useState, useEffect } from 'react';
import "../css/Cart.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AiOutlineDoubleLeft, AiOutlineDelete, AiFillCloseSquare } from "react-icons/ai";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { TbMinusVertical } from "react-icons/tb";
import { TbMeat } from "react-icons/tb";
import { GiClick } from "react-icons/gi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { cartActions } from "../redux/cart";
import "../css/ScrollBar.css";
import Cookies from 'js-cookie';
import { api } from "../util/api";
import axios from "axios";
import TextEffect from "./TextEffect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { version } from '../util/version';

function Cart(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const user= useSelector(state => state.user);
    const [orders, setOrders] = useState([]);
    const [showMBS, setShowMBS] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({content: "", color: ""});
    const [cartImg, setCartImg] = useState("");

    async function getImg() {
        const res = await axios.get(`${api}/admin/findImage/cart-2/${version}`);
        let cart = res.data.imgs || []
        setCartImg(cart[0].data || "");
    
      }
    
      useEffect(() => {
        getImg();
      }, []);

    useEffect(() => {
        const emails = [];
        let arr = [];
        for(let i = 0; i < cart.items.length; i++) {
            if(!emails.includes(cart.items[i].email_nguoi_ban)) emails.push(cart.items[i].email_nguoi_ban);
        }
        for(let i = 0; i < emails.length; i++ ){
            arr.push([]);
        }

        for(let i = 0; i < arr.length; i++) {
            let total = 0;
            for(let j = 0; j < cart.items.length; j++) {
                if(cart.items[j].email_nguoi_ban === emails[i]) {
                    arr[i].push({...cart.items[j], monbosung: cart.items[j].monbosung.filter((item) => item.ten !== "")});
                    total += cart.items[j].tongtien * cart.items[j].so_luong;
                }
            }
            arr[i].total = total;
        }
        setOrders(arr);
    }, [cart]);

    if(isLoading) {
        return <TextEffect text={"Loading..."} fontsize={40} />;
    }

    const handlerOrder = async (order) => {
        if(user.email === "") history.push('/login');
        if(user.email === order[0].email_nguoi_ban) return alert("Bạn không thể mua hàng của chính mình");
        setIsLoading(true);
        let setOrder = {
            deviceToken: order[0].deviceToken,
            nameshop: order[0].nameshop,
            diachi_giaohang: order[0].diachi_giaohang,
            diachi_nhanhang: order[0].diachi_nhanhang,
            sdt_nguoi_ban: order[0].sdt_nguoi_ban,
            sdr_nguoi_mua: order[0].sdt_nguoi_mua,
            email_nguoi_ban: order[0].email_nguoi_ban,
            email_nguoi_mua: order[0].email_nguoi_mua,
            status: "choxacnhan",
            createdAt: Date.now(),
            tongtien: order.total,
            items: []
        }
        let items = [];
        for (let i = 0; i < order.length; i++) {
            items.push({phanloai: order[i].phanloai, tongtien: order[i].tongtien, ten: order[i].ten, monbosung: order[i].monbosung, tien: order[i].tien, so_luong: order[i].so_luong, img_url: order[i].img_url});
        }
        setOrder.items = JSON.stringify(items);
        const token = Cookies.get('token').replaceAll('"', "");
        const res = await axios.post(`${api}/order/order`, setOrder, { headers: { authorization: "Bearer " + token } });
        if(res.data.message === "ok") {
            let pushdata = {
                email: order[0].email_nguoi_ban,
                token: order[0].deviceToken,
            };
            dispatch(cartActions.deleteItemsByEmail(order[0].email_nguoi_ban));         
            setMessage({content: "đặt hàng thành công, vào thông tin tài khoản để xem chi tiết", color: "white"});
            setTimeout(function() {
                setMessage({content: "", color: ""});
            } , [3000]);
            setIsLoading(false);
            if(props.socket !== null) props.socket.emit("pushOrderToServer", ({email: order[0].email_nguoi_ban, nums: order[0].nums}));
            await axios.post(`${api}/order/push_notification`, pushdata);
        } else {
            setMessage({content: "đặt hàng không thành công, vui lòng thử lại sau", color: "red"});
            setTimeout(function() {
                setMessage({content: "", color: ""});
            } , [3000]);
            setIsLoading(false);
        }
    }

  return (
    <div className="cart-screen">
        {cart.items.length === 0 && <div className="cart-box">
          <LazyLoadImage src={cartImg} />
          <button onClick={(e) => { 
            history.push("/");
          }} className="cart-btn-back">
            <AiOutlineDoubleLeft size={25} />
            <span style={{fontSize: "17px", fontWeight: "bold"}}>Back to Shopping</span>
          </button>
        </div>}
        <div className="cart-list-items">
          {orders.map((order, index) => {
            return <div className="cart-order" key={index}>
                <div className="cart-nameshop">
                    <span style={{fontWeight: "bold", color: "gray"}}>SHOP - </span> 
                    <span style={{fontWeight: "bold"}}>{order[0].nameshop}</span> 
                </div>
                {order.map((item, index) => {                
                    return <div className="cart-order-item" key={index}>
                        <div className="cart-order-img">
                            <LazyLoadImage width="100%" height="100%" src={item.img_url} alt="" />
                        </div>
                        <div className="cart-order-content-box">
                            <div className="cart-order-content">
                                <span>Tên Món:</span>
                                <span>{item.ten}</span>
                            </div>
                            <hr />
                            <div className="cart-order-content">
                                <span>Phân Loại:</span>
                                <span>{item.phanloai.ten !== "" ? item.phanloai.ten : "???"}</span>
                            </div>
                            <hr />
                            <div className="cart-order-content cart-order-mt">
                                <span>món thêm:</span>
                                {item.monbosung.length > 0 ? <div style={{fontWeight: "bold", display: "flex", alignItems: "center"}}>{item.monbosung.length} món <TbMinusVertical size="25" color={"gray"} /></div> : "???"}
                                {item.monbosung.length > 0 && <span style={{display: 'flex', alignItems: 'center', gap: "10px", }}>
                                    <span onClick={(e) => setShowMBS(index)} style={{display: 'flex', alignItems: 'center', gap: "10px", color: "green"}}>Bấm vào xem <IoMdArrowDropdownCircle /></span>
                                </span>}
                                {item.monbosung.length > 0 && <div style={{transform: showMBS === index ? "scaleY(1)" : "scaleY(0)"}} id="style-8" className="cart-mbs-box">
                                    <div className="cart-mbs-top">
                                        {item.monbosung.map((item, index) => {
                                            return <div className="cart-mbs-item" key={index}>
                                                <span className="cart-text-overflow">{item.ten}</span>
                                                <span className="cart-text-overflow" style={{color: "orangered"}}>{item.tien}.vnđ</span>
                                            </div>
                                        })}
                                    </div>
                                    <div onClick={(e) => setShowMBS(-1)} className="cart-mbs-close">Đóng cửa sổ</div>
                                </div>}
                            </div>
                            <hr />
                            <div className="cart-order-content">
                                <span>Số Lượng:</span>
                                <span>{item.so_luong}</span>
                            </div>
                            <hr />
                            <div style={{marginBottom: "10px"}} className="cart-order-content">
                                <span>Tổng tiền:</span>
                                <span style={{color: "orangered"}}>{item.tongtien * item.so_luong}.vnđ</span>
                            </div>
                        </div>
                        
                    </div>
                })}
                <div className="cart-order-bot-info">
                    <div style={{fontWeight: "bold"}}>Địa chỉ giao hàng</div>
                    <div className="cart-text-break" style={{fontWeight: "bold", color: 'gray'}}>{order[0].diachi_giaohang[3]} - {order[0].diachi_giaohang[2]} - {order[0].diachi_giaohang[1]} - {order[0].diachi_giaohang[0]}</div>
                </div>
                <div className="cart-order-bot-info">
                    <div style={{fontWeight: "bold"}}>Địa chỉ nhận hàng</div>
                    <div className="cart-text-break" style={{fontWeight: "bold", color: 'gray'}}>{order[0].diachi_nhanhang[3]} - {order[0].diachi_nhanhang[2]} - {order[0].diachi_nhanhang[1]} - {order[0].diachi_nhanhang[0]}</div>
                </div>
                <div className="cart-order-bot-info">
                    <div style={{fontWeight: "bold"}}>Tổng tiền thanh toán</div>
                    <div className="cart-text-break" style={{fontWeight: "bold", color: "orangered"}}>{order.total}.vnđ <span style={{color: "gray"}}>(chưa tính phí ship)</span></div>
                </div>

                <div className="cart-btn-container">
                    <div onClick={(e) => {
                        e.preventDefault();
                        dispatch(cartActions.deleteItemsByEmail(order[0].email_nguoi_ban));
                    }} style={{backgroundColor: "#293462"}} className="cart-btn-submit">
                        <div style={{backgroundColor: "#FF7396"}} className="cart-btn-submit-icon-left"><AiOutlineDelete size="30px" /></div>
                        <div className="cart-btn-submit-text">xóa đơn</div>
                        <div style={{backgroundColor: "#FF7396"}} className="cart-btn-submit-icon-right"><GiClick size="30px" /></div>
                    </div>
                    <div style={{backgroundColor: "#810955"}} className="cart-btn-submit">
                        <div style={{backgroundColor: "#FFC54D", }} className="cart-btn-submit-icon-left"><HiOutlineShoppingBag size="30px" /></div>
                        <div className="cart-btn-submit-text">mua tiếp</div>
                        <div style={{backgroundColor: "#FFC54D", }} className="cart-btn-submit-icon-right"><GiClick size="30px" /></div>
                    </div>

                    <div onClick={(e) => {
                        e.preventDefault();
                        handlerOrder(order);
                    }} style={{backgroundColor: "#87A2FB"}} className="cart-btn-submit">
                        <div style={{backgroundColor: "#1C3879"}} className="cart-btn-submit-icon-left"><TbMeat size="30px" /></div>
                        <div className="cart-btn-submit-text">Đặt Hàng</div>
                        <div style={{backgroundColor: "#1C3879"}} className="cart-btn-submit-icon-right"><GiClick size="30px" /></div>
                    </div>
                </div>
                
            </div>
          })}
        </div>
        <div style={{transform: message.content.length === 0 ? 'scaleY(0)' : 'scaleY(1)'}} className="cart-order-message">
            <div><AiFillCloseSquare size="30px"/></div>
            <div style={{color: message.color}} className="cart-message-text">{message.content}</div>
            <div style={{opacity: 0}}>1</div>
        </div>
    </div>
  )
}

export default Cart